import r, { OPTIONS as z } from "../managers/braze-instance.js";
import ro from "../util/browser-detector.js";
import lr from "./l10n-manager.js";
const ge = {
  l: !1,
  aa: null,
  ea: () => {
    if ((ge.p(), !ge.aa)) {
      let e = ro.language,
        t = !1;
      r.sr(z.Ea) && ((e = r.sr(z.Ea)), (t = !0)), (ge.aa = new lr(e, t));
    }
    return ge.aa;
  },
  p: () => {
    ge.l || (r.h(ge), (ge.l = !0));
  },
  destroy: () => {
    (ge.aa = null), (ge.l = !1);
  },
};
export default ge;
