import r from "../managers/braze-instance.js";
import { MAX_PURCHASE_QUANTITY as or } from "../common/constants.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as rt,
  validateCustomString as nt,
} from "../util/validation-utils.js";
import c from "../common/event-logger.js";
export function logPurchase(e, o, i, n, t) {
  if (!r.rr()) return !1;
  if (
    (null == i && (i = "USD"), null == n && (n = 1), null == e || e.length <= 0)
  )
    return (
      N.error(
        `logPurchase requires a non-empty productId, got "${e}", ignoring.`,
      ),
      !1
    );
  if (!nt(e, "log purchase", "the purchase name")) return !1;
  if (null == o || isNaN(parseFloat(o.toString())))
    return (
      N.error(`logPurchase requires a numeric price, got ${o}, ignoring.`), !1
    );
  const s = parseFloat(o.toString()).toFixed(2);
  if (null == n || isNaN(parseInt(n.toString())))
    return (
      N.error(`logPurchase requires an integer quantity, got ${n}, ignoring.`),
      !1
    );
  const D = parseInt(n.toString());
  if (D < 1 || D > or)
    return (
      N.error(
        `logPurchase requires a quantity >1 and <${or}, got ${D}, ignoring.`,
      ),
      !1
    );
  i = null != i ? i.toUpperCase() : i;
  if (
    -1 ===
    [
      "AED",
      "AFN",
      "ALL",
      "AMD",
      "ANG",
      "AOA",
      "ARS",
      "AUD",
      "AWG",
      "AZN",
      "BAM",
      "BBD",
      "BDT",
      "BGN",
      "BHD",
      "BIF",
      "BMD",
      "BND",
      "BOB",
      "BRL",
      "BSD",
      "BTC",
      "BTN",
      "BWP",
      "BYR",
      "BZD",
      "CAD",
      "CDF",
      "CHF",
      "CLF",
      "CLP",
      "CNY",
      "COP",
      "CRC",
      "CUC",
      "CUP",
      "CVE",
      "CZK",
      "DJF",
      "DKK",
      "DOP",
      "DZD",
      "EEK",
      "EGP",
      "ERN",
      "ETB",
      "EUR",
      "FJD",
      "FKP",
      "GBP",
      "GEL",
      "GGP",
      "GHS",
      "GIP",
      "GMD",
      "GNF",
      "GTQ",
      "GYD",
      "HKD",
      "HNL",
      "HRK",
      "HTG",
      "HUF",
      "IDR",
      "ILS",
      "IMP",
      "INR",
      "IQD",
      "IRR",
      "ISK",
      "JEP",
      "JMD",
      "JOD",
      "JPY",
      "KES",
      "KGS",
      "KHR",
      "KMF",
      "KPW",
      "KRW",
      "KWD",
      "KYD",
      "KZT",
      "LAK",
      "LBP",
      "LKR",
      "LRD",
      "LSL",
      "LTL",
      "LVL",
      "LYD",
      "MAD",
      "MDL",
      "MGA",
      "MKD",
      "MMK",
      "MNT",
      "MOP",
      "MRO",
      "MTL",
      "MUR",
      "MVR",
      "MWK",
      "MXN",
      "MYR",
      "MZN",
      "NAD",
      "NGN",
      "NIO",
      "NOK",
      "NPR",
      "NZD",
      "OMR",
      "PAB",
      "PEN",
      "PGK",
      "PHP",
      "PKR",
      "PLN",
      "PYG",
      "QAR",
      "RON",
      "RSD",
      "RUB",
      "RWF",
      "SAR",
      "SBD",
      "SCR",
      "SDG",
      "SEK",
      "SGD",
      "SHP",
      "SLL",
      "SOS",
      "SRD",
      "STD",
      "SVC",
      "SYP",
      "SZL",
      "THB",
      "TJS",
      "TMT",
      "TND",
      "TOP",
      "TRY",
      "TTD",
      "TWD",
      "TZS",
      "UAH",
      "UGX",
      "USD",
      "UYU",
      "UZS",
      "VEF",
      "VND",
      "VUV",
      "WST",
      "XAF",
      "XAG",
      "XAU",
      "XCD",
      "XDR",
      "XOF",
      "XPD",
      "XPF",
      "XPT",
      "YER",
      "ZAR",
      "ZMK",
      "ZMW",
      "ZWL",
    ].indexOf(i)
  )
    return (
      N.error(`logPurchase requires a valid currencyCode, got ${i}, ignoring.`),
      !1
    );
  const [u, a] = rt(
    t,
    "logPurchase",
    "purchaseProperties",
    `log purchase "${e}"`,
    "purchase",
  );
  if (!u) return !1;
  const g = r.v();
  if (g && g.Dr(e))
    return N.info(`Purchase "${e}" is blocklisted, ignoring.`), !1;
  const P = c.ds(d.Pr, { pid: e, c: i, p: s, q: D, pr: a });
  if (P.ss) {
    N.info(`Logged ${D} purchase${D > 1 ? "s" : ""} of "${e}" for ${i} ${s}.`);
    for (const r of P.fe) ot.m().ue(et.Rr, [e, t], r);
  }
  return P.ss;
}
