import s from "../common/base-provider.js";
import { newInAppMessageFromJson as ut } from "../InAppMessage/in-app-message-factory.js";
import ve from "../models/braze-event.js";
import {
  isArray as w,
  isEqual as ii,
  validateValueIsFromEnum as ta,
} from "../util/code-utils.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import wt from "../InAppMessage/models/templated-in-app-message.js";
import pt from "./models/trigger.js";
import et from "./models/trigger-events.js";
import { logger as N } from "../../shared-lib/index.js";
export default class gr extends s {
  constructor(t, i, s, e, r) {
    super(),
      (this.tg = t),
      (this.Ms = i),
      (this.C = s),
      (this.Ue = e),
      (this.ig = r),
      (this.tg = t),
      (this.Ms = i),
      (this.C = s),
      (this.Ue = e),
      (this.ig = r),
      (this.sg = []),
      (this.eg = []),
      (this.hg = null),
      (this.ng = {}),
      (this.og = {}),
      (this.triggers = []),
      (this.lg = 0),
      this.ag(),
      this.gg();
  }
  fg() {
    if (this.C) {
      (this.hg = this.C.ps(t.bs.aE) || this.hg),
        (this.ng = this.C.ps(t.bs.iE) || this.ng),
        (this.og = this.C.ps(t.bs.nE) || this.og);
      for (let t = 0; t < this.triggers.length; t++) {
        const i = this.triggers[t];
        i.id && null != this.og[i.id] && i.ad(this.og[i.id]);
      }
    }
  }
  ag() {
    if (!this.C) return;
    this.lg = this.C.ps(t.bs.oE) || 0;
    const i = this.C.ps(t.bs.rE) || [],
      s = [];
    for (let t = 0; t < i.length; t++) s.push(pt.Qn(i[t]));
    (this.triggers = s), this.fg();
  }
  gg() {
    const i = this,
      s = function (t, s, e, r, h) {
        return function () {
          i.cg(t, s, e, r, h);
        };
      },
      e = {};
    for (const t of this.triggers) t.id && (e[t.id] = t);
    let r = !1;
    for (let t = 0; t < this.triggers.length; t++) {
      const i = this.triggers[t];
      if (i.id && null != this.ng[i.id]) {
        const t = this.ng[i.id],
          h = [];
        for (let r = 0; r < t.length; r++) {
          const n = t[r],
            o = i.ud(n.pn || 0);
          if (o > 0) {
            let t, r;
            h.push(n),
              null != n.ug && (t = n.ug),
              null != n.dg && ve.AE(n.dg) && (r = ve.Qn(n.dg));
            const l = [];
            if (n.pg && w(n.pg))
              for (let t = 0; t < n.pg.length; t++) {
                const i = e[n.pg[t]];
                null != i && l.push(i);
              }
            this.eg.push(window.setTimeout(s(i, n.pn || 0, t, r, l), o));
          }
        }
        this.ng[i.id].length > h.length &&
          ((this.ng[i.id] = h),
          (r = !0),
          0 === this.ng[i.id].length && delete this.ng[i.id]);
      }
    }
    r && this.C && this.C.js(t.bs.iE, this.ng);
  }
  mg() {
    if (!this.C) return;
    const i = [];
    for (let t = 0; t < this.triggers.length; t++)
      i.push(this.triggers[t].Bs());
    (this.lg = new Date().valueOf()),
      this.C.js(t.bs.rE, i),
      this.C.js(t.bs.oE, this.lg);
  }
  bg() {
    if (!this.C) return;
    (this.C.ps(t.bs.oE) || 0) > this.lg ? this.ag() : this.fg();
  }
  U(i) {
    let s = !1;
    if (null != i && i.triggers) {
      this.ig.vn(), this.fg();
      const e = {},
        r = {};
      this.triggers = [];
      for (let t = 0; t < i.triggers.length; t++) {
        const h = pt.fromJson(i.triggers[t]);
        if (h) {
          h.id &&
            null != this.og[h.id] &&
            (h.ad(this.og[h.id]), (e[h.id] = this.og[h.id])),
            h.id && null != this.ng[h.id] && (r[h.id] = this.ng[h.id]);
          for (let t = 0; t < h.sd.length; t++)
            if (h.sd[t].kc(et.Xt, null)) {
              s = !0;
              break;
            }
          this.triggers.push(h);
        }
      }
      ii(this.og, e) || ((this.og = e), this.C && this.C.js(t.bs.nE, this.og)),
        ii(this.ng, r) ||
          ((this.ng = r), this.C && this.C.js(t.bs.iE, this.ng)),
        this.mg(),
        s &&
          (N.info("Trigger with test condition found, firing test."),
          this.ue(et.Xt)),
        this.ue(et.OPEN);
      const h = this.sg;
      let n;
      this.sg = [];
      for (let t = 0; t < h.length; t++)
        (n = Array.prototype.slice.call(h[t])), this.ue(...n);
    }
  }
  cg(t, i, s, e, r) {
    const h = (e) => {
        this.fg();
        const r = new Date().valueOf();
        t.dd(i) ||
          (!1 === navigator.onLine && t.type === pt.ln.an && e.imageUrl
            ? N.info(
                `Not showing ${t.type} trigger action ${t.id} due to offline state.`,
              )
            : t.nd(r) && this.wg(t, r, s)
            ? 0 === this.Ms.ic()
              ? N.info(
                  `Not displaying trigger ${t.id} because neither automaticallyShowInAppMessages() nor subscribeToInAppMessage() were called.`,
                )
              : (this.Ms.X([e]), this.yg(t, r))
            : N.info(
                `Not displaying trigger ${t.id} because display time fell outside of the acceptable time window.`,
              ));
      },
      n = () => {
        this.fg();
        const h = r.pop();
        if (null != h)
          if ((this.Tg(h, i, s, e, r), h.dd(i))) {
            let t = `Server aborted in-app message display, but the timeout on fallback trigger ${h.id} has already elapsed.`;
            r.length > 0 && (t += " Continuing to fall back."), N.info(t), n();
          } else {
            N.info(
              `Server aborted in-app message display. Falling back to lower priority ${h.type} trigger action ${t.id}.`,
            );
            const n = 1e3 * h.ed - (new Date().valueOf() - i);
            n > 0
              ? this.eg.push(
                  window.setTimeout(() => {
                    this.cg(h, i, s, e, r);
                  }, n),
                )
              : this.cg(h, i, s, e, r);
          }
      };
    let o, l, a;
    switch (t.type) {
      case pt.ln.an:
        if (((o = ut(t.data)), null == o)) {
          N.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          );
          break;
        }
        if (((l = this.ig.Lr(o)), l)) {
          N.error(l), n();
          break;
        }
        h(o);
        break;
      case pt.ln.md:
        if (((a = wt.fromJson(t.data, h, n, i, t.mn || 0)), null == a)) {
          N.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          );
          break;
        }
        this.ig.Wr(a, s, e);
        break;
      default:
        N.error(`Trigger ${t.id} was of unexpected type ${t.type}, ignoring.`);
    }
  }
  ue(t, i = null, s) {
    if (!ta(et, t, "Cannot fire trigger action.", "TriggerEvents")) return;
    if (this.Ue && this.Ue.uc())
      return (
        N.info(
          "Trigger sync is currently in progress, awaiting sync completion before firing trigger event.",
        ),
        void this.sg.push(arguments)
      );
    this.bg();
    const e = new Date().valueOf(),
      r = e - (this.hg || 0);
    let h = !0,
      n = !0;
    const o = [];
    for (let s = 0; s < this.triggers.length; s++) {
      const r = this.triggers[s],
        l = e + 1e3 * r.ed;
      if (
        r.nd(l) &&
        (null == r.startTime || r.startTime.valueOf() <= e) &&
        (null == r.endTime || r.endTime.valueOf() >= e)
      ) {
        let s = !1;
        for (let e = 0; e < r.sd.length; e++)
          if (r.sd[e].kc(t, i)) {
            s = !0;
            break;
          }
        s && ((h = !1), this.wg(r, l, t) && ((n = !1), o.push(r)));
      }
    }
    if (h)
      return void N.info(
        `Trigger event ${t} did not match any trigger conditions.`,
      );
    if (n)
      return void N.info(
        `Ignoring ${t} trigger event because a trigger was displayed ${
          r / 1e3
        }s ago.`,
      );
    o.sort((t, i) => t.priority - i.priority);
    const l = o.pop();
    null != l &&
      (N.info(
        `Firing ${l.type} trigger action ${l.id} from trigger event ${t}.`,
      ),
      this.Tg(l, e, t, s, o),
      0 === l.ed
        ? this.cg(l, e, t, s, o)
        : this.eg.push(
            window.setTimeout(() => {
              this.cg(l, e, t, s, o);
            }, 1e3 * l.ed),
          ));
  }
  changeUser(i = !1) {
    if (((this.triggers = []), this.C && this.C.Ls(t.bs.rE), !i)) {
      (this.sg = []), (this.hg = null), (this.og = {}), (this.ng = {});
      for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
      (this.eg = []),
        this.C && (this.C.Ls(t.bs.aE), this.C.Ls(t.bs.nE), this.C.Ls(t.bs.iE));
    }
  }
  clearData() {
    (this.triggers = []), (this.hg = null), (this.og = {}), (this.ng = {});
    for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
    this.eg = [];
  }
  wg(t, i, s) {
    if (null == this.hg) return !0;
    if (s === et.Xt)
      return (
        N.info(
          "Ignoring minimum interval between trigger because it is a test type.",
        ),
        !0
      );
    let e = t.hd;
    return null == e && (e = this.tg), i - this.hg >= 1e3 * e;
  }
  Tg(i, s, e, r, h) {
    this.fg(), i.id && (this.ng[i.id] = this.ng[i.id] || []);
    const n = {};
    let o;
    (n.pn = s), (n.ug = e), null != r && (o = r.Bs()), (n.dg = o);
    const l = [];
    for (const t of h) t.id && l.push(t.id);
    (n.pg = l),
      i.id && this.ng[i.id].push(n),
      this.C && this.C.js(t.bs.iE, this.ng);
  }
  yg(i, s) {
    this.fg(),
      i.ad(s),
      (this.hg = s),
      i.id && (this.og[i.id] = s),
      this.C && (this.C.js(t.bs.aE, s), this.C.js(t.bs.nE, this.og));
  }
}
