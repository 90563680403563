import Xt from "../models/backend-errors.js";
import ve from "../models/braze-event.js";
import {
  convertMsToSeconds as L,
  convertSecondsToMs as Kt,
} from "../util/date-utils.js";
import c from "../common/event-logger.js";
import { isArray as w, isEqual as ii } from "../util/code-utils.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import { STORAGE_KEYS as t } from "./storage-manager.js";
import h from "../util/request-header-utils.js";
import { LAST_REQUEST_TO_ENDPOINT_MS_AGO_DEFAULT as Ht } from "../common/constants.js";
import { getAlias as Yt } from "./utils.js";
export default class Pt {
  constructor(t, e, i, s, r, n, o, h, a, u, l, c) {
    (this.wn = t),
      (this.C = e),
      (this.Yh = i),
      (this.As = s),
      (this.T = r),
      (this.B = n),
      (this.yn = o),
      (this.Xh = h),
      (this.Vh = a),
      (this.$h = u),
      (this.appVersion = l),
      (this.$a = c),
      (this.Za = (t) => (null == t ? "" : `${t} `)),
      (this.wn = t),
      (this.C = e),
      (this.Yh = i),
      (this.As = s),
      (this.T = r),
      (this.B = n),
      (this.yn = o),
      (this.Xh = h),
      (this.Vh = a),
      (this.$h = u),
      (this.appVersion = l),
      (this.$a = c),
      (this.Ru = ["npm"]);
  }
  H(e, i = !1, s = !1) {
    const r = this.wn.ce(!s),
      n = r.Zr(),
      o = this.C.ps(t.bs.Xa);
    ii(o, n) || (e.device = n),
      (e.api_key = this.yn),
      (e.time = L(new Date().valueOf(), !0));
    const h = this.C.ps(t.bs.qu) || [],
      a = this.C.ps(t.bs.Au) || "";
    if (
      (this.Ru.length > 0 &&
        (!ii(h, this.Ru) || a !== this.T.Ss()) &&
        (e.sdk_metadata = this.Ru),
      (e.sdk_version = this.Vh),
      this.$h && (e.sdk_flavor = this.$h),
      (e.app_version = this.appVersion),
      (e.app_version_code = this.$a),
      (e.device_id = r.id),
      i)
    ) {
      const t = this.As.getUserId();
      null != t && (e.user_id = t);
    }
    if (!e.user_id && !this.Yh.jh()) {
      const t = Yt(this.C);
      t && (e.alias = t);
    }
    return e;
  }
  ts(t, e, i) {
    const s = e.auth_error,
      r = e.error;
    if (!s && !r) return !0;
    if (s) {
      let e;
      this.Yh.Jh();
      const r = { errorCode: s.error_code };
      for (const t of i)
        w(t) && "X-Braze-Auth-Signature" === t[0] && (r.signature = t[1]);
      t.respond_with && t.respond_with.user_id
        ? (r.userId = t.respond_with.user_id)
        : t.user_id && (r.userId = t.user_id);
      const n = s.reason;
      return (
        n
          ? ((r.reason = n), (e = `due to ${n}`))
          : (e = `with error code ${s.error_code}.`),
        this.Yh.jh() ||
          (e +=
            ' Please use the "enableSdkAuthentication" initialization option to enable authentication.'),
        N.error(`SDK Authentication failed ${e}`),
        this.Du(t.events || [], t.attributes || []),
        this.Yh.Gh(r),
        !1
      );
    }
    if (r) {
      let i,
        s = r;
      switch (s) {
        case Xt.Tu:
          return (
            (i = "Received successful response with empty body."),
            c.ds(d.Li, { e: i }),
            N.info(i),
            !1
          );
        case Xt.ku:
          return (
            (i = "Received successful response with invalid JSON"),
            c.ds(d.Li, { e: i + ": " + e.response }),
            N.info(i),
            !1
          );
        case Xt.Su:
          s = `The API key "${t.api_key}" is invalid for the baseUrl ${this.Xh}`;
          break;
        case Xt.Bu:
          s =
            "Sorry, we are not currently accepting your requests. If you think this is in error, please contact us.";
          break;
        case Xt.wu:
          s = "No device identifier. Please contact support@braze.com";
      }
      N.error("Backend error: " + s);
    }
    return !1;
  }
  zu(t, e, i, s) {
    return !!((t && 0 !== t.length) || (e && 0 !== e.length) || i || s);
  }
  ju(t, e, i, s, r = !1) {
    const n = [],
      o = (t) => t || "",
      a = o(this.As.getUserId());
    let u = this.Yr(t, e);
    const l = [],
      c = [];
    let d,
      f = null;
    if (i.length > 0) {
      const t = [];
      for (const e of i) {
        if (((d = e.Zr()), this.Yh.jh())) {
          if (a && !d.user_id) {
            f || (f = {}), f.events || (f.events = []), f.events.push(d);
            continue;
          }
          if (o(d.user_id) !== a) {
            c.push(d);
            continue;
          }
        }
        t.push(d);
      }
      t.length > 0 && (u.events = t);
    }
    if (s.length > 0) {
      const t = [];
      for (const e of s)
        e && (this.Yh.jh() && o(e.user_id) !== a ? l.push(e) : t.push(e));
      t.length > 0 && (u.attributes = t);
    }
    if ((this.Du(c, l), (u = this.H(u, !0, r)), f)) {
      f = this.H(f, !1, r);
      const t = { requestData: f, headers: this.J(f, h.O.Xu) };
      n.push(t);
    }
    if (u && !this.zu(u.events, u.attributes, t, e)) return f ? n : null;
    const m = { requestData: u, headers: this.J(u, h.O.Xu) };
    return n.push(m), n;
  }
  Du(t, e) {
    if (t) {
      const e = [];
      for (const i of t) {
        const t = ve.fromJson(i);
        (t.time = Kt(t.time)), e.push(t);
      }
      this.C.Eo(e);
    }
    if (e) for (const t of e) this.C.$u(t);
  }
  rs(t, e) {
    let i = "HTTP error ";
    null != t && (i += t + " "), (i += e), N.error(i);
  }
  zr(t) {
    return c.ds(d.Mu, { n: t });
  }
  Yr(t, e, i) {
    const s = {};
    t && (s.feed = !0), e && (s.triggers = !0);
    const r = null != i ? i : this.As.getUserId();
    if ((r && (s.user_id = r), !s.user_id && !this.Yh.jh())) {
      const t = Yt(this.C);
      t && (s.alias = t);
    }
    return (s.config = { config_time: this.B.Es() }), { respond_with: s };
  }
  Lu(t) {
    const e = new Date().valueOf();
    let i = Ht.toString();
    const s = h.Eu(this.C, t);
    if (-1 !== s) {
      i = (e - s).toString();
    }
    return i;
  }
  J(e, i, s = !1) {
    const r = [["X-Braze-Api-Key", this.yn]],
      n = this.Lu(i);
    r.push(["X-Braze-Last-Req-Ms-Ago", n]);
    const o = h.Fu(this.C, i).toString();
    r.push(["X-Braze-Req-Attempt", o]);
    let a = !1;
    if (
      (null != e.respond_with &&
        e.respond_with.triggers &&
        (r.push(["X-Braze-TriggersRequest", "true"]), (a = !0)),
      null != e.respond_with &&
        e.respond_with.feed &&
        (r.push(["X-Braze-FeedRequest", "true"]), (a = !0)),
      i === h.O.Pi)
    ) {
      r.push(["X-Braze-ContentCardsRequest", "true"]);
      let e = this.C.ps(t.bs.Fi);
      (e && s) || ((e = 0), this.C.js(t.bs.Fi, e)),
        r.push(["BRAZE-SYNC-RETRY-COUNT", e.toString()]),
        (a = !0);
    }
    if (
      (i === h.O.De &&
        (r.push(["X-Braze-FeatureFlagsRequest", "true"]), (a = !0)),
      a && r.push(["X-Braze-DataRequest", "true"]),
      this.Yh.jh())
    ) {
      const t = this.Yh.xh();
      null != t && r.push(["X-Braze-Auth-Signature", t]);
    }
    return r;
  }
  Ku(t, e, i, s) {
    window.setTimeout(() => {
      N.info(`Retrying rate limited ${this.Za(s)}SDK request.`),
        this.V(e, i, s);
    }, t);
  }
  V(e, i, s, r) {
    if (!this.Iu(s))
      return (
        N.info(`${this.Za(s)}SDK request being rate limited.`),
        void ("function" == typeof r && r())
      );
    const n = this.Pu();
    if (!n.Uu)
      return (
        N.info(
          `${this.Za(
            s,
          )}SDK request being rate limited. Request will be retried in ${Math.trunc(
            n.Ou / 1e3,
          )} seconds.`,
        ),
        void this.Ku(n.Ou, e, i, s)
      );
    this.C.js(t.bs.xu, new Date().valueOf());
    const o = e.device;
    o && o.os_version instanceof Promise
      ? o.os_version.then((t) => {
          (e.device.os_version = t), i(n.Gu);
        })
      : i(n.Gu);
  }
  Hu(e) {
    var i;
    null === (i = this.C) || void 0 === i || i.js(t.bs._u, e);
  }
  Wu(e, i) {
    let s = this.Yu();
    null == s && (s = {}), (s[e] = i), this.C.js(t.bs.Ju, s);
  }
  Qu() {
    var e;
    return null === (e = this.C) || void 0 === e ? void 0 : e.ps(t.bs._u);
  }
  Yu() {
    var e;
    return null === (e = this.C) || void 0 === e ? void 0 : e.ps(t.bs.Ju);
  }
  Vu(t, e, i, s, r = "") {
    let n;
    if (r) {
      const t = this.Yu();
      n = null == t || isNaN(t[r]) ? e : t[r];
    } else (n = this.Qu()), (null == n || isNaN(n)) && (n = e);
    const o = (t - s) / 1e3;
    return (n = Math.min(n + o / i, e)), n;
  }
  Zu(t, e) {
    return Math.max(0, (1 - t) * e * 1e3);
  }
  tl(e, i = "") {
    var s, r, n, o, a;
    const u = { Uu: !0, Gu: -1, Ou: 0 };
    if ((null == e && (e = !0), !e && !i)) return u;
    let l,
      c,
      d = null;
    if (e) d = null === (s = this.C) || void 0 === s ? void 0 : s.ps(t.bs.xu);
    else {
      const t = h.el(this.C);
      if (null == t || null == t[i]) return u;
      d = t[i];
    }
    if (null == d || isNaN(d)) return u;
    if (
      (e
        ? ((l =
            (null === (r = this.B) || void 0 === r ? void 0 : r.il()) || -1),
          (c = (null === (n = this.B) || void 0 === n ? void 0 : n.sl()) || -1))
        : ((l =
            (null === (o = this.B) || void 0 === o ? void 0 : o.rl(i)) || -1),
          (c =
            (null === (a = this.B) || void 0 === a ? void 0 : a.ol(i)) || -1)),
      -1 === l || -1 === c)
    )
      return u;
    const f = new Date().valueOf();
    let m = this.Vu(f, l, c, d, i);
    return m < 1
      ? ((u.Uu = !1), (u.Ou = this.Zu(m, c)), u)
      : ((m = Math.trunc(m) - 1),
        (u.Gu = m),
        e ? this.Hu(m) : this.Wu(i, m),
        u);
  }
  Pu() {
    return this.tl(!0);
  }
  Iu(t) {
    const e = this.tl(!1, t);
    return !(e && !e.Uu);
  }
  es() {
    this.Yh.es();
  }
  Z() {
    return this.Xh;
  }
  addSdkMetadata(t) {
    for (const e of t) -1 === this.Ru.indexOf(e) && this.Ru.push(e);
  }
}
