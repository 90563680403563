import {
  isArray as w,
  isDate as Nt,
  isObject as Ct,
  validateValueIsFromEnum as ta,
} from "../util/code-utils.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import {
  isValidEmail as St,
  validateCustomAttributeArrayType as At,
  validateCustomAttributeKey as Ut,
  validateCustomProperties as rt,
  validateCustomString as nt,
  validatePropertyType as Ft,
  validateStandardString as ir,
} from "../util/validation-utils.js";
import { SET_CUSTOM_USER_ATTRIBUTE_STRING as $t } from "../common/constants.js";
import { toValidBackendTimeString as Bt } from "../util/date-utils.js";
export default class User {
  constructor(t, e) {
    (this.As = t), (this.Ue = e), (this.As = t), (this.Ue = e);
  }
  getUserId(t) {
    const e = this.As.getUserId();
    if ("function" != typeof t) return e;
    N.warn(
      "The callback for getUserId is deprecated. You can access its return value directly instead (e.g. `const id = braze.getUser().getUserId()`)",
    ),
      t(e);
  }
  addAlias(t, e) {
    return !ir(t, "add alias", "the alias", !1) || t.length <= 0
      ? (N.error("addAlias requires a non-empty alias"), !1)
      : !ir(e, "add alias", "the label", !1) || e.length <= 0
      ? (N.error("addAlias requires a non-empty label"), !1)
      : this.Ue.$n(t, e).ss;
  }
  setFirstName(t) {
    return (
      !!ir(t, "set first name", "the firstName", !0) &&
      this.As.nu("first_name", t)
    );
  }
  setLastName(t) {
    return (
      !!ir(t, "set last name", "the lastName", !0) && this.As.nu("last_name", t)
    );
  }
  setEmail(t) {
    return null === t || St(t)
      ? this.As.nu("email", t)
      : (N.error(
          `Cannot set email address - "${t}" did not pass RFC-5322 validation.`,
        ),
        !1);
  }
  setGender(t) {
    return (
      "string" == typeof t && (t = t.toLowerCase()),
      !(
        null !== t &&
        !ta(
          User.Genders,
          t,
          `Gender "${t}" is not a valid gender.`,
          "User.Genders",
        )
      ) && this.As.nu("gender", t)
    );
  }
  setDateOfBirth(t, e, r) {
    return null === t && null === e && null === r
      ? this.As.nu("dob", null)
      : ((t = null != t ? parseInt(t.toString()) : null),
        (e = null != e ? parseInt(e.toString()) : null),
        (r = null != r ? parseInt(r.toString()) : null),
        null == t ||
        null == e ||
        null == r ||
        isNaN(t) ||
        isNaN(e) ||
        isNaN(r) ||
        e > 12 ||
        e < 1 ||
        r > 31 ||
        r < 1
          ? (N.error(
              "Cannot set date of birth - parameters should comprise a valid date e.g. setDateOfBirth(1776, 7, 4);",
            ),
            !1)
          : this.As.nu("dob", `${t}-${e}-${r}`));
  }
  setCountry(t) {
    return (
      !!ir(t, "set country", "the country", !0) && this.As.nu("country", t)
    );
  }
  setHomeCity(t) {
    return (
      !!ir(t, "set home city", "the homeCity", !0) && this.As.nu("home_city", t)
    );
  }
  setLanguage(t) {
    return (
      !!ir(t, "set language", "the language", !0) && this.As.nu("language", t)
    );
  }
  setEmailNotificationSubscriptionType(t) {
    return (
      !!ta(
        User.NotificationSubscriptionTypes,
        t,
        `Email notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes",
      ) && this.As.nu("email_subscribe", t)
    );
  }
  setPushNotificationSubscriptionType(t) {
    return (
      !!ta(
        User.NotificationSubscriptionTypes,
        t,
        `Push notification setting "${t}" is not a valid subscription type.`,
        "User.NotificationSubscriptionTypes",
      ) && this.As.nu("push_subscribe", t)
    );
  }
  setPhoneNumber(t) {
    return (
      !!ir(t, "set phone number", "the phoneNumber", !0) &&
      (null === t || t.match(User.Xn)
        ? this.As.nu("phone", t)
        : (N.error(`Cannot set phone number - "${t}" did not pass validation.`),
          !1))
    );
  }
  setLastKnownLocation(t, e, r, s, n) {
    return null == t || null == e
      ? (N.error(
          "Cannot set last-known location - latitude and longitude are required.",
        ),
        !1)
      : ((t = parseFloat(t.toString())),
        (e = parseFloat(e.toString())),
        null != r && (r = parseFloat(r.toString())),
        null != s && (s = parseFloat(s.toString())),
        null != n && (n = parseFloat(n.toString())),
        isNaN(t) ||
        isNaN(e) ||
        (null != r && isNaN(r)) ||
        (null != s && isNaN(s)) ||
        (null != n && isNaN(n))
          ? (N.error(
              "Cannot set last-known location - all supplied parameters must be numeric.",
            ),
            !1)
          : t > 90 || t < -90 || e > 180 || e < -180
          ? (N.error(
              "Cannot set last-known location - latitude and longitude are bounded by ±90 and ±180 respectively.",
            ),
            !1)
          : (null != r && r < 0) || (null != n && n < 0)
          ? (N.error(
              "Cannot set last-known location - accuracy and altitudeAccuracy may not be negative.",
            ),
            !1)
          : this.Ue.setLastKnownLocation(this.As.getUserId(), t, e, s, r, n)
              .ss);
  }
  setCustomUserAttribute(t, e, r) {
    if (!Ut(t)) return !1;
    const s = (e) => {
      const [r] = rt(
        e,
        $t,
        "attribute value",
        `set custom user attribute "${t}"`,
        "custom user attribute",
      );
      return r;
    };
    if (w(e)) {
      const [r, n] = At(t, e);
      if (!r && !n && 0 !== e.length) return !1;
      if (r || 0 === e.length) return this.Ue.Zn(d.au, t, e).ss;
      for (const t of e) if (!s(t)) return !1;
    } else if (Ct(e)) {
      if (!s(e)) return !1;
      if (r) return this.Ue.Zn(d.du, t, e).ss;
    } else {
      if (!(void 0 !== e && Ft(e))) return !1;
      if (
        (Nt(e) && (e = Bt(e)),
        "string" == typeof e &&
          !nt(
            e,
            `set custom user attribute "${t}"`,
            "the element in the given array",
          ))
      )
        return !1;
    }
    return this.As.setCustomUserAttribute(t, e);
  }
  addToCustomAttributeArray(t, e) {
    return (
      !!nt(t, "add to custom user attribute array", "the given key") &&
      !(
        null != e &&
        !nt(e, "add to custom user attribute array", "the given value")
      ) &&
      this.Ue.Zn(d.pu, t, e).ss
    );
  }
  removeFromCustomAttributeArray(t, e) {
    return (
      !!nt(t, "remove from custom user attribute array", "the given key") &&
      !(
        null != e &&
        !nt(e, "remove from custom user attribute array", "the given value")
      ) &&
      this.Ue.Zn(d.bu, t, e).ss
    );
  }
  incrementCustomUserAttribute(t, e) {
    if (!nt(t, "increment custom user attribute", "the given key")) return !1;
    null == e && (e = 1);
    const r = parseInt(e.toString());
    return isNaN(r) || r !== parseFloat(e.toString())
      ? (N.error(
          `Cannot increment custom user attribute because the given incrementValue "${e}" is not an integer.`,
        ),
        !1)
      : this.Ue.Zn(d.fu, t, r).ss;
  }
  setCustomLocationAttribute(t, e, r) {
    return (
      !!nt(t, "set custom location attribute", "the given key") &&
      ((null !== e || null !== r) &&
      ((e = null != e ? parseFloat(e.toString()) : null),
      (r = null != r ? parseFloat(r.toString()) : null),
      (null == e && null != r) ||
        (null != e && null == r) ||
        (null != e && (isNaN(e) || e > 90 || e < -90)) ||
        (null != r && (isNaN(r) || r > 180 || r < -180)))
        ? (N.error(
            "Received invalid values for latitude and/or longitude. Latitude and longitude are bounded by ±90 and ±180 respectively, or must both be null for removal.",
          ),
          !1)
        : this.Ue.gu(t, e, r).ss)
    );
  }
  addToSubscriptionGroup(t) {
    return !ir(
      t,
      "add user to subscription group",
      "subscription group ID",
      !1,
    ) || t.length <= 0
      ? (N.error(
          "addToSubscriptionGroup requires a non-empty subscription group ID",
        ),
        !1)
      : this.Ue.yu(t, User.Nu.SUBSCRIBED).ss;
  }
  removeFromSubscriptionGroup(t) {
    return !ir(
      t,
      "remove user from subscription group",
      "subscription group ID",
      !1,
    ) || t.length <= 0
      ? (N.error(
          "removeFromSubscriptionGroup requires a non-empty subscription group ID",
        ),
        !1)
      : this.Ue.yu(t, User.Nu.UNSUBSCRIBED).ss;
  }
  En(t, e, r, s, n) {
    this.As.En(t, e, r, s, n), this.Ue.vu();
  }
  Cn(t) {
    this.As.Cn(t);
  }
}
(User.Genders = {
  MALE: "m",
  FEMALE: "f",
  OTHER: "o",
  UNKNOWN: "u",
  NOT_APPLICABLE: "n",
  PREFER_NOT_TO_SAY: "p",
}),
  (User.NotificationSubscriptionTypes = {
    OPTED_IN: "opted_in",
    SUBSCRIBED: "subscribed",
    UNSUBSCRIBED: "unsubscribed",
  }),
  (User.Xn = /^[0-9 .\\(\\)\\+\\-]+$/),
  (User.Nu = { SUBSCRIBED: "subscribed", UNSUBSCRIBED: "unsubscribed" }),
  (User.Cu = "user_id"),
  (User.lu = "custom"),
  (User.mr = 997);
