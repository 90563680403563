import { attachCSS as Sn } from "./attach-css.js";
export function attachBannerCSS(n) {
  Sn(
    n,
    "banner",
    ".ab-html-banner{width:100%;height:100%;border:none}.ab-html-control-banner{width:0;height:0;margin:0;border:none}",
  );
}
export function setupBannerUI() {
  attachBannerCSS();
}
