import is from "./filter-set.js";
export default class rs {
  constructor(t, s) {
    (this.wc = t), (this.tf = s), (this.wc = t), (this.tf = s);
  }
  xc(t) {
    if (null == this.wc || null == this.tf) return !1;
    const s = t[0],
      i = t[1];
    return s === this.wc && this.tf.xc(i);
  }
  static fromJson(t) {
    return new rs(
      t ? t.event_name : null,
      t ? is.fromJson(t.property_filters) : null,
    );
  }
  Bs() {
    return { e: this.wc, pf: this.tf ? this.tf.Bs() : null };
  }
}
