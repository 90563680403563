import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import c from "../common/event-logger.js";
import r from "../managers/braze-instance.js";
import nr from "./feature-flags-provider-factory.js";
export function logFeatureFlagImpression(e) {
  if (!r.rr()) return;
  if (!e) return !1;
  const t =
      "Not logging a feature flag impression. The feature flag was not part of any matching experiment.",
    o = nr.m().Wi();
  if (!o[e]) return N.info(t), !1;
  const n = o[e].trackingString;
  if (!n) return N.info(t), !1;
  const i = nr.m().Zi();
  if (i[n])
    return (
      N.info(
        "Not logging another feature flag impression. This ID was already logged this session.",
      ),
      !1
    );
  (i[n] = !0), nr.m().je(i);
  const s = { fid: e, fts: n };
  return c.ds(d.yr, s).ss;
}
