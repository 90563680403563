import r from "../managers/braze-instance.js";
import Q from "./content-cards-provider.js";
const W = {
  l: !1,
  provider: null,
  m: () => (
    W.p(),
    W.provider ||
      ((W.provider = new Q(r.tr(), r.j(), r.v(), r.nr(), r.g())),
      r._(W.provider)),
    W.provider
  ),
  p: () => {
    W.l || (r.h(W), (W.l = !0));
  },
  destroy: () => {
    (W.provider = null), (W.l = !1);
  },
};
export default W;
