import { logger as N } from "../../shared-lib/index.js";
import r from "../managers/braze-instance.js";
import nr from "./feature-flags-provider-factory.js";
export function getFeatureFlag(e) {
  if (!r.rr()) return;
  const t = r.v();
  if (t && !t.Qi()) return N.info("Feature flags are not enabled."), null;
  const n = nr.m().Wi();
  return n[e] ? n[e] : null;
}
