import { isArray as w, isEqual as ii } from "../util/code-utils.js";
import ti from "../models/push-token.js";
import { logger as N, EncodingUtils as ei } from "../../shared-lib/index.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
import yt from "./utils/push-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class na {
  constructor(i, t, e, s, r, n, o, u, a, h, c) {
    (this.bn = i),
      (this.yn = t),
      (this.wn = e),
      (this.kn = r),
      (this.Pn = n),
      (this.Dn = o),
      (this.B = u),
      (this.Sn = a),
      (this.An = h),
      (this.C = c),
      (this.bn = i),
      (this.yn = t),
      (this.wn = e),
      (this.xn = s + "/safari/" + t),
      (this.kn = r || "/service-worker.js"),
      (this.Dn = o),
      (this.B = u),
      (this.Sn = a || !1),
      (this.An = h || !1),
      (this.C = c),
      (this.Nn = yt.Un()),
      (this.Wn = yt._n());
  }
  Tn() {
    return this.An;
  }
  In(i, t, e, s, r) {
    i.unsubscribe()
      .then((i) => {
        i
          ? this.Vn(t, e, s, r)
          : (N.error("Failed to unsubscribe device from push."),
            "function" == typeof r && r(!1));
      })
      .catch((i) => {
        N.error("Push unsubscription error: " + i),
          "function" == typeof r && r(!1);
      });
  }
  qn(i, t, e) {
    var s;
    const r = ((i) => {
      if ("string" == typeof i) return i;
      if (0 !== i.endpoint.indexOf("https://android.googleapis.com/gcm/send"))
        return i.endpoint;
      let t = i.endpoint;
      const e = i;
      return (
        e.zn &&
          -1 === i.endpoint.indexOf(e.zn) &&
          (t = i.endpoint + "/" + e.zn),
        t
      );
    })(i);
    let n = null,
      o = null;
    const u = i;
    if (null != u.getKey)
      try {
        const i = Array.from(new Uint8Array(u.getKey("p256dh"))),
          t = Array.from(new Uint8Array(u.getKey("auth")));
        (n = btoa(String.fromCharCode.apply(null, i))),
          (o = btoa(String.fromCharCode.apply(null, t)));
      } catch (i) {
        N.error(si(i));
      }
    const a = ((i) => {
      let t;
      return i.options &&
        (t = i.options.applicationServerKey) &&
        t.byteLength &&
        t.byteLength > 0
        ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(t))))
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
        : null;
    })(u);
    null === (s = this.bn) || void 0 === s || s.En(r, t, n, o, a),
      r && "function" == typeof e && e(r, n, o);
  }
  Rn() {
    var i;
    null === (i = this.bn) || void 0 === i || i.Cn(!0);
  }
  Fn(i, t) {
    var e;
    null === (e = this.bn) || void 0 === e || e.Cn(!1),
      N.info(i),
      "function" == typeof t && t(!1);
  }
  On(i, t, e, s) {
    var r;
    if ("default" === t.permission)
      try {
        window.safari.pushNotification.requestPermission(
          this.xn,
          i,
          {
            api_key: this.yn,
            device_id:
              (null === (r = this.wn) || void 0 === r ? void 0 : r.ce().id) ||
              "",
          },
          (t) => {
            "granted" === t.permission &&
              this.bn &&
              this.bn.setPushNotificationSubscriptionType(
                User.NotificationSubscriptionTypes.OPTED_IN,
              ),
              this.On(i, t, e, s);
          },
        );
      } catch (i) {
        this.Fn("Could not request permission for push: " + i, s);
      }
    else
      "denied" === t.permission
        ? this.Fn(
            "The user has blocked notifications from this site, or Safari push is not configured in the Braze dashboard.",
            s,
          )
        : "granted" === t.permission &&
          (N.info("Device successfully subscribed to push."),
          this.qn(t.deviceToken, new Date(), e));
  }
  requestPermission(i, t, e) {
    const s = (s) => {
      switch (s) {
        case "granted":
          return void ("function" == typeof i && i());
        case "default":
          return void ("function" == typeof t && t());
        case "denied":
          return void ("function" == typeof e && e());
        default:
          N.error("Received unexpected permission result " + s);
      }
    };
    let r = !1;
    if ("default" !== window.Notification.permission)
      s(Notification.permission);
    else {
      const i = window.Notification.requestPermission((i) => {
        r && s(i);
      });
      i
        ? i.then((i) => {
            s(i);
          })
        : (r = !0);
    }
  }
  Vn(i, t, e, s) {
    const r = { userVisibleOnly: !0 };
    null != t && (r.applicationServerKey = t),
      i.pushManager
        .subscribe(r)
        .then((i) => {
          N.info("Device successfully subscribed to push."),
            this.qn(i, new Date(), e);
        })
        .catch((i) => {
          yt.isPushBlocked()
            ? (N.info("Permission for push notifications was denied."),
              "function" == typeof s && s(!1))
            : (N.error("Push subscription failed: " + i),
              "function" == typeof s && s(!0));
        });
  }
  Bn() {
    if (this.Sn) return navigator.serviceWorker.getRegistration(this.kn);
    const i = this.Pn ? { scope: this.Pn } : void 0;
    return navigator.serviceWorker.register(this.kn, i).then(() =>
      navigator.serviceWorker.ready.then(
        (i) => (
          i &&
            "function" == typeof i.update &&
            i.update().catch((i) => {
              N.info("ServiceWorker update failed: " + i);
            }),
          i
        ),
      ),
    );
  }
  Mn(i) {
    this.Sn ||
      (i.unregister(), N.info("Service worker successfully unregistered."));
  }
  subscribe(i, e) {
    if (!yt.isPushSupported())
      return N.info(na.Yn), void ("function" == typeof e && e(!1));
    if (this.Nn) {
      if (!this.Sn && null != window.location) {
        let i = this.kn;
        -1 === i.indexOf(window.location.host) &&
          (i = window.location.host + i),
          -1 === i.indexOf(window.location.protocol) &&
            (i = window.location.protocol + "//" + i);
      }
      if (yt.isPushBlocked())
        return void this.Fn(
          "Notifications from this site are blocked. This may be a temporary embargo or a permanent denial.",
          e,
        );
      if (this.B && !this.B.Gn() && 0 === this.B.Es())
        return (
          N.info(
            "Waiting for VAPID key from server config before subscribing to push.",
          ),
          void this.B.Hn(() => {
            this.subscribe(i, e);
          })
        );
      const s = () => {
          N.info("Permission for push notifications was denied."),
            "function" == typeof e && e(!1);
        },
        r = () => {
          let i = "Permission for push notifications was ignored.";
          yt.isPushBlocked() &&
            (i +=
              " The browser has automatically blocked further permission requests for a period (probably 1 week)."),
            N.info(i),
            "function" == typeof e && e(!0);
        },
        n = yt.isPushPermissionGranted(),
        o = () => {
          !n &&
            this.bn &&
            this.bn.setPushNotificationSubscriptionType(
              User.NotificationSubscriptionTypes.OPTED_IN,
            ),
            this.Bn()
              .then((s) => {
                if (null == s)
                  return (
                    N.error(
                      "No service worker registration. Set the `manageServiceWorkerExternally` initialization option to false or ensure that your service worker is registered before calling registerPush.",
                    ),
                    void ("function" == typeof e && e(!0))
                  );
                s.pushManager
                  .getSubscription()
                  .then((r) => {
                    var n;
                    let o = null;
                    if (
                      (null !=
                        (null === (n = this.B) || void 0 === n
                          ? void 0
                          : n.Gn()) && (o = ei.Jn(this.B.Gn())),
                      r)
                    ) {
                      let n,
                        u = null,
                        a = null;
                      if ((this.C && (n = this.C.ps(t.bs.Kn)), n && !w(n))) {
                        let i;
                        try {
                          i = ti.Qn(n).Ln;
                        } catch (t) {
                          i = null;
                        }
                        null == i ||
                          isNaN(i.getTime()) ||
                          0 === i.getTime() ||
                          ((u = i),
                          (a = new Date(u)),
                          a.setMonth(u.getMonth() + 6));
                      }
                      null != o &&
                      r.options &&
                      r.options.applicationServerKey &&
                      r.options.applicationServerKey.byteLength &&
                      r.options.applicationServerKey.byteLength > 0 &&
                      !ii(o, new Uint8Array(r.options.applicationServerKey))
                        ? (r.options.applicationServerKey.byteLength > 12
                            ? N.info(
                                "Device was already subscribed to push using a different VAPID provider, creating new subscription.",
                              )
                            : N.info(
                                "Attempting to upgrade a gcm_sender_id-based push registration to VAPID - depending on the browser this may or may not result in the same gcm_sender_id-based subscription.",
                              ),
                          this.In(r, s, o, i, e))
                        : r.expirationTime &&
                          new Date(r.expirationTime).valueOf() <=
                            new Date().valueOf()
                        ? (N.info(
                            "Push subscription is expired, creating new subscription.",
                          ),
                          this.In(r, s, o, i, e))
                        : n && w(n)
                        ? this.In(r, s, o, i, e)
                        : null == a
                        ? (N.info(
                            "No push subscription creation date found, creating new subscription.",
                          ),
                          this.In(r, s, o, i, e))
                        : a.valueOf() <= new Date().valueOf()
                        ? (N.info(
                            "Push subscription older than 6 months, creating new subscription.",
                          ),
                          this.In(r, s, o, i, e))
                        : (N.info(
                            "Device already subscribed to push, sending existing subscription to backend.",
                          ),
                          this.qn(r, u, i));
                    } else this.Vn(s, o, i, e);
                  })
                  .catch((i) => {
                    N.error("Error checking current push subscriptions: " + i);
                  });
              })
              .catch((i) => {
                N.error("ServiceWorker registration failed: " + i);
              });
        };
      this.requestPermission(o, r, s);
    } else if (this.Wn) {
      if (null == this.Dn || "" === this.Dn)
        return (
          N.error(
            "You must supply the safariWebsitePushId initialization option in order to use registerPush on Safari",
          ),
          void ("function" == typeof e && e(!0))
        );
      const t = window.safari.pushNotification.permission(this.Dn);
      this.On(this.Dn, t, i, e);
    }
  }
  unsubscribe(i, t) {
    if (!yt.isPushSupported())
      return N.info(na.Yn), void ("function" == typeof t && t());
    this.Nn
      ? navigator.serviceWorker.getRegistration(this.kn).then((e) => {
          e
            ? e.pushManager
                .getSubscription()
                .then((s) => {
                  s
                    ? (this.Rn(),
                      s
                        .unsubscribe()
                        .then((s) => {
                          s
                            ? (N.info(
                                "Device successfully unsubscribed from push.",
                              ),
                              "function" == typeof i && i())
                            : (N.error(
                                "Failed to unsubscribe device from push.",
                              ),
                              "function" == typeof t && t()),
                            this.Mn(e);
                        })
                        .catch((i) => {
                          N.error("Push unsubscription error: " + i),
                            "function" == typeof t && t();
                        }))
                    : (N.info("Device already unsubscribed from push."),
                      "function" == typeof i && i());
                })
                .catch((i) => {
                  N.error("Error unsubscribing from push: " + i),
                    "function" == typeof t && t();
                })
            : (N.info("Device already unsubscribed from push."),
              "function" == typeof i && i());
        })
      : this.Wn &&
        (this.Rn(),
        N.info("Device unsubscribed from push."),
        "function" == typeof i && i());
  }
}
na.Yn = "Push notifications are not supported in this browser.";
