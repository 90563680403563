import { logger as N } from "../../shared-lib/index.js";
import r from "../managers/braze-instance.js";
import i from "./banner-provider-factory.js";
export function getBannerIfNotExpired(n, e) {
  const r = n[e];
  if (!r) return null;
  const t = r.it,
    a = new Date().valueOf();
  return -1 !== t && 1e3 * t < a
    ? (N.info(`Banner with ID: ${r.id} and placement ID: ${e} has expired.`),
      null)
    : r;
}
export function getBanner(n) {
  if (!r.rr()) return;
  const e = r.v();
  if (e && !e.Us()) return N.info("Banners are not enabled."), null;
  return getBannerIfNotExpired(i.m().vs(), n);
}
