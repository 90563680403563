import N from "../../../shared-lib/logger.js";
import { SUBSCRIPTION_ID_DATA_ATTRIBUTE as f } from "../../common/constants.js";
import r, { OPTIONS as z } from "../../managers/braze-instance.js";
import { setupBannerUI as U } from "../../ui/js/banner-css.js";
import { addPassiveEventListener as J } from "../../util/dom-utils.js";
import { bannerToHtml as S } from "../display/banner-to-html.js";
import { destroyBannerHtml as A } from "../display/destroy-banner-html.js";
import { detectBannerImpressions as C } from "../display/detect-banner-impressions.js";
import { subscribeToBannersUpdates } from "../subscribe-to-banners-updates.js";
export function insertBanner(o, s) {
  if (!r.rr()) return;
  if (!o || !s) return void N.error("Banner or parentNode is not provided.");
  if (!r.sr(z.er))
    return void N.error(
      "Banners are disabled. Use the 'allowUserSuppliedJavascript' option for braze.initialize to enable these messages.",
    );
  U();
  const e = S(o, r.sr(z.ir)),
    i = subscribeToBannersUpdates((i) => {
      const t = i[o.placementId];
      t ? s.replaceChildren(S(t, r.sr(z.ir))) : A(e);
    });
  i && e.setAttribute(f, i), s.replaceChildren(e), J(window, "scroll", C), C();
}
