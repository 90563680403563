import l from "./util/net.js";
import ve from "./models/braze-event.js";
import c from "./common/event-logger.js";
import { randomInclusive as a } from "./util/math.js";
import E from "./models/request-result.js";
import {
  logger as N,
  IndexedDBAdapter as it,
  Guid as G,
  EventTypes as d,
} from "../shared-lib/index.js";
import { STORAGE_KEYS as t } from "./managers/storage-manager.js";
import u from "./managers/subscription-manager.js";
import yt from "./Push/utils/push-utils.js";
import h from "./util/request-header-utils.js";
export default class Lt {
  constructor(t, i, s, e, h, o, n, r, l, a) {
    (this.yn = t),
      (this.baseUrl = i),
      (this.T = s),
      (this.wn = e),
      (this.As = h),
      (this.B = o),
      (this.C = n),
      (this.$l = r),
      (this.Yh = l),
      (this.S = a),
      (this.yn = t),
      (this.baseUrl = i),
      (this.Pl = 0),
      (this.IE = n.OE() || 0),
      (this.Ol = null),
      (this.T = s),
      (this.wn = e),
      (this.As = h),
      (this.B = o),
      (this.C = n),
      (this.Yh = l),
      (this.S = a),
      (this.$l = r),
      (this.Ul = new u()),
      (this._l = null),
      (this.Xl = 50),
      (this.Ql = !1);
  }
  Yl(t, i) {
    return !t && !i && this.Yh.Kh() >= this.Xl;
  }
  Zl(t) {
    let i = this.T.Hl();
    if (t.length > 0) {
      const s = this.As.getUserId();
      for (const e of t) {
        const t = (!e.userId && !s) || e.userId === s;
        e.type === d.Jl && t && (i = !0);
      }
    }
    return i;
  }
  sc(i = !1, s = !1, e = !0, o, n, r, u = !1, c = !1) {
    e && this.hc();
    const d = this.C.hE(),
      m = this.C.gE();
    let f = !1;
    const g = (i, s, u = -1) => {
        let c = !1;
        const d = new Date().valueOf();
        h.W(this.C, h.O.Xu, d),
          -1 !== u && s.push(["X-Braze-Req-Tokens-Remaining", u.toString()]),
          l.Y({
            url: this.baseUrl + "/data/",
            data: i,
            headers: s,
            ss: (e) => {
              null != i.respond_with &&
                i.respond_with.triggers &&
                (this.Pl = Math.max(this.Pl - 1, 0)),
                this.S.ts(i, e, s)
                  ? (this.Yh.es(),
                    this.B.Rl(e),
                    (null != i.respond_with &&
                      i.respond_with.user_id != this.As.getUserId()) ||
                      (null != i.device && this.C.js(t.bs.Xa, i.device),
                      null != i.sdk_metadata &&
                        (this.C.js(t.bs.qu, i.sdk_metadata),
                        this.C.js(t.bs.Au, this.T.Ss())),
                      this.$l(e),
                      h.ns(this.C, h.O.Xu, 1),
                      "function" == typeof o && o()))
                  : e.auth_error && (c = !0);
            },
            error: () => {
              null != i.respond_with &&
                i.respond_with.triggers &&
                (this.Pl = Math.max(this.Pl - 1, 0)),
                this.S.Du(i.events, i.attributes),
                "function" == typeof n && n();
            },
            os: (t) => {
              if (("function" == typeof r && r(t), e && !f)) {
                if (t && !c) this.nc();
                else {
                  h.hs(this.C, h.O.Xu);
                  let t = this.Ol;
                  (null == t || t < 1e3 * this.IE) && (t = 1e3 * this.IE),
                    this.nc(Math.min(3e5, a(1e3 * this.IE, 3 * t)));
                }
                f = !0;
              }
            },
          });
      },
      v = this.Zl(d),
      p = s || v;
    if (this.Yl(u, v))
      return void N.info(
        "Declining to flush data due to 50 consecutive authentication failures",
      );
    if (e && !this.S.zu(d, m, i, p))
      return this.nc(), void ("function" == typeof r && r(!0));
    const b = this.S.ju(i, p, d, m, c);
    p && this.Pl++;
    let w = !1;
    if (b)
      for (const t of b)
        this.S.V(
          t.requestData,
          (i) => g(t.requestData, t.headers, i),
          h.O.Xu,
          n,
        ),
          (w = !0);
    this.Yh.jh() && e && !w
      ? this.nc()
      : v && (N.info("Invoking new session subscriptions"), this.Ul.X());
  }
  uc() {
    return this.Pl > 0;
  }
  nc(t = 1e3 * this.IE) {
    this.Ql ||
      (this.hc(),
      (this._l = window.setTimeout(() => {
        if (document.hidden) {
          const t = "visibilitychange",
            i = () => {
              document.hidden ||
                (document.removeEventListener(t, i, !1), this.sc());
            };
          document.addEventListener(t, i, !1);
        } else this.sc();
      }, t)),
      (this.Ol = t));
  }
  hc() {
    null != this._l && (clearTimeout(this._l), (this._l = null));
  }
  initialize() {
    (this.Ql = !1), this.nc();
  }
  destroy() {
    this.Ul.removeAllSubscriptions(),
      this.Yh.Hh(),
      this.hc(),
      (this.Ql = !0),
      this.sc(void 0, void 0, !1, void 0, void 0, void 0, void 0, !0),
      (this._l = null);
  }
  rn(t) {
    return this.Ul.Ns(t);
  }
  openSession() {
    const i = this.T.Ss() !== this.T.qo();
    i && (this.C.cE(t.iu.Qo), this.C.cE(t.iu.su)),
      this.sc(void 0, !1, void 0, () => {
        this.C.Ls(t.bs.we);
      }),
      this.vu(),
      i &&
        import("./Push/push-manager-factory.js").then((i) => {
          if (this.Ql) return;
          const s = i.default.ea();
          if (
            null != s &&
            (yt.isPushPermissionGranted() || yt.isPushBlocked())
          ) {
            const i = () => {
                s.Tn()
                  ? N.info(
                      "Push token maintenance is disabled, not refreshing token for backend.",
                    )
                  : s.subscribe();
              },
              e = (t, s) => {
                s && i();
              },
              h = () => {
                const s = this.C.ps(t.bs.Kn);
                (null == s || s) && i();
              },
              o = it.Gs._s;
            new it(o, N).jr(o.Os.cu, e, h);
          }
        });
  }
  lc() {
    this.C.Ls(t.bs.Yi), this.C.Ls(t.bs.wi), this.C.Ls(t.bs.cn);
  }
  changeUser(i, s, e) {
    const h = this.As.getUserId();
    if (h !== i) {
      this.T.Kl(),
        this.lc(),
        null != h && this.sc(void 0, !1, void 0, void 0, void 0),
        this.As.ou(i),
        e ? this.Yh.setSdkAuthenticationSignature(e) : this.Yh.Bh();
      for (let t = 0; t < s.length; t++) s[t].changeUser(null == h);
      null != h && this.C.Ls(t.bs.Ct),
        this.C.Ls(t.bs.Xa),
        this.C.Ls(t.bs.SE),
        this.openSession(),
        N.info('Changed user to "' + i + '".');
    } else {
      let t = "Doing nothing.";
      e &&
        this.Yh.xh() !== e &&
        (this.Yh.setSdkAuthenticationSignature(e),
        (t = "Updated SDK authentication signature")),
        N.info(`Current user is already ${i}. ${t}`);
    }
  }
  requestImmediateDataFlush(t) {
    this.hc(), this.T.qo();
    this.sc(
      void 0,
      void 0,
      void 0,
      void 0,
      () => {
        N.error("Failed to flush data, request will be retried automatically.");
      },
      t,
      !0,
    );
  }
  requestFeedRefresh() {
    this.T.qo(), this.sc(!0);
  }
  vr(t, i) {
    this.T.qo(),
      N.info("Requesting explicit trigger refresh."),
      this.sc(void 0, !0, void 0, t, i);
  }
  $n(i, s) {
    const e = d.cc,
      h = { a: i, l: s },
      o = c.ds(e, h);
    return (
      o && (N.info(`Logged alias ${i} with label ${s}`), this.C.js(t.bs.SE, h)),
      o
    );
  }
  Zn(t, i, s) {
    if (this.B.hu(i))
      return (
        N.info(`Custom Attribute "${i}" is blocklisted, ignoring.`), new E()
      );
    const e = { key: i, value: s },
      h = c.ds(t, e);
    if (h) {
      const t = "object" == typeof s ? JSON.stringify(s, null, 2) : s;
      N.info(`Logged custom attribute: ${i} with value: ${t}`);
    }
    return h;
  }
  setLastKnownLocation(t, i, s, e, h, o) {
    const n = { latitude: i, longitude: s };
    null != e && (n.altitude = e),
      null != h && (n.ll_accuracy = h),
      null != o && (n.alt_accuracy = o);
    const r = c.ds(d.dc, n, t || void 0);
    return (
      r &&
        N.info(`Set user last known location as ${JSON.stringify(n, null, 2)}`),
      r
    );
  }
  br(t, i) {
    const s = this.T.qo();
    return new ve(this.As.getUserId(), d.mc, t, s, { cid: i });
  }
  fc(t, i) {
    return new it(t, i);
  }
  vu() {
    const t = it.Gs._s;
    this.fc(t, N).setItem(t.Os.Xu, 1, {
      baseUrl: this.baseUrl,
      data: { api_key: this.yn, device_id: this.wn.ce().id },
      userId: this.As.getUserId(),
      sdkAuthEnabled: this.Yh.jh(),
    });
  }
  wr(t) {
    for (const i of t)
      if (i.api_key === this.yn) this.S.Du(i.events, i.attributes);
      else {
        const t = it.Gs._s;
        new it(t, N).setItem(t.Os.$r, G.Rt(), i);
      }
  }
  gu(t, i, s) {
    if (this.B.hu(t))
      return (
        N.info(`Custom Attribute "${t}" is blocklisted, ignoring.`), new E()
      );
    let e, h;
    return (
      null === i && null === s
        ? ((e = d.gc), (h = { key: t }))
        : ((e = d.vc), (h = { key: t, latitude: i, longitude: s })),
      c.ds(e, h)
    );
  }
  yu(t, i) {
    const s = { group_id: t, status: i };
    return c.ds(d.bc, s);
  }
}
