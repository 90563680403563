import { STORAGE_KEYS as t } from "./storage-manager.js";
import u from "./subscription-manager.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
export default class qt {
  constructor(t, i, s) {
    (this.C = t),
      (this.gh = i),
      (this.Fh = s),
      (this.C = t),
      (this.gh = i || !1),
      (this.Fh = s),
      (this.kh = new u()),
      (this.fh = 0),
      (this.wh = 1);
  }
  jh() {
    return this.gh;
  }
  xh() {
    return this.C.ps(t.bs.qh);
  }
  setSdkAuthenticationSignature(i) {
    const s = this.xh();
    this.C.js(t.bs.qh, i);
    const e = it.Gs._s;
    new it(e, N).setItem(e.Os.yh, this.wh, i), s !== i && this.es();
  }
  Bh() {
    this.C.Ls(t.bs.qh);
    const i = it.Gs._s;
    new it(i, N).me(i.Os.yh, this.wh);
  }
  subscribeToSdkAuthenticationFailures(t) {
    return this.Fh.Ns(t);
  }
  Gh(t) {
    this.Fh.X(t);
  }
  Hh() {
    this.kh.removeAllSubscriptions();
  }
  Jh() {
    this.fh += 1;
  }
  Kh() {
    return this.fh;
  }
  es() {
    this.fh = 0;
  }
}
