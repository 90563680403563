import ro from "../util/browser-detector.js";
import Gt from "../models/device.js";
import DeviceProperties from "../Core/device-properties.js";
import _t from "../models/identifier.js";
import { logger as N, Guid as G } from "../../shared-lib/index.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import { values as Rt } from "../util/code-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class Ot {
  constructor(t, e) {
    (this.C = t),
      (this.Fa = e),
      (this.C = t),
      null == e && (e = Rt(DeviceProperties)),
      (this.Fa = e);
  }
  ce(e = !0) {
    let r = this.C.tu(t.iu.Qo);
    null == r && ((r = new _t(G.Rt())), e && this.C.uu(t.iu.Qo, r));
    const s = new Gt(r.eu);
    for (let t = 0; t < this.Fa.length; t++) {
      switch (this.Fa[t]) {
        case DeviceProperties.BROWSER:
          s.browser = ro.browser;
          break;
        case DeviceProperties.BROWSER_VERSION:
          s.Ga = ro.version;
          break;
        case DeviceProperties.OS:
          s.os = this.Ja();
          break;
        case DeviceProperties.RESOLUTION:
          s.Ha = screen.width + "x" + screen.height;
          break;
        case DeviceProperties.LANGUAGE:
          s.language = ro.language;
          break;
        case DeviceProperties.TIME_ZONE:
          s.timeZone = this.Ka(new Date());
          break;
        case DeviceProperties.USER_AGENT:
          s.userAgent = ro.userAgent;
      }
    }
    return s;
  }
  Ja() {
    if (ro.Qa()) return ro.Qa();
    const e = this.C.ps(t.bs.Xa);
    return e && e.os_version ? e.os_version : ro.Ja();
  }
  Ka(t) {
    let e = !1;
    if ("undefined" != typeof Intl && "function" == typeof Intl.DateTimeFormat)
      try {
        if ("function" == typeof Intl.DateTimeFormat().resolvedOptions) {
          const t = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (null != t && "" !== t) return t;
        }
      } catch (t) {
        N.info(
          "Intl.DateTimeFormat threw an error, cannot detect user's time zone:" +
            si(t),
        ),
          (e = !0);
      }
    if (e) return "";
    const r = t.getTimezoneOffset();
    return this.Ya(r);
  }
  Ya(t) {
    const e = Math.trunc(t / 60),
      r = Math.trunc(t % 60);
    let s = "GMT";
    return (
      0 !== t &&
        ((s += t < 0 ? "+" : "-"),
        (s +=
          ("00" + Math.abs(e)).slice(-2) +
          ":" +
          ("00" + Math.abs(r)).slice(-2))),
      s
    );
  }
}
