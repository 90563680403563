import { isArray as w } from "./code-utils.js";
export default class Oe {
  constructor() {}
  ef() {}
  ff() {}
  Ja(t) {}
  static nf(t, e) {
    if (t && e)
      if (((t = t.toLowerCase()), w(e.cf))) {
        for (let r = 0; r < e.cf.length; r++)
          if (-1 !== t.indexOf(e.cf[r].toLowerCase())) return e.identity;
      } else if (-1 !== t.indexOf(e.cf.toLowerCase())) return e.identity;
  }
}
