export default class Banner {
  constructor(t, i, s, h = !1, r = !1, e = -1) {
    (this.id = t),
      (this.placementId = i),
      (this.html = s),
      (this.tt = h),
      (this.isControl = r),
      (this.it = e),
      (this.id = t),
      (this.placementId = i),
      (this.html = s),
      (this.tt = h),
      (this.isControl = r),
      (this.it = e),
      (this.st = !1);
  }
  ht() {
    return this.isControl;
  }
  Bs() {
    return {
      id: this.id,
      o: this.placementId,
      html: this.html,
      t: this.tt,
      i: this.isControl,
      u: this.it,
    };
  }
}
