import r from "../managers/braze-instance.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
import ne, { STORAGE_KEYS as t } from "../managers/storage-manager.js";
export function enableSDK() {
  new ne.ne(null, !0).remove(t.ae);
  const e = it.Gs._s;
  new it(e, N).me(e.Os.ie, e.oe),
    N.info("enableSDK was called"),
    r.destroy(!1),
    r.re(!1);
}
