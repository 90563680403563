export default class ts {
  constructor(t) {
    (this.wc = t), (this.wc = t);
  }
  xc(t) {
    return null == this.wc || this.wc === t[0];
  }
  static fromJson(t) {
    return new ts(t ? t.event_name : null);
  }
  Bs() {
    return this.wc;
  }
}
