import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import c from "../common/event-logger.js";
import r from "../managers/braze-instance.js";
import { keys as y } from "../util/code-utils.js";
import i from "./banner-provider-factory.js";
export function logBannerImpressions(o) {
  if (!r.rr()) return;
  if (!o || o.length <= 0) return !1;
  const n = i.m(),
    s = n.vs();
  if (0 === y(s).length)
    return N.info("Not logging banners impression. No banners exist."), !1;
  const e = n.ks(),
    t = [];
  for (const r of o) {
    const o = s[r];
    o
      ? e[o.placementId]
        ? N.info(
            `Not logging banners impression for ID ${r}. This ID was already logged this session.`,
          )
        : ((e[o.placementId] = !0), t.push(o.id))
      : N.info(
          `Not logging banners impression for ID ${r}. The placement ID did not correspond to any banner.`,
        );
  }
  if (0 === t.length) return !1;
  n.zs(e);
  const a = { ids: t };
  return c.ds(d.ro, a).ss;
}
