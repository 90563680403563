import { logger as N } from "../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../common/constants.js";
import r from "../managers/braze-instance.js";
import {
  BRAZE_ACTIONS as oo,
  getDecodedBrazeAction as eo,
} from "../util/braze-actions.js";
import ro from "../util/browser-detector.js";
import { OperatingSystems as so } from "../util/device-constants.js";
import {
  BRAZE_ACTION_URI_REGEX as to,
  isValidBrazeActionJson as io,
} from "../util/validation-utils.js";
import { WindowUtils as no } from "../util/window-utils.js";
import { getUser as ao } from "./get-user.js";
export function _handleBrazeAction(o, e, s) {
  if (r.rr())
    if (to.test(o)) {
      const e = eo(o);
      if (!e) return;
      const s = (o) => {
        if (!io(o))
          return void N.error(
            `Decoded Braze Action json is invalid: ${JSON.stringify(
              o,
              null,
              2,
            )}`,
          );
        const e = oo.properties.type,
          t = oo.properties.eo,
          i = oo.properties.so,
          n = o[e];
        if (n === oo.types.io) {
          const e = o[t];
          for (const o of e) s(o);
        } else {
          const e = o[i];
          let s, t;
          switch (n) {
            case oo.types.logCustomEvent:
              import("./log-custom-event.js").then(
                ({ logCustomEvent: logCustomEvent }) => {
                  r.nt()
                    ? ((t = Array.prototype.slice.call(e)),
                      logCustomEvent(...t))
                    : N.error(p);
                },
              );
              break;
            case oo.types.requestPushPermission:
              import("../Push/request-push-permission.js").then(
                ({ requestPushPermission: requestPushPermission }) => {
                  r.nt()
                    ? "Safari" === ro.browser && ro.OS === so.ao
                      ? window.navigator.standalone && requestPushPermission()
                      : requestPushPermission()
                    : N.error(p);
                },
              );
              break;
            case oo.types.setEmailNotificationSubscriptionType:
            case oo.types.setPushNotificationSubscriptionType:
            case oo.types.setCustomUserAttribute:
            case oo.types.addToSubscriptionGroup:
            case oo.types.removeFromSubscriptionGroup:
            case oo.types.addToCustomAttributeArray:
            case oo.types.removeFromCustomAttributeArray:
              if (((s = ao()), s)) {
                s[n](...Array.prototype.slice.call(e));
              }
              break;
            case oo.types.co:
            case oo.types.mo:
              (t = Array.prototype.slice.call(e)), no.openUri(...t);
              break;
            default:
              N.info(`Ignoring unknown Braze Action: ${n}`);
          }
        }
      };
      s(e);
    } else no.openUri(o, e, s);
}
export function handleBrazeAction(o, e) {
  _handleBrazeAction(o, e);
}
