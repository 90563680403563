import { Card } from "./models/index.js";
import _ from "./card-manager-factory.js";
import r from "../managers/braze-instance.js";
import { isArray as w } from "../util/code-utils.js";
import { MUST_BE_CARD_WARNING_SUFFIX as K } from "../common/constants.js";
import { logger as N } from "../../shared-lib/index.js";
export function logCardImpressions(o, n) {
  if (!r.rr()) return !1;
  if (!w(o)) return N.error("cards must be an array"), !1;
  for (const r of o)
    if (!(r instanceof Card)) return N.error(`Each card in cards ${K}`), !1;
  return _.ea().jt(o, n).ss;
}
