import Card from "./card.js";
export default class ControlCard extends Card {
  constructor(l, t, s, i, n, r) {
    super(l, t, null, null, null, null, s, null, i, null, null, null, n, r),
      (this.isControl = !0),
      (this.At = "ab-control-card"),
      (this.Tt = !1);
  }
  Bs() {
    const l = {};
    return (
      (l[Card.qt.zt] = Card.Dt.Ti),
      (l[Card.qt.Et] = this.id),
      (l[Card.qt.Ft] = this.viewed),
      (l[Card.qt.Lt] = this.updated),
      (l[Card.qt.Ot] = this.expiresAt),
      (l[Card.qt.St] = this.extras),
      (l[Card.qt.Ut] = this.pinned),
      (l[Card.qt.Xt] = this.test),
      l
    );
  }
}
