import r from "../managers/braze-instance.js";
import c from "../common/event-logger.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
import {
  validateCustomProperties as rt,
  validateCustomString as nt,
} from "../util/validation-utils.js";
import { LOG_CUSTOM_EVENT_STRING as mt } from "../common/constants.js";
export function logCustomEvent(t, e) {
  if (!r.rr()) return !1;
  if (null == t || t.length <= 0)
    return (
      N.error(
        `logCustomEvent requires a non-empty eventName, got "${t}". Ignoring event.`,
      ),
      !1
    );
  if (!nt(t, "log custom event", "the event name")) return !1;
  const [o, n] = rt(
    e,
    mt,
    "eventProperties",
    `log custom event "${t}"`,
    "event",
  );
  if (!o) return !1;
  const i = r.v();
  if (i && i.ge(t))
    return N.info(`Custom Event "${t}" is blocklisted, ignoring.`), !1;
  const s = c.ds(d.CustomEvent, { n: t, p: n });
  if (s.ss) {
    N.info(`Logged custom event "${t}".`);
    for (const o of s.fe) ot.m().ue(et.ve, [t, e], o);
  }
  return s.ss;
}
