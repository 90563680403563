import {
  impressOnBottom as j,
  impressOnTop as $,
  detectImpression as g,
} from "../../common/detect-impression.js";
import { topIsInView as x, bottomIsInView as B } from "../../util/dom-utils.js";
import { logBannerImpressions } from "../log-banner-impressions.js";
import {
  BANNER_HTML_CLASS as I,
  BANNER_PLACEMENT_ID as k,
  CONTROL_BANNER_HTML_CLASS as q,
} from "./banner-to-html.js";
export function detectBannerImpressions() {
  const o = document.querySelectorAll(`.${I}, .${q}`),
    t = [];
  for (let n = 0; n < o.length; n++) {
    const s = o[n],
      i = s.getAttribute(k);
    if (!i) continue;
    const m = g.oo(s),
      r = g.no(s);
    if (m && r) continue;
    const e = x(s),
      c = B(s);
    e && !m && $(s), c && !r && j(s), g.oo(s) && g.no(s) && t.push(i);
  }
  t.length > 0 && logBannerImpressions(t);
}
