import { REQUEST_ATTEMPT_DEFAULT as Ae } from "../common/constants.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
const h = {
  O: {
    Xu: "data",
    Pi: "content_cards/sync",
    De: "feature_flags/sync",
    sn: "template",
    K: "banners/sync",
  },
  el: (e) => (null == e ? void 0 : e.ps(t.bs.EE)),
  Ac: (e) => (null == e ? void 0 : e.ps(t.bs.lE)),
  Rc: (e, s) => {
    null == e || e.js(t.bs.EE, s);
  },
  qc: (e, s) => {
    null == e || e.js(t.bs.lE, s);
  },
  Eu: (t, e) => {
    if (!t || !e) return -1;
    const s = h.el(t);
    if (null == s) return -1;
    const n = s[e];
    return null == n || isNaN(n) ? -1 : n;
  },
  Fu: (t, e) => {
    let s = Ae;
    if (!t || !e) return s;
    const n = h.Ac(t);
    return null == n ? s : ((s = n[e]), null == s || isNaN(s) ? Ae : s);
  },
  W: (t, e, s) => {
    if (!t || !e) return;
    let n = h.el(t);
    null == n && (n = {}), (n[e] = s), h.Rc(t, n);
  },
  ns: (t, e, s) => {
    if (!t || !e) return;
    let n = h.Ac(t);
    null == n && (n = {}), (n[e] = s), h.qc(t, n);
  },
  hs: (t, e) => {
    if (!t || !e) return;
    const s = h.Fu(t, e);
    h.ns(t, e, s + 1);
  },
};
export default h;
