import {
  GLOBAL_RATE_LIMIT_CAPACITY_DEFAULT as Zt,
  GLOBAL_RATE_LIMIT_REFILL_RATE_DEFAULT as hi,
} from "../common/constants.js";
export default class Jt {
  constructor(
    t = 0,
    i = [],
    s = [],
    h = [],
    e = null,
    l = null,
    r = { enabled: !1 },
    a = { enabled: !1, refresh_rate_limit: void 0 },
    n = { enabled: !0, capacity: Zt, refill_rate: hi, endpoint_overrides: {} },
    o = { enabled: !1, max_placements: 0 },
  ) {
    (this.dl = t),
      (this.wl = i),
      (this.yl = s),
      (this.Ll = h),
      (this.Fl = e),
      (this.El = l),
      (this.vl = r),
      (this.Gi = a),
      (this.Cl = n),
      (this.banners = o),
      (this.dl = t),
      (this.wl = i),
      (this.yl = s),
      (this.Ll = h),
      (this.Fl = e),
      (this.El = l),
      (this.vl = r),
      (this.Gi = a),
      (this.Cl = n),
      (this.banners = o);
  }
  Bs() {
    return {
      s: "5.6.1",
      l: this.dl,
      e: this.wl,
      a: this.yl,
      p: this.Ll,
      m: this.Fl,
      v: this.El,
      c: this.vl,
      f: this.Gi,
      grl: this.Cl,
      b: this.banners,
    };
  }
  static Qn(t) {
    let i = t.l;
    return (
      "5.6.1" !== t.s && (i = 0),
      new Jt(i, t.e, t.a, t.p, t.m, t.v, t.c, t.f, t.grl, t.b)
    );
  }
}
