import {
  convertMsToSeconds as L,
  dateFromUnixTimestamp as O,
} from "../../util/date-utils.js";
import { FEED_ANIMATION_DURATION as P } from "../../common/constants.js";
import u from "../../managers/subscription-manager.js";
import N from "../../../shared-lib/logger.js";
export default class Card {
  constructor(t, i, s, h, n, l, e, r, u, E, o, T, I, a, c, N) {
    (this.id = t),
      (this.viewed = i),
      (this.title = s),
      (this.imageUrl = h),
      (this.description = n),
      (this.created = l),
      (this.updated = e),
      (this.categories = r),
      (this.expiresAt = u),
      (this.url = E),
      (this.linkText = o),
      (this.aspectRatio = T),
      (this.extras = I),
      (this.pinned = a),
      (this.dismissible = c),
      (this.clicked = N),
      (this.id = t),
      (this.viewed = i || !1),
      (this.title = s || ""),
      (this.imageUrl = h),
      (this.description = n || ""),
      (this.created = l || null),
      (this.updated = e || null),
      (this.categories = r || []),
      (this.expiresAt = u || null),
      (this.url = E),
      (this.linkText = o),
      null == T
        ? (this.aspectRatio = null)
        : ((T = parseFloat(T.toString())),
          (this.aspectRatio = isNaN(T) ? null : T)),
      (this.extras = I || {}),
      (this.pinned = a || !1),
      (this.dismissible = c || !1),
      (this.dismissed = !1),
      (this.clicked = N || !1),
      (this.isControl = !1),
      (this.test = !1),
      (this._t = null),
      (this.Yt = null),
      (this.Zt = null);
  }
  subscribeToClickedEvent(t) {
    return this.ti().Ns(t);
  }
  subscribeToDismissedEvent(t) {
    return this.ii().Ns(t);
  }
  removeSubscription(t) {
    this.ti().removeSubscription(t), this.ii().removeSubscription(t);
  }
  removeAllSubscriptions() {
    this.ti().removeAllSubscriptions(), this.ii().removeAllSubscriptions();
  }
  dismissCard() {
    if (!this.dismissible || this.dismissed) return;
    "function" == typeof this.logCardDismissal && this.logCardDismissal();
    let t = this.bt;
    !t && this.id && (t = document.getElementById(this.id)),
      t &&
        ((t.style.height = t.offsetHeight + "px"),
        (t.className = t.className + " ab-hide"),
        setTimeout(function () {
          t &&
            t.parentNode &&
            ((t.style.height = "0"),
            (t.style.margin = "0"),
            setTimeout(function () {
              t && t.parentNode && t.parentNode.removeChild(t);
            }, Card.si));
        }, P));
  }
  ti() {
    return null == this._t && (this._t = new u()), this._t;
  }
  ii() {
    return null == this.Yt && (this.Yt = new u()), this.Yt;
  }
  wt() {
    const t = new Date().valueOf();
    return (
      !(null != this.Zt && t - this.Zt < Card.hi) &&
      ((this.Zt = t), (this.viewed = !0), !0)
    );
  }
  rt() {
    (this.viewed = !0), (this.clicked = !0), this.ti().X();
  }
  ft() {
    return (
      !(!this.dismissible || this.dismissed) &&
      ((this.dismissed = !0), this.ii().X(), !0)
    );
  }
  ni(t) {
    if (null == t || t[Card.li.Et] !== this.id) return !0;
    if (t[Card.li.ei]) return !1;
    if (
      null != t[Card.li.Lt] &&
      null != this.updated &&
      parseInt(t[Card.li.Lt]) < L(this.updated.valueOf())
    )
      return !0;
    if (
      (t[Card.li.Ft] && !this.viewed && (this.viewed = !0),
      t[Card.li.Wt] && !this.clicked && (this.clicked = t[Card.li.Wt]),
      null != t[Card.li.Gt] && (this.title = t[Card.li.Gt]),
      null != t[Card.li.Jt] && (this.imageUrl = t[Card.li.Jt]),
      null != t[Card.li.Kt] && (this.description = t[Card.li.Kt]),
      null != t[Card.li.Lt])
    ) {
      const i = O(t[Card.li.Lt]);
      null != i && (this.updated = i);
    }
    if (null != t[Card.li.Ot]) {
      let i;
      (i = t[Card.li.Ot] === Card.ri ? null : O(t[Card.li.Ot])),
        (this.expiresAt = i);
    }
    if (
      (null != t[Card.li.URL] && (this.url = t[Card.li.URL]),
      null != t[Card.li.Pt] && (this.linkText = t[Card.li.Pt]),
      null != t[Card.li.Qt])
    ) {
      const i = parseFloat(t[Card.li.Qt].toString());
      this.aspectRatio = isNaN(i) ? null : i;
    }
    return (
      null != t[Card.li.St] && (this.extras = t[Card.li.St]),
      null != t[Card.li.Ut] && (this.pinned = t[Card.li.Ut]),
      null != t[Card.li.Vt] && (this.dismissible = t[Card.li.Vt]),
      null != t[Card.li.Xt] && (this.test = t[Card.li.Xt]),
      !0
    );
  }
  Bs() {
    N.error("Must be implemented in a subclass");
  }
}
(Card.ri = -1),
  (Card.li = {
    Et: "id",
    Ft: "v",
    Vt: "db",
    ei: "r",
    Lt: "ca",
    Ut: "p",
    Ot: "ea",
    St: "e",
    zt: "tp",
    Jt: "i",
    Gt: "tt",
    Kt: "ds",
    URL: "u",
    Pt: "dm",
    Qt: "ar",
    Wt: "cl",
    Xt: "t",
  }),
  (Card.Dt = {
    Bt: "captioned_image",
    ui: "text_announcement",
    Ei: "short_news",
    oi: "banner_image",
    Ti: "control",
  }),
  (Card.qt = {
    Et: "id",
    Ft: "v",
    Vt: "db",
    Mt: "cr",
    Lt: "ca",
    Ut: "p",
    Nt: "t",
    Ot: "ea",
    St: "e",
    zt: "tp",
    Jt: "i",
    Gt: "tt",
    Kt: "ds",
    URL: "u",
    Pt: "dm",
    Qt: "ar",
    Wt: "cl",
    Xt: "s",
  }),
  (Card.Ii = {
    ai: "ADVERTISING",
    ci: "ANNOUNCEMENTS",
    Ni: "NEWS",
    mi: "SOCIAL",
  }),
  (Card.si = 400),
  (Card.hi = 1e4);
