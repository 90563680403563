const N = {
  init: function (n) {
    (void 0 === n && void 0 !== N.zg) || (N.zg = !!n), N.l || (N.l = !0);
  },
  destroy: function () {
    (N.l = !1), (N.zg = void 0), (N.vd = void 0);
  },
  setLogger: function (n) {
    "function" == typeof n
      ? (N.init(), (N.vd = n))
      : N.info("Ignoring setLogger call since logger is not a function");
  },
  toggleLogging: function () {
    N.init(),
      N.zg
        ? (console.log("Disabling Braze logging"), (N.zg = !1))
        : (console.log("Enabled Braze logging"), (N.zg = !0));
  },
  info: function (n) {
    if (N.zg) {
      const o = "Braze: " + n;
      null != N.vd ? N.vd(o) : console.log(o);
    }
  },
  warn: function (n) {
    if (N.zg) {
      const o = "Braze SDK Warning: " + n + " (v5.6.1)";
      null != N.vd ? N.vd(o) : console.warn(o);
    }
  },
  error: function (n) {
    if (N.zg) {
      const o = "Braze SDK Error: " + n + " (v5.6.1)";
      null != N.vd ? N.vd(o) : console.error(o);
    }
  },
};
export default N;
