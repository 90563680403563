import InAppMessage from "./in-app-message.js";
import u from "../../managers/subscription-manager.js";
export default class InAppMessageButton {
  constructor(s, t, i, r, h, e, n) {
    (this.text = s),
      (this.backgroundColor = t),
      (this.textColor = i),
      (this.borderColor = r),
      (this.clickAction = h),
      (this.uri = e),
      (this.id = n),
      (this.text = s || ""),
      (this.backgroundColor = t || InAppMessage.th.sh),
      (this.textColor = i || InAppMessage.th.ih),
      (this.borderColor = r || this.backgroundColor),
      (this.clickAction = h || InAppMessage.ClickAction.NONE),
      (this.uri = e),
      null == n && (n = InAppMessageButton.Jr),
      (this.id = n),
      (this.Co = !1),
      (this._t = new u());
  }
  subscribeToClickedEvent(s) {
    return this._t.Ns(s);
  }
  removeSubscription(s) {
    this._t.removeSubscription(s);
  }
  removeAllSubscriptions() {
    this._t.removeAllSubscriptions();
  }
  rt() {
    return !this.Co && ((this.Co = !0), this._t.X(), !0);
  }
  static fromJson(s) {
    return new InAppMessageButton(
      s.text,
      s.bg_color,
      s.text_color,
      s.border_color,
      s.click_action,
      s.uri,
      s.id,
    );
  }
}
InAppMessageButton.Jr = -1;
