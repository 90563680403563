import r, { OPTIONS as z } from "../../managers/braze-instance.js";
import { ContentCards, subscribeToContentCardsUpdates } from "../index.js";
import W from "../content-cards-provider-factory.js";
import {
  destroyFeedHtml as ee,
  detectFeedImpressions as nn,
  feedToHtml as tn,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as en,
  refreshFeed as on,
  registerFeedSubscriptionId as rn,
  updateFeedCards as an,
} from "../../common/feed-display.js";
import { setCardHeight as sn } from "../../Card/display/card-display.js";
import { setupFeedUI as fn } from "../../ui/js/index.js";
import { logger as N } from "../../../shared-lib/index.js";
export function showContentCards(n, t) {
  if (!r.rr()) return;
  fn();
  let e = !1;
  null == n && ((n = document.body), (e = !0));
  const o = r.sr(z.tn) || r.sr(z.en) || !1,
    i = W.m().ki(!1);
  "function" == typeof t && an(i, t(i.cards.slice()), i.lastUpdated, null, o);
  const a = tn(i, o, e),
    s = W.m(),
    f = s.pi();
  (null == i.lastUpdated ||
    new Date().valueOf() - i.lastUpdated.valueOf() > ContentCards.ar) &&
    (null == f || new Date().valueOf() - f > ContentCards.ar) &&
    (N.info(
      `Cached content cards were older than max TTL of ${ContentCards.ar} ms, requesting an update from the server.`,
    ),
    on(i, a),
    s.vi(new Date().valueOf()));
  const l = new Date().valueOf(),
    c = subscribeToContentCardsUpdates(function (n) {
      const e = a.querySelectorAll(".ab-refresh-button")[0];
      if (null != e) {
        let n = 500,
          t = (n -= new Date().valueOf() - l);
        const o = a.getAttribute(en);
        o && ((t = parseInt(o)), isNaN(t) || (n -= new Date().valueOf() - t)),
          setTimeout(
            function () {
              e.className = e.className.replace(/fa-spin/g, "");
            },
            Math.max(n, 0),
          );
      }
      let r = n.cards;
      "function" == typeof t && (r = t(r.slice())),
        an(i, r, n.lastUpdated, a, o);
    });
  rn(c, a);
  const u = function (n) {
    const t = n.querySelectorAll(".ab-feed");
    let o = null;
    for (let e = 0; e < t.length; e++) t[e].parentNode === n && (o = t[e]);
    null != o
      ? (ee(o), null != o.parentNode && o.parentNode.replaceChild(a, o))
      : n.appendChild(a),
      setTimeout(function () {
        a.className = a.className.replace("ab-hide", "ab-show");
      }, 0),
      e && a.focus(),
      nn(i, a),
      sn(i.cards, n);
  };
  var d;
  null != n
    ? u(n)
    : (window.onload =
        ((d = window.onload),
        function () {
          "function" == typeof d && d(new Event("oldLoad")), u(document.body);
        }));
}
