import { Guid as G } from "../../shared-lib/index.js";
export default class u {
  constructor() {
    this.Ir = {};
  }
  Ns(t) {
    if ("function" != typeof t) return null;
    const i = G.Rt();
    return (this.Ir[i] = t), i;
  }
  removeSubscription(t) {
    delete this.Ir[t];
  }
  removeAllSubscriptions() {
    this.Ir = {};
  }
  ic() {
    return Object.keys(this.Ir).length;
  }
  X(t) {
    const i = [];
    for (const s in this.Ir) {
      const r = this.Ir[s];
      i.push(r(t));
    }
    return i;
  }
}
