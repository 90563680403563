import s from "../common/base-provider.js";
import r from "../managers/braze-instance.js";
import Feed from "./feed.js";
import {
  newCardFromFeedJson as lt,
  newCardFromSerializedValue as tt,
} from "../Card/util/card-factory.js";
import { rehydrateDateAfterJsonization as V } from "../util/date-utils.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
export default class re extends s {
  constructor(t, s) {
    super(),
      (this.C = t),
      (this.Ue = s),
      (this.cards = []),
      (this.Ae = null),
      (this.C = t),
      (this.Ue = s),
      (this.Ms = new u()),
      r.N(this.Ms),
      this.Xs();
  }
  Xs() {
    let s = [];
    this.C && (s = this.C.ps(t.bs.Be) || []);
    const i = [];
    for (let t = 0; t < s.length; t++) {
      const e = tt(s[t]);
      null != e && i.push(e);
    }
    (this.cards = i), this.C && (this.Ae = V(this.C.ps(t.bs.Ge)));
  }
  He(s) {
    const i = [];
    let e = null,
      r = {};
    this.C && (r = this.C.ps(t.bs.Ct) || {});
    const h = {};
    for (let t = 0; t < s.length; t++) {
      e = s[t];
      const o = lt(e);
      if (null != o) {
        const t = o.id;
        t && r[t] && ((o.viewed = !0), (h[t] = !0)), i.push(o);
      }
    }
    (this.cards = i),
      this.Ri(),
      (this.Ae = new Date()),
      this.C && (this.C.js(t.bs.Ct, h), this.C.js(t.bs.Ge, this.Ae));
  }
  Ri() {
    var s;
    const i = [];
    for (let t = 0; t < this.cards.length; t++) i.push(this.cards[t].Bs());
    null === (s = this.C) || void 0 === s || s.js(t.bs.Be, i);
  }
  U(t) {
    null != t &&
      t.feed &&
      (this.Xs(),
      this.He(t.feed),
      this.Ms.X(new Feed(this.cards.slice(), this.Ae)));
  }
  Je() {
    this.Xs();
    const t = [],
      s = new Date();
    for (let i = 0; i < this.cards.length; i++) {
      const e = this.cards[i].expiresAt;
      let r = !0;
      null != e && (r = e >= s), r && t.push(this.cards[i]);
    }
    return new Feed(t, this.Ae);
  }
  zi() {
    this.Ue && this.Ue.requestFeedRefresh();
  }
  Fs(t) {
    return this.Ms.Ns(t);
  }
  clearData(s) {
    null == s && (s = !1),
      (this.cards = []),
      (this.Ae = null),
      s && this.C && (this.C.Ls(t.bs.Be), this.C.Ls(t.bs.Ge)),
      this.Ms.X(new Feed(this.cards.slice(), this.Ae));
  }
}
