import r from "../managers/braze-instance.js";
import { getAlias as Yt } from "../managers/utils.js";
import { isObject as Ct } from "../util/code-utils.js";
import {
  convertMsToSeconds as L,
  timestampOrNow as Qt,
} from "../util/date-utils.js";
export default class ve {
  constructor(t, s, i, r, e) {
    (this.userId = t),
      (this.type = s),
      (this.time = i),
      (this.sessionId = r),
      (this.data = e),
      (this.userId = t),
      (this.type = s),
      (this.time = Qt(i)),
      (this.sessionId = r),
      (this.data = e);
  }
  Zr() {
    var t;
    const s = {
      name: this.type,
      time: L(this.time),
      data: this.data || {},
      session_id: this.sessionId,
    };
    null != this.userId && (s.user_id = this.userId);
    const i = (null === (t = r.Sr()) || void 0 === t ? void 0 : t.jh()) || !1;
    if (!s.user_id && !i) {
      const t = Yt(r.j());
      t && (s.alias = t);
    }
    return s;
  }
  Bs() {
    return {
      u: this.userId,
      t: this.type,
      ts: this.time,
      s: this.sessionId,
      d: this.data,
    };
  }
  static fromJson(t) {
    return new ve(t.user_id, t.name, t.time, t.session_id, t.data);
  }
  static AE(t) {
    return null != t && Ct(t) && null != t.t && "" !== t.t;
  }
  static Qn(t) {
    return new ve(t.u, t.t, t.ts, t.s, t.d);
  }
}
