import r from "../managers/braze-instance.js";
import W from "./content-cards-provider-factory.js";
export function subscribeToContentCardsUpdates(t) {
  if (!r.rr()) return;
  const n = W.m(),
    o = n.Fs(t);
  if (!n.Ts()) {
    const t = r.nn();
    if (t) {
      const r = t.rn(() => {
        n.zi();
      });
      r && n.Rs(r);
    }
  }
  return o;
}
