import r, { OPTIONS as z } from "../../managers/braze-instance.js";
export function attachCSS(n, t, o) {
  const c = n || document.querySelector("head"),
    e = `ab-${t}-css-definitions-${"5.6.1".replace(/\./g, "-")}`;
  if (!c) return;
  const s = c.ownerDocument || document;
  if (null == s.getElementById(e)) {
    const n = s.createElement("style");
    (n.innerHTML = o || ""), (n.id = e);
    const t = r.sr(z.ir);
    null != t && n.setAttribute("nonce", t), c.appendChild(n);
  }
}
