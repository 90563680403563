import Card from "./card.js";
export default class ImageOnly extends Card {
  constructor(s, t, i, h, r, e, l, n, o, a, u, c, d, m) {
    super(s, t, null, i, null, h, r, e, l, n, o, a, u, c, d, m),
      (this.At = "ab-image-only"),
      (this.Tt = !1),
      (this.test = !1);
  }
  Bs() {
    const s = {};
    return (
      (s[Card.qt.zt] = Card.Dt.oi),
      (s[Card.qt.Et] = this.id),
      (s[Card.qt.Ft] = this.viewed),
      (s[Card.qt.Jt] = this.imageUrl),
      (s[Card.qt.Lt] = this.updated),
      (s[Card.qt.Mt] = this.created),
      (s[Card.qt.Nt] = this.categories),
      (s[Card.qt.Ot] = this.expiresAt),
      (s[Card.qt.URL] = this.url),
      (s[Card.qt.Pt] = this.linkText),
      (s[Card.qt.Qt] = this.aspectRatio),
      (s[Card.qt.St] = this.extras),
      (s[Card.qt.Ut] = this.pinned),
      (s[Card.qt.Vt] = this.dismissible),
      (s[Card.qt.Wt] = this.clicked),
      (s[Card.qt.Xt] = this.test),
      s
    );
  }
}
