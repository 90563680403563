import { isDate as Nt } from "../../util/code-utils.js";
import {
  convertMsToSeconds as L,
  dateFromUnixTimestamp as O,
  secondsAgo as es,
  secondsInTheFuture as ls,
} from "../../util/date-utils.js";
export default class fr {
  constructor(t, s, e, i) {
    (this.yE = t),
      (this.HE = s),
      (this.comparator = e),
      (this.LE = i),
      (this.yE = t),
      (this.HE = s),
      (this.comparator = e),
      (this.LE = i),
      this.HE === fr.QE.UE &&
        this.comparator !== fr.wE.XE &&
        this.comparator !== fr.wE.BE &&
        this.comparator !== fr.wE.jE &&
        this.comparator !== fr.wE.zE &&
        (this.LE = O(this.LE));
  }
  xc(t) {
    let s = null;
    switch ((null != t && (s = t[this.yE]), this.comparator)) {
      case fr.wE.FE:
        return null != s && s.valueOf() === this.LE.valueOf();
      case fr.wE.kE:
        return null == s || s.valueOf() !== this.LE.valueOf();
      case fr.wE.vE:
        return null != s && typeof s == typeof this.LE && s > this.LE;
      case fr.wE.XE:
        return this.HE === fr.QE.UE
          ? null != s && Nt(s) && es(s) <= this.LE.valueOf()
          : null != s && typeof s == typeof this.LE && s >= this.LE;
      case fr.wE.xE:
        return null != s && typeof s == typeof this.LE && s < this.LE;
      case fr.wE.BE:
        return this.HE === fr.QE.UE
          ? null != s && Nt(s) && es(s) >= this.LE.valueOf()
          : null != s && typeof s == typeof this.LE && s <= this.LE;
      case fr.wE.JE:
        return (
          null != s &&
          "string" == typeof s &&
          typeof s == typeof this.LE &&
          null != s.match(this.LE)
        );
      case fr.wE.VE:
        return null != s;
      case fr.wE.qE:
        return null == s;
      case fr.wE.jE:
        return null != s && Nt(s) && ls(s) < this.LE;
      case fr.wE.zE:
        return null != s && Nt(s) && ls(s) > this.LE;
      case fr.wE.PE:
        return (
          null == s ||
          typeof s != typeof this.LE ||
          "string" != typeof s ||
          null == s.match(this.LE)
        );
    }
    return !1;
  }
  static fromJson(t) {
    return new fr(
      t.property_key,
      t.property_type,
      t.comparator,
      t.property_value,
    );
  }
  Bs() {
    let t = this.LE;
    return (
      Nt(this.LE) && (t = L(t.valueOf())),
      { k: this.yE, t: this.HE, c: this.comparator, v: t }
    );
  }
  static Qn(t) {
    return new fr(t.k, t.t, t.c, t.v);
  }
}
(fr.QE = { WE: "boolean", ZE: "number", $E: "string", UE: "date" }),
  (fr.wE = {
    FE: 1,
    kE: 2,
    vE: 3,
    XE: 4,
    xE: 5,
    BE: 6,
    JE: 10,
    VE: 11,
    qE: 12,
    jE: 15,
    zE: 16,
    PE: 17,
  });
