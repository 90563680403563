import qt from "./auth-manager.js";
import s from "../common/base-provider.js";
import ro from "../util/browser-detector.js";
import Ot from "./device-manager.js";
import DeviceProperties from "../Core/device-properties.js";
import {
  isArray as w,
  keys as y,
  validateValueIsFromEnum as ta,
  values as Rt,
} from "../util/code-utils.js";
import Pt from "./network-manager.js";
import Lt from "../request-controller.js";
import Dt from "./server-config-manager.js";
import Mt from "./session-manager.js";
import ne, { STORAGE_KEYS as t } from "./storage-manager.js";
import Wt from "./storage-manager-factory.js";
import u from "./subscription-manager.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
import kt from "../User/user-manager.js";
import { User } from "../User/index.js";
import { parseQueryStringKeyValues as ll } from "../util/url-utils.js";
import { WindowUtils as no } from "../util/window-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../common/constants.js";
import { SupportedOptions as zt, logger as N } from "../../shared-lib/index.js";
import _t from "../models/identifier.js";
const z = {
  Oo: "allowCrawlerActivity",
  Ro: "baseUrl",
  Lo: "noCookies",
  Wo: "devicePropertyAllowlist",
  Da: "disablePushTokenMaintenance",
  Vo: "enableLogging",
  Ko: "enableSdkAuthentication",
  Ca: "manageServiceWorkerExternally",
  Go: "minimumIntervalBetweenTriggerActionsInSeconds",
  Yo: "sessionTimeoutInSeconds",
  Ho: "appVersion",
  Xo: "appVersionNumber",
  _a: "serviceWorkerLocation",
  Ba: "safariWebsitePushId",
  Ea: "localization",
  ir: "contentSecurityNonce",
  er: "allowUserSuppliedJavascript",
  Jo: "inAppMessageZIndex",
  Do: "openInAppMessagesInNewTab",
  tn: "openCardsInNewTab",
  en: "openNewsFeedCardsInNewTab",
  mh: "requireExplicitInAppMessageDismissal",
  Zo: "doNotLoadFontAwesome",
  Qo: "deviceId",
  Aa: "serviceWorkerScope",
  Wh: "sdkFlavor",
};
class Vt {
  constructor() {
    (this.yn = ""),
      (this.Vh = ""),
      (this.$h = void 0),
      (this.Yh = null),
      (this.wn = null),
      (this.S = null),
      (this.Ue = null),
      (this.B = null),
      (this.T = null),
      (this.C = null),
      (this.As = null),
      (this.Xh = ""),
      (this.isInitialized = !1),
      (this.Zh = !1),
      (this.Qh = new u()),
      (this.Ia = new u()),
      (this.options = {}),
      (this.Na = []),
      (this.Oa = []),
      (this.Ir = []),
      (this.Vh = "5.6.1");
  }
  Ta(t) {
    this.Qh.Ns(t);
  }
  Nh(t) {
    this.Ia.Ns(t);
  }
  initialize(i, s) {
    if (this.nt())
      return N.info("Braze has already been initialized with an API key."), !0;
    this.options = s || {};
    let e = this.sr(z.Vo);
    const r = ll(no.Ra());
    if (
      (r && "true" === r.brazeLogging && (e = !0),
      N.init(e),
      N.info(
        `Initialization Options: ${JSON.stringify(this.options, null, 2)}`,
      ),
      null == i || "" === i || "string" != typeof i)
    )
      return N.error("Braze requires a valid API key to be initialized."), !1;
    this.yn = i;
    let n = this.sr(z.Ro);
    if (null == n || "" === n || "string" != typeof n)
      return N.error("Braze requires a valid baseUrl to be initialized."), !1;
    !1 === /^https?:/.test(n) && (n = `https://${n}`);
    const o = n;
    if (
      ((n = document.createElement("a")),
      (n.href = o),
      "/" === n.pathname && (n = `${n}api/v3`),
      (this.Xh = n.toString()),
      ro.Pa && !this.sr(z.Oo))
    )
      return (
        N.info("Ignoring activity from crawler bot " + navigator.userAgent),
        (this.Zh = !0),
        !1
      );
    const h = this.sr(z.Lo) || !1;
    if (
      ((this.C = Wt.La(i, h)), h && this.C.Ua(i), new ne.ne(null, !0).gr(t.ae))
    )
      return (
        N.info("Ignoring all activity due to previous opt out"),
        (this.Zh = !0),
        !1
      );
    for (const t of y(this.options))
      -1 === Rt(zt).indexOf(t) &&
        N.warn(`Ignoring unknown initialization option '${t}'.`);
    const a = ["mparticle", "wordpress", "tealium"];
    if (null != this.sr(z.Wh)) {
      const t = this.sr(z.Wh);
      -1 !== a.indexOf(t)
        ? (this.$h = t)
        : N.error("Invalid sdk flavor passed: " + t);
    }
    let l = this.sr(zt.Wo);
    if (null != l)
      if (w(l)) {
        const t = [];
        for (let i = 0; i < l.length; i++)
          ta(
            DeviceProperties,
            l[i],
            "devicePropertyAllowlist contained an invalid value.",
            "DeviceProperties",
          ) && t.push(l[i]);
        l = t;
      } else
        N.error(
          "devicePropertyAllowlist must be an array. Defaulting to all properties.",
        ),
          (l = null);
    const c = this.sr(z.Qo);
    if (c) {
      const i = new _t(c);
      this.C.uu(t.iu.Qo, i);
    }
    (this.wn = new Ot(this.C, l)),
      (this.B = new Dt(this.C)),
      (this.As = new kt(this.B, this.C)),
      (this.T = new Mt(this.C, this.As, this.B, this.sr(z.Yo)));
    const f = new u();
    return (
      (this.Yh = new qt(this.C, this.sr(z.Ko), f)),
      this.N(f),
      (this.S = new Pt(
        this.wn,
        this.C,
        this.Yh,
        this.As,
        this.T,
        this.B,
        this.yn,
        this.Xh,
        this.Vh,
        this.$h || "",
        this.sr(z.Ho),
        this.sr(z.Xo),
      )),
      (this.Ue = new Lt(
        this.yn,
        this.Xh,
        this.T,
        this.wn,
        this.As,
        this.B,
        this.C,
        (t) => {
          if (this.nt()) for (const i of this.lr()) i.U(t);
        },
        this.Yh,
        this.S,
      )),
      this.Ue.initialize(),
      h || this.C.Wa(),
      N.info(
        `Initialized for the Braze backend at "${this.sr(
          z.Ro,
        )}" with API key "${this.yn}".`,
      ),
      ot.p(),
      this.B.Re(() => {
        var t;
        this.isInitialized &&
          (null === (t = this.B) || void 0 === t ? void 0 : t.Qi()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.isInitialized) return;
            (0, t.default)();
          });
      }),
      this.Ue.rn(() => {
        var t;
        this.isInitialized &&
          (null === (t = this.B) || void 0 === t ? void 0 : t.Qi()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.isInitialized) return;
            (0, t.default)(void 0, void 0, !0);
          });
      }),
      this.Qh.X(this.options),
      (this.isInitialized = !0),
      window.dispatchEvent(new CustomEvent("braze.initialized")),
      !0
    );
  }
  destroy(t) {
    if ((N.destroy(), this.nt())) {
      this.Ia.X(), this.Ia.removeAllSubscriptions();
      for (const t of this.Na) t.destroy();
      this.Na = [];
      for (const t of this.Oa) t.clearData(!1);
      (this.Oa = []),
        this.removeAllSubscriptions(),
        (this.Ir = []),
        null != this.Ue && this.Ue.destroy(),
        (this.Ue = null),
        (this.Yh = null),
        (this.wn = null),
        (this.S = null),
        (this.B = null),
        (this.T = null),
        (this.As = null),
        (this.options = {}),
        (this.$h = void 0),
        (this.isInitialized = !1),
        (this.Zh = !1),
        t && (this.C = null);
    }
  }
  rr() {
    return !this.Va() && (!!this.nt() || (console.warn(p), !1));
  }
  Ma() {
    return this.yn;
  }
  Sr() {
    return this.Yh;
  }
  Z() {
    return this.Xh;
  }
  te() {
    return this.wn;
  }
  g() {
    return this.S;
  }
  sr(t) {
    return this.options[t];
  }
  lr() {
    return this.Oa;
  }
  nn() {
    return this.Ue;
  }
  v() {
    return this.B;
  }
  P() {
    return this.T;
  }
  j() {
    return this.C;
  }
  cr() {
    if (this.As && this.Ue) return new User(this.As, this.Ue);
  }
  tr() {
    return this.As;
  }
  nr() {
    return !0 === this.sr(z.er);
  }
  h(t) {
    let i = !1;
    for (const s of this.Na) s === t && (i = !0);
    i || this.Na.push(t);
  }
  _(t) {
    let i = !1;
    for (const s of this.Oa) s.constructor === t.constructor && (i = !0);
    t instanceof s && !i && this.Oa.push(t);
  }
  N(t) {
    t instanceof u && this.Ir.push(t);
  }
  removeAllSubscriptions() {
    if (this.rr()) for (const t of this.Ir) t.removeAllSubscriptions();
  }
  removeSubscription(t) {
    if (this.rr()) for (const i of this.Ir) i.removeSubscription(t);
  }
  re(t) {
    this.Zh = t;
  }
  nt() {
    return this.isInitialized;
  }
  Va() {
    return this.Zh;
  }
  Ui() {
    return this.Vh;
  }
}
const r = new Vt();
export { r as default, Vt as BrazeSdkInstance, z as OPTIONS };
