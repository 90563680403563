import r from "../managers/braze-instance.js";
import InAppMessage from "./models/in-app-message.js";
import se from "./in-app-message-manager-factory.js";
import { logInAppMessageImpression } from "./log-in-app-message-impression.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as sr } from "../common/constants.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageClick(o) {
  if (!r.rr()) return !1;
  if (!(o instanceof InAppMessage)) return N.error(sr), !1;
  const s = se.ea().ds(o, d.Cr);
  if (s) {
    o.ko() || logInAppMessageImpression(o);
    for (let r = 0; r < s.fe.length; r++)
      ot.m().ue(et.Ao, [o.triggerId], s.fe[r]);
  }
  return s.ss;
}
