import { Card } from "./models/index.js";
import _ from "./card-manager-factory.js";
import { MUST_BE_CARD_WARNING_SUFFIX as K } from "../common/constants.js";
import r from "../managers/braze-instance.js";
import { logger as N } from "../../shared-lib/index.js";
export function logCardClick(o, m) {
  return (
    !!r.rr() &&
    (o instanceof Card ? _.ea().logClick(o, m).ss : (N.error("card " + K), !1))
  );
}
