import ai from "./client-hints-parser.js";
import gi from "./user-agent-parser.js";
import { Browsers as oi, OperatingSystems as so } from "./device-constants.js";
class ui {
  constructor() {
    const t = navigator.userAgentData ? ai : gi;
    (this.Sg = new t()),
      (this.userAgent = navigator.userAgent),
      (this.browser = this.Sg.ef()),
      (this.version = this.Sg.ff()),
      (this.OS = null),
      this.Ja().then((t) => (this.OS = t));
    const i = navigator;
    (this.language = (
      i.userLanguage ||
      i.language ||
      i.browserLanguage ||
      i.systemLanguage ||
      ""
    ).toLowerCase()),
      (this.Pa = ui.vg(this.userAgent));
  }
  dE() {
    return this.browser === oi.Bg;
  }
  Qa() {
    return this.OS || null;
  }
  Ja() {
    return this.OS
      ? Promise.resolve(this.OS)
      : this.Sg.Ja(ui.kg).then((t) => ((this.OS = t), t));
  }
  static vg(t) {
    t = t.toLowerCase();
    const i = [
      "googlebot",
      "bingbot",
      "slurp",
      "duckduckbot",
      "baiduspider",
      "yandex",
      "facebookexternalhit",
      "sogou",
      "ia_archiver",
      "https://github.com/prerender/prerender",
      "aolbuild",
      "bingpreview",
      "msnbot",
      "adsbot",
      "mediapartners-google",
      "teoma",
      "taiko",
    ];
    for (let n = 0; n < i.length; n++) if (-1 !== t.indexOf(i[n])) return !0;
    return !1;
  }
}
ui.kg = [
  { string: navigator.platform, cf: "Win", identity: so.Og },
  { string: navigator.platform, cf: "Mac", identity: so.Pg },
  { string: navigator.platform, cf: "BlackBerry", identity: "BlackBerry" },
  { string: navigator.platform, cf: "FreeBSD", identity: "FreeBSD" },
  { string: navigator.platform, cf: "OpenBSD", identity: "OpenBSD" },
  { string: navigator.platform, cf: "Nintendo", identity: "Nintendo" },
  { string: navigator.platform, cf: "SunOS", identity: "SunOS" },
  { string: navigator.platform, cf: "PlayStation", identity: "PlayStation" },
  { string: navigator.platform, cf: "X11", identity: "X11" },
  {
    string: navigator.userAgent,
    cf: ["iPhone", "iPad", "iPod"],
    identity: so.ao,
  },
  { string: navigator.platform, cf: "Pike v", identity: so.ao },
  { string: navigator.userAgent, cf: ["Web0S"], identity: "WebOS" },
  { string: navigator.userAgent, cf: "Tizen", identity: "Tizen" },
  {
    string: navigator.platform,
    cf: ["Linux armv7l", "Android"],
    identity: so.xg,
  },
  { string: navigator.userAgent, cf: ["Android"], identity: so.xg },
  { string: navigator.platform, cf: "Linux", identity: "Linux" },
];
const ro = new ui();
export { ro as default, ui as BrowserDetector };
