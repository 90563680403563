import { buttonsFromSerializedInAppMessage as pe } from "../in-app-message-factory.js";
import InAppMessage from "./in-app-message.js";
export default class ModalMessage extends InAppMessage {
  constructor(
    e,
    r,
    s,
    t,
    i,
    o,
    a,
    p,
    m,
    n,
    u,
    c,
    d,
    f,
    l,
    g,
    j,
    v,
    x,
    z,
    h,
    w,
    y,
    S,
    b,
    k,
    q,
    A,
  ) {
    super(
      e,
      r,
      void 0,
      s,
      t,
      i,
      o,
      a,
      (p = p || InAppMessage.DismissType.MANUAL),
      m,
      n,
      u,
      c,
      d,
      f,
      l,
      g,
      j,
      v,
      x,
      z,
      h,
      w,
      y,
      S,
      (b = b || InAppMessage.CropType.FIT_CENTER),
      void 0,
      k,
      q,
      A,
    ),
      (this.do = InAppMessage.TextAlignment.CENTER);
  }
  Bs() {
    return super.Bs(ModalMessage.Dt);
  }
  static hn(e) {
    return new ModalMessage(
      e[InAppMessage.qt.ra],
      e[InAppMessage.qt.sa],
      e[InAppMessage.qt.St],
      e[InAppMessage.qt.ta],
      e[InAppMessage.qt.ia],
      e[InAppMessage.qt.URI],
      e[InAppMessage.qt.oa],
      e[InAppMessage.qt.pa],
      e[InAppMessage.qt.ma],
      e[InAppMessage.qt.na],
      e[InAppMessage.qt.Jt],
      e[InAppMessage.qt.ua],
      e[InAppMessage.qt.ca],
      e[InAppMessage.qt.fa],
      e[InAppMessage.qt.da],
      e[InAppMessage.qt.la],
      e[InAppMessage.qt.ga],
      e[InAppMessage.qt.ja],
      e[InAppMessage.qt.xa],
      e[InAppMessage.qt.za],
      e[InAppMessage.qt.ha],
      e[InAppMessage.qt.va],
      e[InAppMessage.qt.wa],
      pe(e[InAppMessage.qt.ya]),
      e[InAppMessage.qt.Sa],
      e[InAppMessage.qt.ka],
      e[InAppMessage.qt.CSS],
      e[InAppMessage.qt.qa],
    );
  }
}
ModalMessage.Dt = InAppMessage.Qe.dn;
