import _t from "../models/identifier.js";
import { getByteLength as er } from "../util/string-utils.js";
import ti from "../models/push-token.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
export default class kt {
  constructor(t, s) {
    (this.B = t), (this.C = s), (this.B = t), (this.C = s);
  }
  getUserId() {
    const s = this.C.tu(t.iu.su);
    if (null == s) return null;
    let i = s.eu,
      e = er(i);
    if (e > User.mr) {
      for (; e > User.mr; ) (i = i.slice(0, i.length - 1)), (e = er(i));
      (s.eu = i), this.C.uu(t.iu.su, s);
    }
    return i;
  }
  ou(s) {
    const i = null == this.getUserId();
    this.C.uu(t.iu.su, new _t(s)), i && this.C.ru(s);
  }
  setCustomUserAttribute(t, s) {
    if (this.B.hu(t))
      return (
        N.info('Custom Attribute "' + t + '" is blocklisted, ignoring.'), !1
      );
    const i = {};
    return (i[t] = s), this.nu(User.lu, i, !0);
  }
  nu(t, s, i = !1, e = !1) {
    const u = this.C.mu(this.getUserId(), t, s);
    let o = "",
      r = t,
      h = s;
    return (
      i &&
        ((o = " custom"),
        "object" == typeof s &&
          ((r = Object.keys(s)[0]),
          (h = s[r]),
          "object" == typeof h && (h = JSON.stringify(h, null, 2)))),
      !e && u && N.info(`Logged${o} attribute ${r} with value ${h}`),
      u
    );
  }
  En(s, i, e, u, o) {
    this.nu("push_token", s, !1, !0),
      this.nu("custom_push_public_key", e, !1, !0),
      this.nu("custom_push_user_auth", u, !1, !0),
      this.nu("custom_push_vapid_public_key", o, !1, !0);
    const r = it.Gs._s,
      h = new it(r, N),
      n = new ti(s, i, e, u, o);
    this.C.js(t.bs.Kn, n.Bs()), h.setItem(r.Os.cu, r.oe, !0);
  }
  Cn(s) {
    if (
      (this.nu("push_token", null, !1, !0),
      this.nu("custom_push_public_key", null, !1, !0),
      this.nu("custom_push_user_auth", null, !1, !0),
      this.nu("custom_push_vapid_public_key", null, !1, !0),
      s)
    ) {
      const s = it.Gs._s,
        i = new it(s, N);
      this.C.js(t.bs.Kn, !1), i.setItem(s.Os.cu, s.oe, !1);
    }
  }
}
