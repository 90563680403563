export default {
  Oo: "allowCrawlerActivity",
  Ro: "baseUrl",
  Lo: "noCookies",
  Wo: "devicePropertyAllowlist",
  Da: "disablePushTokenMaintenance",
  Vo: "enableLogging",
  Ko: "enableSdkAuthentication",
  Ca: "manageServiceWorkerExternally",
  Go: "minimumIntervalBetweenTriggerActionsInSeconds",
  Yo: "sessionTimeoutInSeconds",
  Ho: "appVersion",
  Xo: "appVersionNumber",
  _a: "serviceWorkerLocation",
  Ba: "safariWebsitePushId",
  Ea: "localization",
  ir: "contentSecurityNonce",
  er: "allowUserSuppliedJavascript",
  Jo: "inAppMessageZIndex",
  Do: "openInAppMessagesInNewTab",
  en: "openNewsFeedCardsInNewTab",
  mh: "requireExplicitInAppMessageDismissal",
  Zo: "doNotLoadFontAwesome",
  Qo: "deviceId",
  Aa: "serviceWorkerScope",
  Wh: "sdkFlavor",
  tn: "openCardsInNewTab",
};
