import r from "../managers/braze-instance.js";
import HtmlMessage from "./models/html-message.js";
import se from "./in-app-message-manager-factory.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageHtmlClick(e, t, s) {
  if (!r.rr()) return !1;
  if (!(e instanceof HtmlMessage))
    return (
      N.error(
        "inAppMessage argument to logInAppMessageHtmlClick must be an HtmlMessage object.",
      ),
      !1
    );
  let o = d.Cr;
  null != t && (o = d.Or);
  const m = se.ea().ds(e, o, t, s);
  if (m.ss)
    for (let r = 0; r < m.fe.length; r++)
      ot.m().ue(et.Ao, [e.triggerId, t], m.fe[r]);
  return m.ss;
}
