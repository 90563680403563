import { logger as N } from "../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../common/constants.js";
import r from "../managers/braze-instance.js";
import { isArray as w } from "../util/code-utils.js";
import { isValidBannerPlacementId as D } from "../util/validation-utils.js";
import i from "./banner-provider-factory.js";
export function requestBannersRefresh(e, n, o) {
  if (!r.rr()) return void N.warn(p);
  const t = r.v();
  if (!t) return void N.warn(p);
  if (!t.Us()) return void N.info("Banners are not enabled.");
  if (!w(e) || 0 === e.length)
    return void N.warn("placementIds should be a non-empty array.");
  if (i.m().Is())
    return void N.warn(
      "Banners can be refreshed only once per session per user.",
    );
  const s = t.ee();
  e.length > s &&
    (N.warn(
      `Number of placement IDs requested exceeds the max allowed. Trimming placementIds array from length ${e.length} to ${s} (max allowed).`,
    ),
    (e = e.slice(0, s))),
    0 !==
      (e = e.filter(
        (e) =>
          !!D(e) ||
          (N.warn(
            `Placement ID should be a valid utf8 string with no whitespaces, filtering out: ${e}`,
          ),
          !1),
      )).length &&
      (N.info(`Requesting banners for placement IDs: ${JSON.stringify(e)}`),
      i.m().$(e, n, o));
}
