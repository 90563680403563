const d = {
    CustomEvent: "ce",
    Pr: "p",
    mc: "pc",
    Cc: "ca",
    Mu: "i",
    Li: "ie",
    xt: "cci",
    $t: "ccic",
    lt: "ccc",
    dt: "ccd",
    Jl: "ss",
    Gl: "se",
    Nr: "si",
    Cr: "sc",
    Or: "sbc",
    yc: "sfe",
    zo: "iec",
    dc: "lr",
    cc: "uae",
    yt: "ci",
    ut: "cc",
    vc: "lcaa",
    gc: "lcar",
    fu: "inc",
    pu: "add",
    bu: "rem",
    au: "set",
    du: "ncam",
    bc: "sgu",
    yr: "ffi",
    ro: "bi",
    fs: "bc",
  },
  dr = { Fr: "feed_displayed", Mc: "content_cards_displayed" };
export { d as EventTypes, dr as InternalEventTypes };
