import l, { readResponseHeaders as X } from "../util/net.js";
import s from "../common/base-provider.js";
import r from "../managers/braze-instance.js";
import ContentCards from "./content-cards.js";
import { dateFromUnixTimestamp as O } from "../util/date-utils.js";
import { isURIJavascriptOrData as Y } from "../util/url-utils.js";
import {
  newCardFromContentCardsJson as Z,
  newCardFromSerializedValue as tt,
} from "../Card/util/card-factory.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
import c from "../common/event-logger.js";
import h from "../util/request-header-utils.js";
import { randomInclusive as a } from "../util/math.js";
import { MAX_ERROR_RETRIES_CONTENT_CARDS as st } from "../common/constants.js";
import {
  logger as N,
  IndexedDBAdapter as it,
  EventTypes as d,
} from "../../shared-lib/index.js";
export default class Q extends s {
  constructor(t, s, i, e, h) {
    super(),
      (this.As = t),
      (this.C = s),
      (this.B = i),
      (this.Js = e),
      (this.S = h),
      (this.As = t),
      (this.C = s),
      (this.B = i),
      (this.Js = e),
      (this.S = h),
      (this.Ms = new u()),
      r.N(this.Ms),
      (this.$s = 0),
      (this.Ps = 0),
      (this.cards = []),
      this.Xs();
    const n = it.Gs._s;
    new it(n, N).Hs(n.Os.Ks, (t) => {
      this.Qs(t);
    }),
      (this.Vs = null),
      (this.k = null),
      (this.Ws = null),
      (this.Ys = null),
      (this.Zs = null),
      (this.fi = 10),
      (this.di = 0);
  }
  pi() {
    return this.Vs;
  }
  vi(t) {
    this.Vs = t;
  }
  Ts() {
    return this.k;
  }
  Rs(t) {
    this.k = t;
  }
  Xs() {
    if (!this.C) return;
    const s = this.C.ps(t.bs.wi) || [],
      i = [];
    for (let t = 0; t < s.length; t++) {
      const e = tt(s[t]);
      null != e && i.push(e);
    }
    (this.cards = this.Ci(this.bi(i, !1))),
      (this.$s = this.C.ps(t.bs.gi) || this.$s),
      (this.Ps = this.C.ps(t.bs.yi) || this.Ps);
  }
  ji(s, i = !1, e = 0, h = 0) {
    let r;
    if (i) {
      r = [];
      for (const t of this.cards) t.test && r.push(t);
    } else r = this.cards.slice();
    for (let t = 0; t < s.length; t++) {
      const e = s[t];
      let h = null;
      for (let t = 0; t < this.cards.length; t++)
        if (e.id === this.cards[t].id) {
          h = this.cards[t];
          break;
        }
      if (i) {
        const t = Z(e);
        null != h && h.viewed && t && (t.viewed = !0), null != t && r.push(t);
      } else if (null == h) {
        const t = Z(e);
        null != t && r.push(t);
      } else {
        if (!h.ni(e))
          for (let t = 0; t < r.length; t++)
            if (e.id === r[t].id) {
              r.splice(t, 1);
              break;
            }
      }
    }
    (this.cards = this.Ci(this.bi(r, i))),
      this.Ri(),
      (this.$s = e),
      (this.Ps = h),
      this.C && (this.C.js(t.bs.gi, this.$s), this.C.js(t.bs.yi, this.Ps));
  }
  U(s) {
    if (this.Si() && null != s && s.cards) {
      this.C && this.C.js(t.bs.Di, r.Ui());
      const i = s.full_sync;
      i || this.Xs(),
        this.ji(s.cards, i, s.last_full_sync_at, s.last_card_updated_at),
        this.Ms.X(this.ki(!0));
    }
  }
  Ai(s) {
    this.C && this.C.js(t.bs.Fi, s);
  }
  xi(s, i, e) {
    const h = () => {
        this.zi(i, e, !0);
      },
      r = s ? X(s) : null;
    let n;
    if ((this.qi(), !r || !r["retry-after"])) return void this.Ai(0);
    const o = r["retry-after"];
    if (isNaN(o) && !isNaN(Date.parse(o)))
      (n = Date.parse(o) - new Date().getTime()), n < 0 && h();
    else {
      if (isNaN(parseFloat(o.toString()))) {
        const t =
          "Received unexpected value for retry-after header in /sync response";
        return c.ds(d.Li, { e: t + ": " + o }), void this.Ai(0);
      }
      n = 1e3 * parseFloat(o.toString());
    }
    this.Ws = window.setTimeout(() => {
      h();
    }, n);
    let l = 0;
    this.C && (l = this.C.ps(t.bs.Fi)),
      (null == l || isNaN(parseInt(l.toString()))) && (l = 0),
      this.Ai(parseInt(l.toString()) + 1);
  }
  Qs(t) {
    var s;
    if (!this.Si()) return;
    this.Xs();
    const i = this.cards.slice();
    let e = null;
    e = null === (s = this.As) || void 0 === s ? void 0 : s.getUserId();
    for (let s = 0; s < t.length; s++)
      if (e === t[s].userId || (null == e && null == t[s].userId)) {
        const e = t[s].card;
        let h = null;
        for (let t = 0; t < this.cards.length; t++)
          if (e.id === this.cards[t].id) {
            h = this.cards[t];
            break;
          }
        if (null == h) {
          const t = Z(e);
          null != t && i.push(t);
        } else {
          if (!h.ni(e))
            for (let t = 0; t < i.length; t++)
              if (e.id === i[t].id) {
                i.splice(t, 1);
                break;
              }
        }
      }
    (this.cards = this.Ci(this.bi(i, !1))), this.Ri(), this.Ms.X(this.ki(!0));
  }
  bi(s, i) {
    let e = {},
      h = {},
      r = {};
    this.C &&
      ((e = this.C.ps(t.bs.ot) || {}),
      (h = this.C.ps(t.bs.vt) || {}),
      (r = this.C.ps(t.bs.gt) || {}));
    const n = {},
      o = {},
      l = {};
    for (let t = 0; t < s.length; t++) {
      const i = s[t].id;
      i &&
        (e[i] && ((s[t].clicked = !0), (n[i] = !0)),
        h[i] && ((s[t].viewed = !0), (o[i] = !0)),
        r[i] && ((s[t].dismissed = !0), (l[i] = !0)));
    }
    return (
      i &&
        this.C &&
        (this.C.js(t.bs.ot, n), this.C.js(t.bs.vt, o), this.C.js(t.bs.gt, l)),
      s
    );
  }
  Ci(s) {
    const i = [],
      e = new Date();
    let h = {};
    this.C && (h = this.C.ps(t.bs.gt) || {});
    let r = !1;
    for (let t = 0; t < s.length; t++) {
      const n = s[t].url;
      if (!this.Js && n && Y(n)) {
        N.error(
          `Card with url ${n} will not be displayed because Javascript URLs are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable this card.`,
        );
        continue;
      }
      const o = s[t].expiresAt;
      let l = !0;
      if ((null != o && (l = o >= e), (l = l && !s[t].dismissed), l))
        i.push(s[t]);
      else {
        const i = s[t].id;
        i && (h[i] = !0), (r = !0);
      }
    }
    return r && this.C && this.C.js(t.bs.gt, h), i;
  }
  Ri() {
    var s;
    const i = [];
    for (let t = 0; t < this.cards.length; t++) i.push(this.cards[t].Bs());
    null === (s = this.C) || void 0 === s || s.js(t.bs.wi, i);
  }
  qi() {
    this.Ws && (clearTimeout(this.Ws), (this.Ws = null));
  }
  Ji() {
    null != this.Ys && (clearTimeout(this.Ys), (this.Ys = null));
  }
  Mi(t = 1e3 * this.fi, s, i) {
    this.Ji(),
      (this.Ys = window.setTimeout(() => {
        this.zi(s, i, !0);
      }, t)),
      (this.Zs = t);
  }
  zi(s, i, e = !1, n = !0) {
    var o;
    const u = this.S,
      c = this.C;
    if (!u || !c) return void ("function" == typeof i && i());
    if ((!e && (this.qi(), this.Ai(0)), !this.Si()))
      return void (
        null === (o = this.B) ||
        void 0 === o ||
        o.$i(() => {
          this.zi(s, i);
        })
      );
    n && this.Ji();
    const f = u.H({}, !0);
    c.ps(t.bs.Di) !== r.Ui() && this.Bi(),
      (f.last_full_sync_at = this.$s),
      (f.last_card_updated_at = this.Ps);
    const d = u.J(f, h.O.Pi, e);
    let m = !1;
    u.V(
      f,
      (t = -1) => {
        if (this.C) {
          const t = new Date().valueOf();
          h.W(this.C, h.O.Pi, t);
        }
        -1 !== t && d.push(["X-Braze-Req-Tokens-Remaining", t.toString()]),
          l.Y({
            url: `${u.Z()}/content_cards/sync`,
            data: f,
            headers: d,
            ss: (t, e) => {
              if (!u.ts(f, t, d))
                return (m = !0), void ("function" == typeof i && i());
              u.es(),
                this.xi(e, s, i),
                this.U(t),
                (m = !1),
                h.ns(this.C, h.O.Pi, 1),
                "function" == typeof s && s();
            },
            error: (t) => {
              u.rs(t, "retrieving content cards"),
                (m = !0),
                "function" == typeof i && i();
            },
            os: () => {
              if (m && n && !this.Ys && this.di + 1 < st) {
                h.hs(this.C, h.O.Pi);
                let t = this.Zs;
                (null == t || t < 1e3 * this.fi) && (t = 1e3 * this.fi),
                  this.Mi(Math.min(3e5, a(1e3 * this.fi, 3 * t)), s, i),
                  (this.di = this.di + 1);
              }
            },
          });
      },
      h.O.Pi,
      i,
    );
  }
  ki(s) {
    s || this.Xs();
    const i = this.Ci(this.cards);
    i.sort((t, s) =>
      t.pinned && !s.pinned
        ? -1
        : s.pinned && !t.pinned
        ? 1
        : t.updated && s.updated && t.updated > s.updated
        ? -1
        : t.updated && s.updated && s.updated > t.updated
        ? 1
        : 0,
    );
    let e = Math.max(this.Ps || 0, this.$s || 0);
    return (
      0 === e && (e = void 0),
      this.C && this.C.ps(t.bs.yi) === this.Ps && void 0 === e && (e = this.Ps),
      new ContentCards(i, O(e))
    );
  }
  Fs(t) {
    return this.Ms.Ns(t);
  }
  Bi() {
    (this.$s = 0),
      (this.Ps = 0),
      this.C && (this.C.Ls(t.bs.gi), this.C.Ls(t.bs.yi));
  }
  changeUser(s) {
    s ||
      ((this.cards = []),
      this.Ms.X(new ContentCards(this.cards.slice(), null)),
      this.C &&
        (this.C.Ls(t.bs.wi),
        this.C.Ls(t.bs.ot),
        this.C.Ls(t.bs.vt),
        this.C.Ls(t.bs.gt))),
      this.Bi();
  }
  clearData(s) {
    (this.$s = 0),
      (this.Ps = 0),
      (this.cards = []),
      this.Ms.X(new ContentCards(this.cards.slice(), null)),
      s &&
        this.C &&
        (this.C.Ls(t.bs.wi),
        this.C.Ls(t.bs.ot),
        this.C.Ls(t.bs.vt),
        this.C.Ls(t.bs.gt),
        this.C.Ls(t.bs.gi),
        this.C.Ls(t.bs.yi));
  }
  Si() {
    return !!this.B && (!!this.B.Xi() || (0 !== this.B.Es() && this._i(), !1));
  }
  _i() {
    this.Ms.X(new ContentCards([], new Date())), this.C && this.C.Ls(t.bs.wi);
  }
}
