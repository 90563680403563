import r, { OPTIONS as z } from "../managers/braze-instance.js";
import na from "./push-manager.js";
const ra = {
  l: !1,
  aa: null,
  ea: () => (
    ra.p(),
    ra.aa ||
      (ra.aa = new na(
        r.cr(),
        r.Ma(),
        r.te(),
        r.Z(),
        r.sr(z._a),
        r.sr(z.Aa),
        r.sr(z.Ba),
        r.v(),
        r.sr(z.Ca),
        r.sr(z.Da),
        r.j(),
      )),
    ra.aa
  ),
  p: () => {
    ra.l || (r.h(ra), (ra.l = !0));
  },
  destroy: () => {
    (ra.aa = null), (ra.l = !1);
  },
};
export default ra;
