import { ControlCard } from "./models/index.js";
import c from "../common/event-logger.js";
import E from "../models/request-result.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
export default class M {
  constructor(t) {
    (this.C = t), (this.C = t);
  }
  logClick(s, n) {
    const r = new E();
    if ((s.rt(), null == s.url || "" === s.url))
      return (
        N.info(`Card ${s.id} has no url. Not logging click to Braze servers.`),
        r
      );
    if (n && s.id && this.C) {
      const n = this.C.ps(t.bs.ot) || {};
      (n[s.id] = !0), this.C.js(t.bs.ot, n);
    }
    const i = this.et([s]);
    if (null == i) return r;
    const o = n ? d.lt : d.ut;
    return c.ds(o, i);
  }
  ct(s) {
    const n = new E();
    if (!s.ft())
      return (
        N.info(
          `Card ${s.id} refused this dismissal. Ignoring analytics event.`,
        ),
        n
      );
    if (s.id && this.C) {
      const n = this.C.ps(t.bs.gt) || {};
      (n[s.id] = !0), this.C.js(t.bs.gt, n);
    }
    const r = this.et([s]);
    return null == r ? n : c.ds(d.dt, r);
  }
  jt(s, n) {
    const r = new E(!0),
      i = [],
      o = [];
    let e = {};
    this.C && (e = n ? this.C.ps(t.bs.vt) || {} : this.C.ps(t.bs.Ct) || {});
    for (const t of s) {
      t.wt()
        ? (t instanceof ControlCard ? o.push(t) : i.push(t),
          t.id && (e[t.id] = !0))
        : N.info(
            `Card ${t.id} logged an impression too recently. Ignoring analytics event.`,
          );
    }
    const l = this.et(i),
      a = this.et(o);
    if (null == l && null == a) return (r.ss = !1), r;
    if (
      (this.C && (n ? this.C.js(t.bs.vt, e) : this.C.js(t.bs.Ct, e)), null != l)
    ) {
      const t = n ? d.xt : d.yt,
        s = c.ds(t, l);
      r.It(s);
    }
    if (null != a && n) {
      const t = c.ds(d.$t, a);
      r.It(t);
    }
    return r;
  }
  et(t) {
    let s,
      n = null;
    for (let r = 0; r < t.length; r++)
      (s = t[r].id),
        null != s &&
          "" !== s &&
          ((n = n || {}), (n.ids = n.ids || []), n.ids.push(s));
    return n;
  }
}
