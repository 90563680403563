import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import ve from "../models/braze-event.js";
import _t from "../models/identifier.js";
import { convertMsToSeconds as L } from "../util/date-utils.js";
import {
  logger as N,
  Guid as G,
  EventTypes as d,
} from "../../shared-lib/index.js";
export default class Mt {
  constructor(s, t, i, e) {
    (this.C = s),
      (this.As = t),
      (this.B = i),
      (this.Sl = e),
      (this.C = s),
      (this.As = t),
      (this.B = i),
      (this.xl = 1e3),
      (null == e || isNaN(e)) && (e = 1800),
      e < this.xl / 1e3 &&
        (N.info(
          "Specified session timeout of " +
            e +
            "s is too small, using the minimum session timeout of " +
            this.xl / 1e3 +
            "s instead.",
        ),
        (e = this.xl / 1e3)),
      (this.Sl = e);
  }
  Dl(s, t) {
    return new ve(this.As.getUserId(), d.Gl, s, t.eu, { d: L(s - t.Nl) });
  }
  Ss() {
    const s = this.C.tu(t.iu.zl);
    return null == s ? null : s.eu;
  }
  Hl() {
    const s = new Date().valueOf(),
      i = this.B.Bl();
    if (null == i) return !1;
    const e = this.C.ps(t.bs.Wl),
      n = null == e || s - e > 1e3 * i;
    return n && this.C.js(t.bs.Wl, s), n;
  }
  kl(s, t) {
    return null == t || null == t.ql || (!(s - t.Nl < this.xl) && t.ql < s);
  }
  qo() {
    const s = new Date().valueOf(),
      i = s + 1e3 * this.Sl,
      e = this.C.tu(t.iu.zl);
    if (this.kl(s, e)) {
      let n = "Generating session start event with time " + s;
      if (null != e) {
        let s = e.Al;
        s - e.Nl < this.xl && (s = e.Nl + this.xl),
          this.C.Il(this.Dl(s, e)),
          (n += " (old session ended " + s + ")");
      }
      (n += ". Will expire " + i.valueOf()), N.info(n);
      const o = new _t(G.Rt(), i);
      this.C.Il(new ve(this.As.getUserId(), d.Jl, s, o.eu)),
        this.C.uu(t.iu.zl, o);
      return null == this.C.ps(t.bs.Wl) && this.C.js(t.bs.Wl, s), o.eu;
    }
    if (null != e) return (e.Al = s), (e.ql = i), this.C.uu(t.iu.zl, e), e.eu;
  }
  Kl() {
    const s = this.C.tu(t.iu.zl);
    null != s &&
      (this.C.Ml(t.iu.zl), this.C.Il(this.Dl(new Date().valueOf(), s)));
  }
}
