import { logger as N } from "../../shared-lib/index.js";
import { getUser as ao } from "../Core/get-user.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../common/constants.js";
import r from "../managers/braze-instance.js";
import { keys as y } from "../util/code-utils.js";
export const buildBrazeBridge = (t, e, o) => {
  const n = { display: {}, web: {} },
    requestPushPermission = function () {
      return function () {
        const t = arguments;
        import("../Push/request-push-permission.js").then((e) => {
          r.nt()
            ? e.requestPushPermission(...Array.prototype.slice.call(t))
            : N.error(p);
        });
      };
    },
    s = {
      requestImmediateDataFlush: function () {
        const t = arguments;
        import("../Core/request-immediate-data-flush.js").then(
          ({ requestImmediateDataFlush: requestImmediateDataFlush }) => {
            r.nt()
              ? requestImmediateDataFlush(...Array.prototype.slice.call(t))
              : N.error(p);
          },
        );
      },
      logCustomEvent: function () {
        const t = arguments;
        import("../Core/log-custom-event.js").then(
          ({ logCustomEvent: logCustomEvent }) => {
            if (!r.nt()) return void N.error(p);
            logCustomEvent(...Array.prototype.slice.call(t));
          },
        );
      },
      logPurchase: function () {
        const t = arguments;
        import("../Core/log-purchase.js").then(
          ({ logPurchase: logPurchase }) => {
            if (!r.nt()) return void N.error(p);
            logPurchase(...Array.prototype.slice.call(t));
          },
        );
      },
      unregisterPush: function () {
        const t = arguments;
        import("../Push/unregister-push.js").then(
          ({ unregisterPush: unregisterPush }) => {
            r.nt()
              ? unregisterPush(...Array.prototype.slice.call(t))
              : N.error(p);
          },
        );
      },
      requestPushPermission: requestPushPermission(),
      changeUser: function () {
        const t = arguments;
        import("../Core/change-user.js").then(({ changeUser: changeUser }) => {
          if (!r.nt()) return void N.error(p);
          changeUser(...Array.prototype.slice.call(t));
        });
      },
    },
    i = function (t) {
      return function () {
        s[t](...Array.prototype.slice.call(arguments));
      };
    };
  for (const t of y(s)) n[t] = i(t);
  const u = [
      "setFirstName",
      "setLastName",
      "setEmail",
      "setGender",
      "setDateOfBirth",
      "setCountry",
      "setHomeCity",
      "setEmailNotificationSubscriptionType",
      "setLanguage",
      "addAlias",
      "setPushNotificationSubscriptionType",
      "setPhoneNumber",
      "setCustomUserAttribute",
      "addToCustomAttributeArray",
      "removeFromCustomAttributeArray",
      "incrementCustomUserAttribute",
      "setCustomLocationAttribute",
      "addToSubscriptionGroup",
      "removeFromSubscriptionGroup",
    ],
    c = function (t) {
      return function () {
        const e = ao();
        e && e[t](...Array.prototype.slice.call(arguments));
      };
    },
    a = {};
  for (let t = 0; t < u.length; t++) a[u[t]] = c(u[t]);
  n.getUser = function () {
    return a;
  };
  const m = { showFeed: e },
    f = function (e) {
      return function () {
        const r = arguments;
        "function" != typeof o
          ? m[e](...Array.prototype.slice.call(r))
          : o(t, function () {
              m[e](...Array.prototype.slice.call(r));
            });
      };
    },
    l = n.display;
  for (const t of y(m)) l[t] = f(t);
  const d = { registerAppboyPushMessages: requestPushPermission() },
    h = function (t) {
      return function () {
        d[t](...Array.prototype.slice.call(arguments));
      };
    },
    g = n.web;
  for (const t of y(d)) g[t] = h(t);
  return n;
};
export const applyNonceToDynamicallyCreatedTags = (t, e, r) => {
  const o = `([\\w]+)\\s*=\\s*document.createElement\\(['"]${r}['"]\\)`,
    n = t.match(new RegExp(o));
  if (n) {
    const r = `${n[1]}.setAttribute("nonce", "${e}")`;
    return `${t.slice(0, n.index + n[0].length)};${r};${t.slice(
      n.index + n[0].length,
    )}`;
  }
  return null;
};
export const attachHtmlToIframeWithNonce = (t, e, r) => {
  let o = null;
  if (null != r) {
    (o = document.createElement("html")), (o.innerHTML = e || "");
    const t = o.getElementsByTagName("style");
    for (let e = 0; e < t.length; e++) t[e].setAttribute("nonce", r);
    const n = o.getElementsByTagName("script");
    for (let t = 0; t < n.length; t++) {
      n[t].setAttribute("nonce", r),
        (n[t].innerHTML = n[t].innerHTML.replace(
          /<style>/g,
          `<style nonce='${r}'>`,
        ));
      const e = applyNonceToDynamicallyCreatedTags(n[t].innerHTML, r, "script");
      e && (n[t].innerHTML = e);
      const o = applyNonceToDynamicallyCreatedTags(n[t].innerHTML, r, "style");
      o && (n[t].innerHTML = o);
    }
  }
  t.srcdoc = o ? o.innerHTML : e || "";
};
