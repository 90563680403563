import ea from "./in-app-message-manager.js";
import r from "../managers/braze-instance.js";
const se = {
  aa: null,
  l: !1,
  ea: () => (
    se.p(), se.aa || (se.aa = new ea(r.g(), r.P(), r.j(), r.tr())), se.aa
  ),
  p: () => {
    se.l || (r.h(se), (se.l = !0));
  },
  destroy: () => {
    (se.aa = null), (se.l = !1);
  },
};
export default se;
