import { parseQueryStringKeyValues as ll } from "../../util/url-utils.js";
import { WindowUtils as no } from "../../util/window-utils.js";
import { logInAppMessageHtmlClick } from "../log-in-app-message-html-click.js";
import InAppMessage from "../models/in-app-message.js";
export const buildHtmlClickHandler = (l, t, i, o) => {
  const r = i.getAttribute("href"),
    e = i.onclick;
  return (n) => {
    if (null != e && "function" == typeof e && !1 === e.bind(i)(n)) return;
    let s = ll(r).abButtonId;
    if (
      ((null != s && "" !== s) || (s = i.getAttribute("id") || void 0),
      null != r && "" !== r && 0 !== r.indexOf("#"))
    ) {
      const e =
          "blank" ===
          (i.getAttribute("target") || "").toLowerCase().replace("_", ""),
        m = o || l.openTarget === InAppMessage.OpenTarget.BLANK || e,
        u = () => {
          logInAppMessageHtmlClick(l, s, r), no.openUri(r, m, n);
        };
      m ? u() : l.ll(t, u);
    } else logInAppMessageHtmlClick(l, s, r || void 0);
    return n.stopPropagation(), !1;
  };
};
