import r from "../managers/braze-instance.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
import ne, { STORAGE_KEYS as t } from "../managers/storage-manager.js";
export function disableSDK() {
  const e = r.nn();
  e && e.requestImmediateDataFlush();
  const n = new ne.ne(null, !0),
    s = "This-cookie-will-expire-in-" + n.se();
  n.store(t.ae, s);
  const a = it.Gs._s;
  new it(a, N).setItem(a.Os.ie, a.oe, !0),
    N.info("disableSDK was called"),
    r.destroy(!1),
    r.re(!0);
}
