import r from "../managers/braze-instance.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
export function openSession() {
  if (!r.rr()) return;
  const i = r.nn();
  if (!i) return;
  i.openSession();
  const t = it.Gs._s,
    o = new it(t, N);
  o.jr(t.Os.hr, (r, n) => {
    const e = n.lastClick,
      s = n.trackingString;
    N.info(`Firing push click trigger from ${s} push click at ${e}`);
    const c = i.br(e, s),
      g = function () {
        ot.m().ue(et.kr, [s], c);
      };
    i.vr(g, g), o.me(t.Os.hr, r);
  }),
    o.Hs(t.Os.$r, function (r) {
      i.wr(r);
    });
}
