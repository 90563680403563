import ve from "../models/braze-event.js";
import _t from "../models/identifier.js";
import {
  isArray as w,
  keys as y,
  validateValueIsFromEnum as ta,
  values as Rt,
} from "../util/code-utils.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import { User } from "../User/index.js";
import ro from "../util/browser-detector.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export const STORAGE_KEYS = {
  iu: {
    su: "ab.storage.userId",
    Qo: "ab.storage.deviceId",
    zl: "ab.storage.sessionId",
  },
  bs: {
    ec: "ab.test",
    tE: "ab.storage.events",
    eE: "ab.storage.attributes",
    sE: "ab.storage.attributes.anonymous_user",
    Xa: "ab.storage.device",
    qu: "ab.storage.sdk_metadata",
    Au: "ab.storage.session_id_for_cached_metadata",
    Kn: "ab.storage.pushToken",
    Be: "ab.storage.newsFeed",
    Ge: "ab.storage.lastNewsFeedRefresh",
    Ct: "ab.storage.cardImpressions",
    fl: "ab.storage.serverConfig",
    rE: "ab.storage.triggers",
    oE: "ab.storage.triggers.ts",
    Wl: "ab.storage.messagingSessionStart",
    wi: "ab.storage.cc",
    gi: "ab.storage.ccLastFullSync",
    yi: "ab.storage.ccLastCardUpdated",
    _u: "ab.storage.globalRateLimitCurrentTokenCount",
    Ju: "ab.storage.dynamicRateLimitCurrentTokenCount",
    ot: "ab.storage.ccClicks",
    vt: "ab.storage.ccImpressions",
    gt: "ab.storage.ccDismissals",
    nE: "ab.storage.lastDisplayedTriggerTimesById",
    aE: "ab.storage.lastDisplayedTriggerTime",
    iE: "ab.storage.triggerFireInstancesById",
    qh: "ab.storage.signature",
    Fi: "ab.storage.brazeSyncRetryCount",
    Di: "ab.storage.sdkVersion",
    Yi: "ab.storage.ff",
    we: "ab.storage.ffImpressions",
    ke: "ab.storage.ffLastRefreshAt",
    xe: "ab.storage.ff.sessionId",
    EE: "ab.storage.lastReqToEndpoint",
    lE: "ab.storage.requestAttempts",
    cn: "ab.storage.deferredIam",
    xu: "ab.storage.lastSdkReq",
    SE: "ab.storage.alias",
    gs: "ab.storage.banners",
    xs: "ab.storage.banners.impressions",
    ys: "ab.storage.banners.sessionId",
  },
  ae: "ab.optOut",
};
export default class ne {
  constructor(t, e) {
    (this._E = t), (this.uE = e), (this._E = t), (this.uE = e);
  }
  Ua(t) {
    const e = y(STORAGE_KEYS.iu),
      s = new ne.ne(t);
    for (const t of e) s.remove(STORAGE_KEYS.iu[t]);
  }
  uu(t, e) {
    let s = null;
    null != e && e instanceof _t && (s = e.Bs()), this._E.store(t, s);
  }
  cE(t) {
    const e = this.tu(t);
    null != e && ((e.Al = new Date().valueOf()), this.uu(t, e));
  }
  tu(t) {
    const e = this._E.gr(t),
      s = ((t) => {
        let e;
        try {
          e = JSON.parse(t);
        } catch (t) {
          e = null;
        }
        return e;
      })(e);
    let r;
    if (s) (r = _t.Qn(s) || null), r && this.uu(t, r);
    else {
      const s = _t.TE(e);
      (r = _t.Qn(s) || null), s !== e && r && this.uu(t, r);
    }
    return r;
  }
  Ml(t) {
    this._E.remove(t);
  }
  Wa() {
    const t = y(STORAGE_KEYS.iu);
    let e;
    for (const s of t)
      (e = this.tu(STORAGE_KEYS.iu[s])),
        null != e && this.uu(STORAGE_KEYS.iu[s], e);
  }
  Eo(t) {
    let e;
    if (null == t || 0 === t.length) return !1;
    e = w(t) ? t : [t];
    let s = this.uE.gr(STORAGE_KEYS.bs.tE);
    (null != s && w(s)) || (s = []);
    for (let t = 0; t < e.length; t++) s.push(e[t].Bs());
    return this.uE.store(STORAGE_KEYS.bs.tE, s);
  }
  Il(t) {
    return null != t && this.Eo([t]);
  }
  hE() {
    let t = this.uE.gr(STORAGE_KEYS.bs.tE);
    this.uE.remove(STORAGE_KEYS.bs.tE), null == t && (t = []);
    const e = [];
    let s = !1,
      r = null;
    if (w(t))
      for (let s = 0; s < t.length; s++)
        ve.AE(t[s]) ? e.push(ve.Qn(t[s])) : (r = s);
    else s = !0;
    if (s || null != r) {
      let o = "Stored events could not be deserialized as Events";
      s &&
        (o += ", was " + Object.prototype.toString.call(t) + " not an array"),
        null != r &&
          (o += ", value at index " + r + " does not look like an event"),
        (o +=
          ", serialized values were of type " +
          typeof t +
          ": " +
          JSON.stringify(t)),
        e.push(new ve(null, d.Li, new Date().valueOf(), null, { e: o }));
    }
    return e;
  }
  js(t, e) {
    return (
      !!ta(
        STORAGE_KEYS.bs,
        t,
        "StorageManager cannot store object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this.uE.store(t, e)
    );
  }
  ps(t) {
    return (
      !!ta(
        STORAGE_KEYS.bs,
        t,
        "StorageManager cannot retrieve object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this.uE.gr(t)
    );
  }
  Ls(t) {
    return (
      !!ta(
        STORAGE_KEYS.bs,
        t,
        "StorageManager cannot remove object.",
        "STORAGE_KEYS.OBJECTS",
      ) && (this.uE.remove(t), !0)
    );
  }
  clearData() {
    const t = y(STORAGE_KEYS.iu),
      e = y(STORAGE_KEYS.bs);
    for (let e = 0; e < t.length; e++) {
      const s = t[e];
      this._E.remove(STORAGE_KEYS.iu[s]);
    }
    for (let t = 0; t < e.length; t++) {
      const s = e[t];
      this.uE.remove(STORAGE_KEYS.bs[s]);
    }
  }
  RE(t) {
    return t || STORAGE_KEYS.bs.sE;
  }
  $u(t) {
    let e = this.uE.gr(STORAGE_KEYS.bs.eE);
    null == e && (e = {});
    const s = this.RE(t[User.Cu]),
      r = e[s];
    for (const o in t)
      o !== User.Cu &&
        (null == e[s] || (r && null == r[o])) &&
        this.mu(t[User.Cu], o, t[o]);
  }
  mu(t, e, s) {
    let r = this.uE.gr(STORAGE_KEYS.bs.eE);
    null == r && (r = {});
    const o = this.RE(t);
    let n = r[o];
    if (
      (null == n && ((n = {}), null != t && (n[User.Cu] = t)), e === User.lu)
    ) {
      null == n[e] && (n[e] = {});
      for (const t in s) n[e][t] = s[t];
    } else n[e] = s;
    return (r[o] = n), this.uE.store(STORAGE_KEYS.bs.eE, r);
  }
  gE() {
    const t = this.uE.gr(STORAGE_KEYS.bs.eE);
    this.uE.remove(STORAGE_KEYS.bs.eE);
    const e = [];
    for (const s in t) null != t[s] && e.push(t[s]);
    return e;
  }
  ru(t) {
    const e = this.uE.gr(STORAGE_KEYS.bs.eE);
    if (null != e) {
      const s = this.RE(null),
        r = e[s];
      null != r &&
        ((e[s] = void 0),
        this.uE.store(STORAGE_KEYS.bs.eE, e),
        (r[User.Cu] = t),
        this.$u(r));
    }
    const s = this.tu(STORAGE_KEYS.iu.zl);
    let r = null;
    null != s && (r = s.eu);
    const o = this.hE();
    if (null != o)
      for (let e = 0; e < o.length; e++) {
        const s = o[e];
        null == s.userId && s.sessionId == r && (s.userId = t), this.Il(s);
      }
  }
  OE() {
    return this.uE.IE;
  }
}
(ne.rc = class {
  constructor(t) {
    (this.yn = t), (this.yn = t), (this.IE = ro.dE() ? 3 : 10);
  }
  fE(t) {
    return t + "." + this.yn;
  }
  store(t, e) {
    const s = { v: e };
    try {
      return localStorage.setItem(this.fE(t), JSON.stringify(s)), !0;
    } catch (t) {
      return N.info("Storage failure: " + si(t)), !1;
    }
  }
  gr(t) {
    try {
      let e = null;
      const s = localStorage.getItem(this.fE(t));
      return null != s && (e = JSON.parse(s)), null == e ? null : e.v;
    } catch (t) {
      return N.info("Storage retrieval failure: " + si(t)), null;
    }
  }
  remove(t) {
    try {
      localStorage.removeItem(this.fE(t));
    } catch (t) {
      return N.info("Storage removal failure: " + si(t)), !1;
    }
  }
}),
  (ne.ac = class {
    constructor() {
      (this.bE = {}), (this.KE = 5242880), (this.IE = 3);
    }
    store(t, e) {
      const s = { value: e },
        r = this.mE(e);
      return r > this.KE
        ? (N.info(
            "Storage failure: object is ≈" +
              r +
              " bytes which is greater than the max of " +
              this.KE,
          ),
          !1)
        : ((this.bE[t] = s), !0);
    }
    mE(t) {
      const e = [],
        s = [t];
      let r = 0;
      for (; s.length; ) {
        const t = s.pop();
        if ("boolean" == typeof t) r += 4;
        else if ("string" == typeof t) r += 2 * t.length;
        else if ("number" == typeof t) r += 8;
        else if ("object" == typeof t && -1 === e.indexOf(t)) {
          let r, o;
          e.push(t);
          for (const e in t) (o = t), (r = e), s.push(o[r]);
        }
      }
      return r;
    }
    gr(t) {
      const e = this.bE[t];
      return null == e ? null : e.value;
    }
    remove(t) {
      this.bE[t] = null;
    }
  }),
  (ne.ne = class {
    constructor(t, e) {
      (this.yn = t),
        (this.YE = e),
        (this.yn = t),
        (this.NE = this.GE()),
        (this.DE = 576e3),
        (this.YE = !!e);
    }
    fE(t) {
      return null != this.yn ? t + "." + this.yn : t;
    }
    GE() {
      let t = 0,
        e = document.location.hostname;
      const s = e.split("."),
        r = "ab._gd";
      for (; t < s.length - 1 && -1 === document.cookie.indexOf(r + "=" + r); )
        t++,
          (e = "." + s.slice(-1 - t).join(".")),
          (document.cookie = r + "=" + r + ";domain=" + e + ";");
      return (
        (document.cookie =
          r + "=;expires=" + new Date(0).toUTCString() + ";domain=" + e + ";"),
        e
      );
    }
    se() {
      const t = new Date();
      return t.setTime(t.getTime() + 60 * this.DE * 1e3), t.getFullYear();
    }
    CE() {
      const t = Rt(STORAGE_KEYS.iu),
        e = document.cookie.split(";");
      for (let s = 0; s < e.length; s++) {
        let r = e[s];
        for (; " " === r.charAt(0); ) r = r.substring(1);
        let o = !1;
        for (let e = 0; e < t.length; e++)
          if (0 === r.indexOf(t[e])) {
            o = !0;
            break;
          }
        if (o) {
          const t = r.split("=")[0];
          -1 === t.indexOf("." + this.yn) && this.ME(t);
        }
      }
    }
    store(t, e) {
      this.CE();
      const s = new Date();
      s.setTime(s.getTime() + 60 * this.DE * 1e3);
      const r = "expires=" + s.toUTCString(),
        o = "domain=" + this.NE;
      let n;
      n = this.YE ? e : encodeURIComponent(e);
      const a = this.fE(t) + "=" + n + ";" + r + ";" + o + ";path=/";
      return a.length >= 4093
        ? (N.info(
            "Storage failure: string is " +
              a.length +
              " chars which is too large to store as a cookie.",
          ),
          !1)
        : ((document.cookie = a), !0);
    }
    gr(t) {
      const e = [],
        s = this.fE(t) + "=",
        r = document.cookie.split(";");
      for (let o = 0; o < r.length; o++) {
        let n = r[o];
        for (; " " === n.charAt(0); ) n = n.substring(1);
        if (0 === n.indexOf(s))
          try {
            let t;
            (t = this.YE
              ? n.substring(s.length, n.length)
              : decodeURIComponent(n.substring(s.length, n.length))),
              e.push(t);
          } catch (e) {
            return (
              N.info("Storage retrieval failure: " + si(e)),
              this.remove(t),
              null
            );
          }
      }
      return e.length > 0 ? e[e.length - 1] : null;
    }
    remove(t) {
      this.ME(this.fE(t));
    }
    ME(t) {
      const e = t + "=;expires=" + new Date(0).toUTCString();
      (document.cookie = e), (document.cookie = e + ";path=/");
      const s = e + ";domain=" + this.NE;
      (document.cookie = s), (document.cookie = s + ";path=/");
    }
  }),
  (ne.tc = class {
    constructor(t, e, s) {
      (this.yn = t),
        (this.pE = []),
        e && this.pE.push(new ne.ne(t)),
        s && this.pE.push(new ne.rc(t)),
        this.pE.push(new ne.ac());
    }
    store(t, e) {
      let s = !0;
      for (let r = 0; r < this.pE.length; r++) s = this.pE[r].store(t, e) && s;
      return s;
    }
    gr(t) {
      for (let e = 0; e < this.pE.length; e++) {
        const s = this.pE[e].gr(t);
        if (null != s) return s;
      }
      return null;
    }
    remove(t) {
      new ne.ne(this.yn).remove(t);
      for (let e = 0; e < this.pE.length; e++) this.pE[e].remove(t);
    }
  });
