import { logger as N } from "../../shared-lib/index.js";
import { ControlCard } from "../Card/index.js";
export const FEED_ANIMATION_DURATION = 500;
export default class rr {
  constructor(s, e) {
    (this.cards = s),
      (this.lastUpdated = e),
      (this.cards = s),
      (this.lastUpdated = e);
  }
  getUnreadCardCount() {
    let s = 0;
    for (const e of this.cards) e.viewed || e instanceof ControlCard || s++;
    return s;
  }
  ur() {
    N.error("Must be implemented in a subclass");
  }
  logCardImpressions(s) {
    N.error("Must be implemented in a subclass");
  }
  logCardClick(s) {
    N.error("Must be implemented in a subclass");
  }
  dr() {
    N.error("Must be implemented in a subclass");
  }
}
(rr.ar = 6e4), (rr.Ih = 500), (rr.No = 1e4);
