import {
  Card,
  CaptionedImage,
  ClassicCard,
  ControlCard,
  ImageOnly,
} from "../index.js";
import {
  dateFromUnixTimestamp as O,
  rehydrateDateAfterJsonization as V,
} from "../../util/date-utils.js";
import { logger as N } from "../../../shared-lib/index.js";
export function newCard(n, e, r, t, o, i, l, u, d, a, w, f, s, m, C, p, c, x) {
  let F;
  if (e === Card.Dt.ui || e === Card.Dt.Ei)
    F = new ClassicCard(n, r, t, o, i, l, u, d, a, w, f, s, m, C, p, c);
  else if (e === Card.Dt.Bt)
    F = new CaptionedImage(n, r, t, o, i, l, u, d, a, w, f, s, m, C, p, c);
  else if (e === Card.Dt.oi)
    F = new ImageOnly(n, r, o, l, u, d, a, w, f, s, m, C, p, c);
  else {
    if (e !== Card.Dt.Ti)
      return N.error("Ignoring card with unknown type " + e), null;
    F = new ControlCard(n, r, u, a, m, C);
  }
  return x && (F.test = x), F;
}
export function newCardFromContentCardsJson(n) {
  if (n[Card.li.ei]) return null;
  const e = n[Card.li.Et],
    r = n[Card.li.zt],
    t = n[Card.li.Ft],
    o = n[Card.li.Gt],
    i = n[Card.li.Jt],
    l = n[Card.li.Kt],
    u = O(n[Card.li.Lt]),
    d = u;
  let a;
  a = n[Card.li.Ot] === Card.ri ? null : O(n[Card.li.Ot]);
  return newCard(
    e,
    r,
    t,
    o,
    i,
    l,
    d,
    u,
    null,
    a,
    n[Card.li.URL],
    n[Card.li.Pt],
    n[Card.li.Qt],
    n[Card.li.St],
    n[Card.li.Ut],
    n[Card.li.Vt],
    n[Card.li.Wt],
    n[Card.li.Xt] || !1,
  );
}
export function newCardFromFeedJson(n) {
  return newCard(
    n.id,
    n.type,
    n.viewed,
    n.title,
    n.image,
    n.description,
    O(n.created),
    O(n.updated),
    n.categories,
    O(n.expires_at),
    n.url,
    n.domain,
    n.aspect_ratio,
    n.extras,
    !1,
    !1,
  );
}
export function newCardFromSerializedValue(n) {
  return (
    newCard(
      n[Card.qt.Et],
      n[Card.qt.zt],
      n[Card.qt.Ft],
      n[Card.qt.Gt],
      n[Card.qt.Jt],
      n[Card.qt.Kt],
      V(n[Card.qt.Mt]),
      V(n[Card.qt.Lt]),
      n[Card.qt.Nt],
      V(n[Card.qt.Ot]),
      n[Card.qt.URL],
      n[Card.qt.Pt],
      n[Card.qt.Qt],
      n[Card.qt.St],
      n[Card.qt.Ut],
      n[Card.qt.Vt],
      n[Card.qt.Wt],
      n[Card.qt.Xt] || !1,
    ) || void 0
  );
}
