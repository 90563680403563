import r from "../managers/braze-instance.js";
import tr from "./feature-flags-provider.js";
const nr = {
  l: !1,
  provider: null,
  m: () => (
    nr.p(),
    nr.provider ||
      ((nr.provider = new tr(r.v(), r.g(), r.j(), r.P())), r._(nr.provider)),
    nr.provider
  ),
  p: () => {
    nr.l || (r.h(nr), (nr.l = !0));
  },
  destroy: () => {
    (nr.provider = null), (nr.l = !1);
  },
};
export default nr;
