import Banner from "./banner.js";
export function newBannerFromSerializedValue(n) {
  return new Banner(n.id, n.o, n.html, n.t, n.i, n.u);
}
export function newBannerFromJson(n) {
  return new Banner(
    n.id,
    n.placement_id,
    n.html,
    n.is_test_send,
    n.is_control,
    n.expires_at,
  );
}
