import {
  attachHtmlToIframeWithNonce as m,
  buildBrazeBridge as b,
} from "../../util/html-display-utils.js";
import r from "../../managers/braze-instance.js";
import N from "../../../shared-lib/logger.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../../common/constants.js";
import { logBannerClick } from "../log-banner-click.js";
export const BANNER_PLACEMENT_ID = "data-ab-banner-placement-id";
export const BANNER_HTML_CLASS = "ab-html-banner";
export const CONTROL_BANNER_HTML_CLASS = "ab-html-control-banner";
export function controlBannerToHtml(t) {
  const n = document.createElement("div");
  return (
    (n.id = t.id),
    (n.className = "ab-html-control-banner"),
    n.setAttribute(BANNER_PLACEMENT_ID, t.placementId),
    n
  );
}
export function bannerToHtml(t, n) {
  if (t.ht()) return controlBannerToHtml(t);
  const o = document.createElement("iframe");
  return (
    (o.id = t.id),
    n && o.setAttribute("nonce", n),
    (o.className = "ab-html-banner"),
    o.setAttribute(BANNER_PLACEMENT_ID, t.placementId),
    o.setAttribute("title", "Banner"),
    m(o, t.html, n),
    (o.onload = () => {
      const n = o.contentWindow,
        e = n.document.getElementsByTagName("title");
      e && e.length > 0 && o.setAttribute("title", e[0].textContent || "");
      const a = Object.assign(
        Object.assign(
          {},
          b(o, () => {
            import("../../Feed/ui/show-feed.js").then((t) => {
              r.nt() ? t.showFeed() : N.error(p);
            });
          }),
        ),
        {
          logClick: function () {
            logBannerClick(t, ...arguments);
          },
          closeMessage: function () {},
        },
      );
      (n.brazeBridge = a),
        (n.appboyBridge = a),
        n.dispatchEvent(new CustomEvent("ab.BridgeReady"));
    }),
    o
  );
}
