import { logger as N } from "../../shared-lib/index.js";
import FeatureFlag from "./feature-flag.js";
export function newFeatureFlagFromJson(e) {
  if (e[FeatureFlag.li.Et] && "boolean" == typeof e[FeatureFlag.li.le])
    return new FeatureFlag(
      e[FeatureFlag.li.Et],
      e[FeatureFlag.li.le],
      e[FeatureFlag.li.pe],
      e[FeatureFlag.li.Fe],
    );
  N.info(`Unable to create feature flag from ${JSON.stringify(e, null, 2)}`);
}
export function newFeatureFlagFromSerializedValue(e) {
  if (e[FeatureFlag.qt.Et] && "boolean" == typeof e[FeatureFlag.qt.le])
    return new FeatureFlag(
      e[FeatureFlag.qt.Et],
      e[FeatureFlag.qt.le],
      e[FeatureFlag.qt.pe],
      e[FeatureFlag.qt.Fe],
    );
  N.info(
    `Unable to deserialize feature flag from ${JSON.stringify(e, null, 2)}`,
  );
}
