import { logger as N } from "../../shared-lib/index.js";
import r from "../managers/braze-instance.js";
import nr from "./feature-flags-provider-factory.js";
export function getAllFeatureFlags() {
  if (!r.rr()) return;
  const e = [],
    t = r.v();
  if (t && !t.Qi()) return N.info("Feature flags are not enabled."), e;
  const n = nr.m().Wi();
  for (const r in n) e.push(n[r]);
  return e;
}
