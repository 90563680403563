import { logger as N } from "../../shared-lib/index.js";
import r from "../managers/braze-instance.js";
import i from "./banner-provider-factory.js";
import { getBannerIfNotExpired as v } from "./get-banner.js";
export function getAllBanners() {
  if (!r.rr()) return;
  const n = {},
    e = r.v();
  if (e && !e.Us()) return N.info("Banners are not enabled."), n;
  const o = i.m().vs();
  for (const r in o) n[r] = v(o, r);
  return n;
}
