import r from "../managers/braze-instance.js";
import { getByteLength as er } from "../util/string-utils.js";
import { logger as N } from "../../shared-lib/index.js";
import { User } from "../User/index.js";
import { validateStandardString as ir } from "../util/validation-utils.js";
export function changeUser(e, i) {
  if (!r.rr()) return;
  if (null == e || 0 === e.length || e != e)
    return void N.error("changeUser requires a non-empty userId.");
  if (er(e) > User.mr)
    return void N.error(
      `Rejected user id "${e}" because it is longer than ${User.mr} bytes.`,
    );
  if (null != i && !ir(i, "set signature for new user", "signature")) return;
  const t = r.nn();
  t && t.changeUser(e.toString(), r.lr(), i);
}
