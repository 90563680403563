import r, { OPTIONS as z } from "../../managers/braze-instance.js";
import {
  destroyFeedHtml as ee,
  detectFeedImpressions as nn,
  feedToHtml as tn,
  LAST_REQUESTED_REFRESH_DATA_ATTRIBUTE as en,
  refreshFeed as on,
  registerFeedSubscriptionId as rn,
  updateFeedCards as an,
} from "../../common/feed-display.js";
import { Feed, logFeedDisplayed, subscribeToFeedUpdates } from "../index.js";
import ie from "../feed-provider-factory.js";
import { intersection as te } from "../../util/code-utils.js";
import { setCardHeight as sn } from "../../Card/display/card-display.js";
import { setupFeedUI as fn } from "../../ui/js/index.js";
import { logger as N } from "../../../shared-lib/index.js";
export function showFeed(e, t, n) {
  if (!r.rr()) return;
  fn();
  const o = (e, t) => {
      if (null == t) return e;
      const n = [];
      for (let e = 0; e < t.length; e++) n.push(t[e].toLowerCase());
      const o = [];
      for (let t = 0; t < e.length; t++) {
        const r = [],
          i = e[t].categories || [];
        for (let e = 0; e < i.length; e++) r.push(i[e].toLowerCase());
        te(r, n).length > 0 && o.push(e[t]);
      }
      return o;
    },
    i = r.sr(z.tn) || r.sr(z.en) || !1;
  let s = !1;
  null == e && ((e = document.body), (s = !0));
  let l,
    f = !1;
  null == t
    ? ((l = ie.m().Je()),
      an(l, o(l.cards, n), l.lastUpdated, null, i),
      (f = !0))
    : (l = new Feed(o(t, n), new Date()));
  const a = tn(l, i, s);
  if (f) {
    (null == l.lastUpdated ||
      new Date().valueOf() - l.lastUpdated.valueOf() > Feed.ar) &&
      (N.info(
        `Cached feed was older than max TTL of ${Feed.ar} ms, requesting an update from the server.`,
      ),
      on(l, a));
    const e = new Date().valueOf(),
      t = subscribeToFeedUpdates(function (t) {
        const r = a.querySelectorAll(".ab-refresh-button")[0];
        if (null != r) {
          let t = 500;
          t -= new Date().valueOf() - e;
          const n = a.getAttribute(en);
          if (n) {
            const e = parseInt(n);
            isNaN(e) || (t -= new Date().valueOf() - e);
          }
          setTimeout(
            function () {
              r.className = r.className.replace(/fa-spin/g, "");
            },
            Math.max(t, 0),
          );
        }
        an(l, o(t.cards, n), t.lastUpdated, a, i);
      });
    rn(t, a);
  }
  const u = (e) => {
    const t = e.querySelectorAll(".ab-feed");
    let n = null;
    for (let o = 0; o < t.length; o++) t[o].parentNode === e && (n = t[o]);
    null != n
      ? (ee(n), n.parentNode && n.parentNode.replaceChild(a, n))
      : e.appendChild(a),
      setTimeout(function () {
        a.className = a.className.replace("ab-hide", "ab-show");
      }, 0),
      s && a.focus(),
      logFeedDisplayed(),
      nn(l, a),
      l && sn(l.cards, e);
  };
  var d;
  null != e
    ? u(e)
    : (window.onload =
        ((d = window.onload),
        function () {
          "function" == typeof d && d(new Event("oldLoad")), u(document.body);
        }));
}
