import r from "../managers/braze-instance.js";
import re from "./feed-provider.js";
const ie = {
  l: !1,
  provider: null,
  m: () => (
    ie.p(),
    ie.provider || ((ie.provider = new re(r.j(), r.nn())), r._(ie.provider)),
    ie.provider
  ),
  p: () => {
    ie.l || (r.h(ie), (ie.l = !0));
  },
  destroy: () => {
    (ie.provider = null), (ie.l = !1);
  },
};
export default ie;
