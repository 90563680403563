import r from "../managers/braze-instance.js";
import e from "./banner-provider.js";
const i = {
  l: !1,
  provider: null,
  m: () => (
    i.p(),
    i.provider ||
      ((i.provider = new e(r.v(), r.g(), r.j(), r.P())), r._(i.provider)),
    i.provider
  ),
  p: () => {
    i.l || (r.h(i), (i.l = !0));
  },
  destroy: () => {
    (i.provider = null), (i.l = !1);
  },
};
export default i;
