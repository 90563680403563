import r, { OPTIONS as z } from "../../managers/braze-instance.js";
import ControlMessage from "../models/control-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import me from "../display/get-animation-effect.js";
import de from "../display/in-app-message-to-html.js";
import { logInAppMessageImpression } from "../log-in-app-message-impression.js";
import {
  ORIENTATION as fe,
  WindowUtils as no,
} from "../../util/window-utils.js";
import { isURIJavascriptOrData as Y } from "../../util/url-utils.js";
import { KeyCodes as bt } from "../../util/key-codes.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import { logger as N } from "../../../shared-lib/index.js";
import { toRgba as le } from "../../util/color-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../../common/constants.js";
import { isIFrame as It } from "../utils/in-app-message-utils.js";
import ge from "../../l10n/l10n-manager-factory.js";
export function showInAppMessage(e, t, o) {
  if (!r.rr()) return;
  if ((ss(), null == e)) return !1;
  if (e instanceof ControlMessage)
    return (
      N.info(
        "User received control for a multivariate test, logging to Braze servers.",
      ),
      logInAppMessageImpression(e),
      !0
    );
  if (!(e instanceof InAppMessage)) return !1;
  if (e.constructor === InAppMessage) return !1;
  e.Lh();
  const s = e instanceof HtmlMessage;
  if (s && !e.trusted && !r.nr())
    return (
      N.error(
        'HTML in-app messages are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these messages.',
      ),
      !1
    );
  if ((null == t && (t = document.body), e.Ye())) {
    if (t.querySelectorAll(".ab-modal-interactions").length > 0)
      return (
        N.info(
          `Cannot show in-app message ${e.message} because another message is being shown.`,
        ),
        !1
      );
  }
  if (no.Uo()) {
    const t = no.$o();
    if (
      (t === fe.PORTRAIT &&
        e.orientation === InAppMessage.Orientation.LANDSCAPE) ||
      (t === fe.LANDSCAPE &&
        e.orientation === InAppMessage.Orientation.PORTRAIT)
    ) {
      const o = t === fe.PORTRAIT ? "portrait" : "landscape",
        s =
          e.orientation === InAppMessage.Orientation.PORTRAIT
            ? "portrait"
            : "landscape";
      return (
        N.info(
          `Not showing ${s} in-app message ${e.message} because the screen is currently ${o}`,
        ),
        !1
      );
    }
  }
  if (!r.nr()) {
    let t = !1;
    if (e.buttons && e.buttons.length > 0) {
      const o = e.buttons;
      for (let e = 0; e < o.length; e++)
        if (o[e].clickAction === InAppMessage.ClickAction.URI) {
          const s = o[e].uri;
          t = Y(s);
        }
    } else e.clickAction === InAppMessage.ClickAction.URI && (t = Y(e.uri));
    if (t)
      return (
        N.error(
          'Javascript click actions are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these actions.',
        ),
        !1
      );
  }
  const i = document.createElement("div");
  if (
    ((i.className = "ab-iam-root v3"),
    (i.className += me(e)),
    i.setAttribute("role", "complementary"),
    e.Ve() && (i.id = e.htmlId),
    r.sr(z.Jo) && (i.style.zIndex = (r.sr(z.Jo) + 1).toString()),
    t.appendChild(i),
    e.Oe())
  ) {
    const t = document.createElement("style");
    (t.innerHTML = e.css),
      (t.id = e._e()),
      null != r.sr(z.ir) && t.setAttribute("nonce", r.sr(z.ir)),
      document.getElementsByTagName("head")[0].appendChild(t);
  }
  const n = e instanceof SlideUpMessage,
    a = de(
      e,
      () => {
        import("../../Feed/ui/show-feed.js").then((e) => {
          r.nt() ? e.showFeed() : N.error(p);
        });
      },
      (t) => {
        if (e.Ye() && e.Bo()) {
          const o = document.createElement("div");
          if (
            ((o.className = "ab-page-blocker"),
            e.Oe() || (o.style.backgroundColor = le(e.frameColor)),
            r.sr(z.Jo) && (o.style.zIndex = r.sr(z.Jo).toString()),
            i.appendChild(o),
            !r.sr(z.mh))
          ) {
            const s = new Date().valueOf();
            o.onclick = (o) => {
              new Date().valueOf() - s > InAppMessage.ph &&
                (e.ll(t), o.stopPropagation());
            };
          }
          i.appendChild(t), t.focus(), e.Ah(i);
        } else if (n) {
          const o = document.querySelectorAll(".ab-slideup");
          let s = null;
          for (let e = o.length - 1; e >= 0; e--)
            if (o[e] !== t) {
              s = o[e];
              break;
            }
          if (e.slideFrom === InAppMessage.SlideFrom.TOP) {
            let e = 0;
            null != s && (e = s.offsetTop + s.offsetHeight),
              (t.style.top = Math.max(e, 0) + "px");
          } else {
            let e = 0;
            null != s &&
              (e =
                (window.innerHeight || document.documentElement.clientHeight) -
                s.offsetTop),
              (t.style.bottom = Math.max(e, 0) + "px");
          }
        } else if (s && !r.sr(z.mh)) {
          const o = e;
          It(t) &&
            t.contentWindow &&
            t.contentWindow.addEventListener("keydown", function (e) {
              e.keyCode === bt._h && o.closeMessage();
            });
        }
        logInAppMessageImpression(e),
          e.dismissType === InAppMessage.DismissType.AUTO_DISMISS &&
            setTimeout(() => {
              i.contains(t) && e.ll(t);
            }, e.duration),
          "function" == typeof o && o();
      },
      (e) => {
        N.info(e);
      },
      r.sr(z.Do),
      r.sr(z.Jo),
      r.sr(z.ir),
      t,
      ge.ea().So(),
    );
  return (s || n) && (i.appendChild(a), e.Ah(i)), !0;
}
