import r from "../managers/braze-instance.js";
import i from "./banner-provider-factory.js";
import { getAllBanners } from "./get-all-banners.js";
export function subscribeToBannersUpdates(n) {
  if (!r.rr()) return;
  const o = i.m();
  if (o.qs()) {
    const r = getAllBanners();
    r && "function" == typeof n && n(r);
  }
  const t = o.Fs(n);
  if (!o.Ts()) {
    const n = r.nn();
    if (n) {
      const r = n.rn(() => {
        const n = o.us();
        n && n.length > 0 && o.$(n);
      });
      r && o.Rs(r);
    }
  }
  return t;
}
