import r from "../managers/braze-instance.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as sr } from "../common/constants.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import se from "./in-app-message-manager-factory.js";
import { logger as N } from "../../shared-lib/index.js";
import et from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as ot } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageButtonClick(o, t) {
  var e;
  if (!r.rr()) return !1;
  if (!(o instanceof InAppMessageButton))
    return N.error("button must be an InAppMessageButton object"), !1;
  if (!(t instanceof InAppMessage)) return N.error(sr), !1;
  const s = se.ea().Hr(o, t);
  if (s.ss)
    for (let r = 0; r < s.fe.length; r++)
      ot.m().ue(
        et.Ao,
        [
          t.triggerId,
          null === (e = o.id) || void 0 === e ? void 0 : e.toString(),
        ],
        s.fe[r],
      );
  return s.ss;
}
