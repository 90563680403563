import r from "../managers/braze-instance.js";
import se from "./in-app-message-manager-factory.js";
export function subscribeToInAppMessage(n) {
  if (r.rr())
    return "function" != typeof n
      ? null
      : se.ea().qr(function (r) {
          return n(r[0]), r.slice(1);
        });
}
