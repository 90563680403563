import r from "../managers/braze-instance.js";
import nr from "./feature-flags-provider-factory.js";
import { getAllFeatureFlags } from "./get-all-feature-flags.js";
export function subscribeToFeatureFlagsUpdates(t) {
  if (!r.rr()) return;
  const e = nr.m();
  if (e.$e()) {
    const r = getAllFeatureFlags();
    r && "function" == typeof t && t(r);
  }
  return e.Fs(t);
}
