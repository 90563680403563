import r, { OPTIONS as z } from "../../managers/braze-instance.js";
import ro from "../../util/browser-detector.js";
import {
  clickElement as Et,
  supportsPassive as Tt,
} from "../../util/dom-utils.js";
import { KeyCodes as bt } from "../../util/key-codes.js";
import u from "../../managers/subscription-manager.js";
import { isIFrame as It } from "../utils/in-app-message-utils.js";
export default class InAppMessage {
  constructor(
    t,
    s,
    i,
    h,
    e,
    E,
    n,
    T,
    o,
    r,
    l,
    a,
    I,
    c,
    A,
    O,
    _,
    m,
    L,
    N,
    S,
    R,
    M,
    D,
    C,
    d,
    U,
    b,
    P,
    p,
  ) {
    (this.message = t),
      (this.messageAlignment = s),
      (this.slideFrom = i),
      (this.extras = h),
      (this.triggerId = e),
      (this.clickAction = E),
      (this.uri = n),
      (this.openTarget = T),
      (this.dismissType = o),
      (this.duration = r),
      (this.icon = l),
      (this.imageUrl = a),
      (this.imageStyle = I),
      (this.iconColor = c),
      (this.iconBackgroundColor = A),
      (this.backgroundColor = O),
      (this.textColor = _),
      (this.closeButtonColor = m),
      (this.animateIn = L),
      (this.animateOut = N),
      (this.header = S),
      (this.headerAlignment = R),
      (this.headerTextColor = M),
      (this.frameColor = D),
      (this.buttons = C),
      (this.cropType = d),
      (this.orientation = U),
      (this.htmlId = b),
      (this.css = P),
      (this.messageExtras = p),
      (this.message = t),
      (this.messageAlignment = s || InAppMessage.TextAlignment.CENTER),
      (this.duration = r || 5e3),
      (this.slideFrom = i || InAppMessage.SlideFrom.BOTTOM),
      (this.extras = h || {}),
      (this.triggerId = e),
      (this.clickAction = E || InAppMessage.ClickAction.NONE),
      (this.uri = n),
      (this.openTarget = T || InAppMessage.OpenTarget.NONE),
      (this.dismissType = o || InAppMessage.DismissType.AUTO_DISMISS),
      (this.icon = l),
      (this.imageUrl = a),
      (this.imageStyle = I || InAppMessage.ImageStyle.TOP),
      (this.iconColor = c || InAppMessage.th.ih),
      (this.iconBackgroundColor = A || InAppMessage.th.sh),
      (this.backgroundColor = O || InAppMessage.th.ih),
      (this.textColor = _ || InAppMessage.th.hh),
      (this.closeButtonColor = m || InAppMessage.th.eh),
      (this.animateIn = L),
      null == this.animateIn && (this.animateIn = !0),
      (this.animateOut = N),
      null == this.animateOut && (this.animateOut = !0),
      (this.header = S),
      (this.headerAlignment = R || InAppMessage.TextAlignment.CENTER),
      (this.headerTextColor = M || InAppMessage.th.hh),
      (this.frameColor = D || InAppMessage.th.Eh),
      (this.buttons = C || []),
      (this.cropType = d || InAppMessage.CropType.FIT_CENTER),
      (this.orientation = U),
      (this.htmlId = b),
      (this.css = P),
      (this.isControl = !1),
      (this.messageExtras = p),
      (this.nh = !1),
      (this.st = !1),
      (this.Co = !1),
      (this.Th = !1),
      (this.We = null),
      (this.Xe = null),
      (this._t = new u()),
      (this.oh = new u()),
      (this.do = InAppMessage.TextAlignment.CENTER);
  }
  subscribeToClickedEvent(t) {
    return this._t.Ns(t);
  }
  subscribeToDismissedEvent(t) {
    return this.oh.Ns(t);
  }
  removeSubscription(t) {
    this._t.removeSubscription(t), this.oh.removeSubscription(t);
  }
  removeAllSubscriptions() {
    this._t.removeAllSubscriptions(), this.oh.removeAllSubscriptions();
  }
  closeMessage() {
    this.ll(this.We);
  }
  Ye() {
    return !0;
  }
  Bo() {
    return this.Ye();
  }
  Ve() {
    return null != this.htmlId && this.htmlId.length > 4;
  }
  Oe() {
    return this.Ve() && null != this.css && this.css.length > 0;
  }
  _e() {
    if (this.Ve() && this.Oe()) return this.htmlId + "-css";
  }
  wt() {
    return !this.st && ((this.st = !0), !0);
  }
  ko() {
    return this.st;
  }
  rt(t) {
    return !this.Co && ((this.Co = !0), this._t.X(), !0);
  }
  ft() {
    return !this.Th && ((this.Th = !0), this.oh.X(), !0);
  }
  hide(t) {
    if (t && t.parentNode) {
      let s = t.closest(".ab-iam-root");
      if ((null == s && (s = t), this.Ye() && null != s.parentNode)) {
        const t = s.parentNode.classList;
        t && t.contains(InAppMessage.rh) && t.remove(InAppMessage.rh),
          document.body.removeEventListener("touchmove", InAppMessage.lh);
      }
      s.className = s.className.replace(InAppMessage.uh, InAppMessage.ah);
    }
    return this.animateOut || !1;
  }
  ll(t, s) {
    if (null == t) return;
    let i;
    (this.We = null),
      (i =
        -1 === t.className.indexOf("ab-in-app-message")
          ? t.getElementsByClassName("ab-in-app-message")[0]
          : t);
    let h = !1;
    i && (h = this.hide(i));
    const e = document.body;
    let E;
    null != e && (E = e.scrollTop);
    const n = () => {
      if (t && t.parentNode) {
        let s = t.closest(".ab-iam-root");
        null == s && (s = t), s.parentNode && s.parentNode.removeChild(s);
      }
      const i = this._e();
      if (null != i) {
        const t = document.getElementById(i);
        t && t.parentNode && t.parentNode.removeChild(t);
      }
      null != e && "Safari" === ro.browser && (e.scrollTop = E),
        s ? s() : this.ft();
    };
    h ? setTimeout(n, InAppMessage.Ih) : n(), this.Xe && this.Xe.focus();
  }
  po() {
    return document.createTextNode(this.message || "");
  }
  uo(t) {
    let s = "";
    this.message || this.header || !this.Ye() || (s = "Modal Image"),
      t.setAttribute("alt", s);
  }
  static lh(t) {
    if (t.targetTouches && t.targetTouches.length > 1) return;
    const s = t.target;
    (s &&
      s.classList &&
      s.classList.contains("ab-message-text") &&
      s.scrollHeight > s.clientHeight) ||
      (document.querySelector(`.${InAppMessage.rh}`) &&
        t.cancelable &&
        t.preventDefault());
  }
  Ah(t) {
    const s = t.parentNode;
    this.Ye() &&
      null != s &&
      this.orientation !== InAppMessage.Orientation.LANDSCAPE &&
      (null != s.classList && s.classList.add(InAppMessage.rh),
      document.body.addEventListener(
        "touchmove",
        InAppMessage.lh,
        !!Tt() && { passive: !1 },
      )),
      (t.className += " " + InAppMessage.uh);
  }
  static Oh(t) {
    if (
      t.keyCode === bt._h &&
      !r.sr(z.mh) &&
      document.querySelectorAll(".ab-modal-interactions").length > 0
    ) {
      const t = document.getElementsByClassName("ab-html-message");
      let s = !1;
      for (const i of t) {
        let t = null;
        It(i) &&
          i.contentWindow &&
          (t = i.contentWindow.document.getElementsByClassName(
            "ab-programmatic-close-button",
          )[0]),
          null != t && (Et(t), (s = !0));
      }
      if (!s) {
        const t = document.querySelectorAll(
          ".ab-modal-interactions > .ab-close-button",
        )[0];
        null != t && Et(t);
      }
    }
  }
  Lh() {
    this.nh ||
      r.sr(z.mh) ||
      (document.addEventListener("keydown", InAppMessage.Oh, !1),
      r.Nh(() => {
        document.removeEventListener("keydown", InAppMessage.Oh);
      }),
      (this.nh = !0));
  }
  Bs(t) {
    const s = {};
    return t
      ? ((s[InAppMessage.qt.ra] = this.message),
        (s[InAppMessage.qt.sa] = this.messageAlignment),
        (s[InAppMessage.qt.Sh] = this.slideFrom),
        (s[InAppMessage.qt.St] = this.extras),
        (s[InAppMessage.qt.ta] = this.triggerId),
        (s[InAppMessage.qt.ia] = this.clickAction),
        (s[InAppMessage.qt.URI] = this.uri),
        (s[InAppMessage.qt.oa] = this.openTarget),
        (s[InAppMessage.qt.pa] = this.dismissType),
        (s[InAppMessage.qt.ma] = this.duration),
        (s[InAppMessage.qt.na] = this.icon),
        (s[InAppMessage.qt.Jt] = this.imageUrl),
        (s[InAppMessage.qt.ua] = this.imageStyle),
        (s[InAppMessage.qt.ca] = this.iconColor),
        (s[InAppMessage.qt.fa] = this.iconBackgroundColor),
        (s[InAppMessage.qt.da] = this.backgroundColor),
        (s[InAppMessage.qt.la] = this.textColor),
        (s[InAppMessage.qt.ga] = this.closeButtonColor),
        (s[InAppMessage.qt.ja] = this.animateIn),
        (s[InAppMessage.qt.xa] = this.animateOut),
        (s[InAppMessage.qt.za] = this.header),
        (s[InAppMessage.qt.ha] = this.headerAlignment),
        (s[InAppMessage.qt.va] = this.headerTextColor),
        (s[InAppMessage.qt.wa] = this.frameColor),
        (s[InAppMessage.qt.ya] = this.buttons),
        (s[InAppMessage.qt.Sa] = this.cropType),
        (s[InAppMessage.qt.ba] = this.orientation),
        (s[InAppMessage.qt.ka] = this.htmlId),
        (s[InAppMessage.qt.CSS] = this.css),
        (s[InAppMessage.qt.zt] = t),
        (s[InAppMessage.qt.qa] = this.messageExtras),
        s)
      : s;
  }
}
(InAppMessage.th = {
  hh: 4281545523,
  ih: 4294967295,
  sh: 4278219733,
  Rh: 4293914607,
  Mh: 4283782485,
  Eh: 3224580915,
  eh: 4288387995,
}),
  (InAppMessage.lo = {
    Dh: "hd",
    Ze: "ias",
    Ch: "of",
    dh: "do",
    Uh: "umt",
    bh: "tf",
    Ph: "te",
  }),
  (InAppMessage.SlideFrom = { TOP: "TOP", BOTTOM: "BOTTOM" }),
  (InAppMessage.ClickAction = {
    NEWS_FEED: "NEWS_FEED",
    URI: "URI",
    NONE: "NONE",
  }),
  (InAppMessage.DismissType = {
    AUTO_DISMISS: "AUTO_DISMISS",
    MANUAL: "SWIPE",
  }),
  (InAppMessage.OpenTarget = { NONE: "NONE", BLANK: "BLANK" }),
  (InAppMessage.ImageStyle = { TOP: "TOP", GRAPHIC: "GRAPHIC" }),
  (InAppMessage.Orientation = { PORTRAIT: "PORTRAIT", LANDSCAPE: "LANDSCAPE" }),
  (InAppMessage.TextAlignment = {
    START: "START",
    CENTER: "CENTER",
    END: "END",
  }),
  (InAppMessage.CropType = {
    CENTER_CROP: "CENTER_CROP",
    FIT_CENTER: "FIT_CENTER",
  }),
  (InAppMessage.Qe = {
    jn: "SLIDEUP",
    dn: "MODAL",
    xo: "MODAL_STYLED",
    gn: "FULL",
    fn: "WEB_HTML",
    Le: "HTML",
    Io: "HTML_FULL",
  }),
  (InAppMessage.Ih = 500),
  (InAppMessage.ph = 200),
  (InAppMessage.uh = "ab-show"),
  (InAppMessage.ah = "ab-hide"),
  (InAppMessage.rh = "ab-pause-scrolling"),
  (InAppMessage.qt = {
    ra: "m",
    sa: "ma",
    Sh: "sf",
    St: "e",
    ta: "ti",
    ia: "ca",
    URI: "u",
    oa: "oa",
    pa: "dt",
    ma: "d",
    na: "i",
    Jt: "iu",
    ua: "is",
    ca: "ic",
    fa: "ibc",
    da: "bc",
    la: "tc",
    ga: "cbc",
    ja: "ai",
    xa: "ao",
    za: "h",
    ha: "ha",
    va: "htc",
    wa: "fc",
    ya: "b",
    Sa: "ct",
    ba: "o",
    ka: "hi",
    CSS: "css",
    zt: "type",
    Po: "messageFields",
    qa: "me",
  });
