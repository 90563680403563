import ne, { STORAGE_KEYS as t } from "./storage-manager.js";
import { logger as N } from "../../shared-lib/index.js";
const Wt = {
  La: function (e, o = !1) {
    let r = !1;
    try {
      if (localStorage && localStorage.getItem)
        try {
          localStorage.setItem(t.bs.ec, "true"),
            localStorage.getItem(t.bs.ec) &&
              (localStorage.removeItem(t.bs.ec), (r = !0));
        } catch (e) {
          if (
            !(
              e instanceof Error &&
              ("QuotaExceededError" === e.name ||
                "NS_ERROR_DOM_QUOTA_REACHED" === e.name) &&
              localStorage.length > 0
            )
          )
            throw e;
          r = !0;
        }
    } catch (e) {
      N.info("Local Storage not supported!");
    }
    const a = Wt.oc(),
      n = new ne.tc(e, a && !o, r);
    let c;
    return (c = r ? new ne.rc(e) : new ne.ac()), new ne(n, c);
  },
  oc: function () {
    return (
      navigator.cookieEnabled ||
      ("cookie" in document &&
        (document.cookie.length > 0 ||
          (document.cookie = "test").indexOf.call(document.cookie, "test") >
            -1))
    );
  },
};
export default Wt;
