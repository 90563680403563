export default class ControlMessage {
  constructor(s, t) {
    (this.triggerId = s),
      (this.messageExtras = t),
      (this.triggerId = s),
      (this.messageExtras = t),
      (this.extras = {}),
      (this.isControl = !0),
      (this.st = !1);
  }
  static fromJson(s) {
    return new ControlMessage(s.trigger_id, s.message_extras);
  }
  wt() {
    return !this.st && ((this.st = !0), !0);
  }
  ko() {
    return this.st;
  }
}
