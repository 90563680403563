import ts from "./custom-event-data.js";
import rs from "./custom-event-property-data.js";
import is from "./filter-set.js";
import ni from "./in-app-message-click-data.js";
import ns from "./purchase-data.js";
import hs from "./purchase-property-data.js";
import ur from "./push-click-data.js";
import et from "./trigger-events.js";
import { logger as N } from "../../../shared-lib/index.js";
import { getErrorMessage as si } from "../../util/error-utils.js";
export default class ri {
  constructor(e, t) {
    (this.type = e), (this.data = t), (this.type = e), (this.data = t);
  }
  kc(e, t) {
    return ri._c[this.type] === e && (null == this.data || this.data.xc(t));
  }
  static sf(e, t) {
    let r = null;
    try {
      r = window.atob(e);
    } catch (t) {
      return N.info("Failed to unencode analytics id " + e + ": " + si(t)), !1;
    }
    return t === r.split("_")[0];
  }
  static fromJson(e) {
    const t = e.type;
    let r = null;
    switch (t) {
      case ri.ln.OPEN:
      case ri.ln.Xt:
        break;
      case ri.ln.Rr:
        r = ns.fromJson(e.data);
        break;
      case ri.ln.Ec:
        r = hs.fromJson(e.data);
        break;
      case ri.ln.kr:
        r = ur.fromJson(e.data);
        break;
      case ri.ln.ve:
        r = ts.fromJson(e.data);
        break;
      case ri.ln.jc:
        r = rs.fromJson(e.data);
        break;
      case ri.ln.Ao:
        r = ni.fromJson(e.data);
    }
    return new ri(t, r);
  }
  Bs() {
    return { t: this.type, d: this.data ? this.data.Bs() : null };
  }
  static Qn(e) {
    let t,
      r = null;
    switch (e.t) {
      case ri.ln.OPEN:
      case ri.ln.Xt:
        break;
      case ri.ln.Rr:
        r = new ns(e.d);
        break;
      case ri.ln.Ec:
        (t = e.d || {}), (r = new hs(t.id, is.Qn(t.pf || [])));
        break;
      case ri.ln.kr:
        r = new ur(e.d);
        break;
      case ri.ln.ve:
        r = new ts(e.d);
        break;
      case ri.ln.jc:
        (t = e.d || {}), (r = new rs(t.e, is.Qn(t.pf || [])));
        break;
      case ri.ln.Ao:
        r = new ni(e.d);
    }
    return new ri(e.t, r);
  }
}
(ri.ln = {
  OPEN: "open",
  Rr: "purchase",
  Ec: "purchase_property",
  kr: "push_click",
  ve: "custom_event",
  jc: "custom_event_property",
  Ao: "iam_click",
  Xt: "test",
}),
  (ri._c = {}),
  (ri._c[ri.ln.OPEN] = et.OPEN),
  (ri._c[ri.ln.Rr] = et.Rr),
  (ri._c[ri.ln.Ec] = et.Rr),
  (ri._c[ri.ln.kr] = et.kr),
  (ri._c[ri.ln.ve] = et.ve),
  (ri._c[ri.ln.jc] = et.ve),
  (ri._c[ri.ln.Ao] = et.Ao),
  (ri._c[ri.ln.Xt] = et.Xt);
