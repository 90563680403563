import ro from "../../util/browser-detector.js";
import { InAppMessage, logInAppMessageHtmlClick } from "../index.js";
import { OperatingSystems as so } from "../../util/device-constants.js";
import { buildHtmlClickHandler as ct } from "./html-message-display-utils.js";
import { attachHtmlToIframeWithNonce as m } from "../../util/html-display-utils.js";
import { buildBrazeBridge as b } from "../../util/html-display-utils.js";
export default function dt(t, e, o, s, n, i) {
  const l = document.createElement("iframe");
  l.setAttribute("title", "Modal Message"),
    n && (l.style.zIndex = (n + 1).toString());
  if (
    (m(l, t.message, i),
    (l.onload = () => {
      const n = l.contentWindow;
      n.focus();
      const a = n.document.getElementsByTagName("head")[0];
      if (null != a) {
        if (t.Oe()) {
          const e = document.createElement("style");
          (e.innerHTML = t.css || ""),
            (e.id = t._e() || ""),
            null != i && e.setAttribute("nonce", i),
            a.appendChild(e);
        }
        const e = n.document.createElement("base");
        null != e && (e.setAttribute("target", "_parent"), a.appendChild(e));
      }
      const r = n.document.getElementsByTagName("title");
      r && r.length > 0 && l.setAttribute("title", r[0].textContent || "");
      const c = Object.assign(
        Object.assign(
          {},
          b(l, e, (e, o) => t.ll(e, o)),
        ),
        {
          closeMessage: function () {
            t.ll(l);
          },
          logClick: function () {
            logInAppMessageHtmlClick(t, ...arguments);
          },
        },
      );
      if (
        ((n.appboyBridge = c), (n.brazeBridge = c), t.Ke !== InAppMessage.Qe.Le)
      ) {
        const e = n.document.getElementsByTagName("a");
        for (let o = 0; o < e.length; o++) e[o].onclick = ct(t, l, e[o], s);
        const o = n.document.getElementsByTagName("button");
        for (let e = 0; e < o.length; e++) o[e].onclick = ct(t, l, o[e], s);
      }
      const m = n.document.body;
      if (null != m) {
        t.Ve() && (m.id = t.htmlId || "");
        const e = document.createElement("hidden");
        (e.onclick = c.closeMessage),
          (e.className = "ab-programmatic-close-button"),
          m.appendChild(e);
      }
      n.dispatchEvent(new CustomEvent("ab.BridgeReady")),
        -1 !== l.className.indexOf("ab-start-hidden") &&
          ((l.className = l.className.replace("ab-start-hidden", "")), o(l));
    }),
    (l.className =
      "ab-in-app-message ab-start-hidden ab-html-message ab-modal-interactions"),
    ro.OS === so.ao)
  ) {
    const e = document.createElement("div");
    return (
      (e.className = "ab-ios-scroll-wrapper"), e.appendChild(l), (t.We = e), e
    );
  }
  return (t.We = l), l;
}
