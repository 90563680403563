import InAppMessage from "./models/in-app-message.js";
import r from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import { logger as N } from "../../shared-lib/index.js";
import se from "./in-app-message-manager-factory.js";
export function deferInAppMessage(e) {
  if (r.rr())
    return e instanceof ControlMessage
      ? (N.info("Not deferring since this is a ControlMessage."), !1)
      : e instanceof InAppMessage
      ? se.ea().Me(e)
      : (N.info("Not an instance of InAppMessage, ignoring."), !1);
}
