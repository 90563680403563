import r from "../managers/braze-instance.js";
import { keys as y } from "../util/code-utils.js";
import { logger as N, IndexedDBAdapter as it } from "../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as p } from "../common/constants.js";
export function wipeData() {
  const o = r.j();
  if (null == o) return void N.warn(p);
  o.clearData();
  const t = y(it.Gs);
  for (let o = 0; o < t.length; o++) {
    const n = t[o],
      r = it.Gs[n];
    new it(r, N).clearData();
  }
  if (r.rr()) for (const o of r.lr()) o.clearData(!0);
}
