import Card from "./card.js";
export default class CaptionedImage extends Card {
  constructor(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f) {
    super(t, s, i, h, e, r, a, o, c, n, d, p, u, l, m, f),
      (this.At = "ab-captioned-image"),
      (this.Tt = !0),
      (this.test = !1);
  }
  Bs() {
    const t = {};
    return (
      (t[Card.qt.zt] = Card.Dt.Bt),
      (t[Card.qt.Et] = this.id),
      (t[Card.qt.Ft] = this.viewed),
      (t[Card.qt.Gt] = this.title),
      (t[Card.qt.Jt] = this.imageUrl),
      (t[Card.qt.Kt] = this.description),
      (t[Card.qt.Lt] = this.updated),
      (t[Card.qt.Mt] = this.created),
      (t[Card.qt.Nt] = this.categories),
      (t[Card.qt.Ot] = this.expiresAt),
      (t[Card.qt.URL] = this.url),
      (t[Card.qt.Pt] = this.linkText),
      (t[Card.qt.Qt] = this.aspectRatio),
      (t[Card.qt.St] = this.extras),
      (t[Card.qt.Ut] = this.pinned),
      (t[Card.qt.Vt] = this.dismissible),
      (t[Card.qt.Wt] = this.clicked),
      (t[Card.qt.Xt] = this.test),
      t
    );
  }
}
