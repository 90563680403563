import { STORAGE_KEYS as t } from "./storage-manager.js";
import u from "./subscription-manager.js";
import Jt from "../models/server-config.js";
import c from "../common/event-logger.js";
import { EventTypes as d } from "../../shared-lib/index.js";
export default class Dt {
  constructor(t) {
    (this.C = t),
      (this.C = t),
      (this.al = new u()),
      (this.hl = new u()),
      (this.ul = new u()),
      (this.cl = new u()),
      (this.ml = null),
      (this.gl = null);
  }
  bl() {
    if (null == this.gl) {
      const e = this.C.ps(t.bs.fl);
      this.gl = null != e ? Jt.Qn(e) : new Jt();
    }
    return this.gl;
  }
  Es() {
    return this.bl().dl;
  }
  Rl(e) {
    if (null != e && null != e.config) {
      const i = e.config;
      if (i.time > this.bl().dl) {
        const e = (t) => (null == t ? this.bl().Cl : t),
          s = new Jt(
            i.time,
            i.events_blacklist,
            i.attributes_blacklist,
            i.purchases_blacklist,
            i.messaging_session_timeout,
            i.vapid_public_key,
            i.content_cards,
            i.feature_flags,
            e(i.global_request_rate_limit),
            i.banners,
          );
        let n = !1;
        null != s.El && this.Gn() !== s.El && (n = !0);
        let r = !1;
        null != s.vl.enabled && this.Xi() !== s.vl.enabled && (r = !0);
        let l = !1;
        null != s.Gi.enabled && this.Qi() !== s.Gi.enabled && (l = !0);
        let a = !1;
        null != s.banners.enabled &&
          this.Us() !== s.banners.enabled &&
          (a = !0),
          (this.gl = s),
          this.C.js(t.bs.fl, s.Bs()),
          n && this.al.X(),
          r && this.hl.X(),
          l && this.ul.X(),
          a && this.cl.X();
      }
    }
  }
  Hn(t) {
    const e = this.al.Ns(t);
    return this.ml && this.al.removeSubscription(this.ml), (this.ml = e), e;
  }
  $i(t) {
    return this.hl.Ns(t);
  }
  Re(t) {
    return this.ul.Ns(t);
  }
  A(t) {
    return this.cl.Ns(t);
  }
  ge(t) {
    return -1 !== this.bl().wl.indexOf(t);
  }
  hu(t) {
    return -1 !== this.bl().yl.indexOf(t);
  }
  Dr(t) {
    return -1 !== this.bl().Ll.indexOf(t);
  }
  Bl() {
    return this.bl().Fl;
  }
  Gn() {
    return this.bl().El;
  }
  Xi() {
    return this.bl().vl.enabled || !1;
  }
  Tl() {
    const t = this.bl().Cl;
    return !(!t || null == t.enabled) && t.enabled;
  }
  il() {
    if (!this.Tl()) return -1;
    const t = this.bl().Cl;
    return null == t.capacity || t.capacity < 10 ? -1 : t.capacity;
  }
  sl() {
    if (!this.Tl()) return -1;
    const t = this.bl().Cl;
    return null == t.refill_rate || t.refill_rate <= 0 ? -1 : t.refill_rate;
  }
  jl(t) {
    const e = this.bl().Cl.endpoint_overrides;
    return null == e ? null : e[t];
  }
  rl(t) {
    const e = this.jl(t);
    return null == e || isNaN(e.capacity) || e.capacity <= 0 ? -1 : e.capacity;
  }
  ol(t) {
    const e = this.jl(t);
    return null == e || isNaN(e.refill_rate) || e.refill_rate <= 0
      ? -1
      : e.refill_rate;
  }
  Qi() {
    return this.bl().Gi.enabled && null == this.Se()
      ? (c.ds(d.Li, { e: "Missing feature flag refresh_rate_limit." }), !1)
      : this.bl().Gi.enabled || !1;
  }
  Se() {
    return this.bl().Gi.refresh_rate_limit;
  }
  Us() {
    return this.bl().banners.enabled || !1;
  }
  ee() {
    return this.bl().banners.max_placements || 0;
  }
}
