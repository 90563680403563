import {
  addPassiveEventListener as J,
  buildSvg as oe,
  detectSwipe as H,
  DIRECTIONS as R,
} from "../../util/dom-utils.js";
import { createCloseButton as T } from "../../util/component-utils.js";
import { isTransparent as ae, toRgba as le } from "../../util/color-utils.js";
import FullScreenMessage from "../models/full-screen-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import ModalMessage from "../models/modal-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import { logInAppMessageClick } from "../log-in-app-message-click.js";
import { _handleBrazeAction as F } from "../../Core/handle-braze-action.js";
import dt from "./html-message-to-html.js";
import ce from "./modal-utils.js";
import { logger as N, Guid as G } from "../../../shared-lib/index.js";
export default function de(e, o, a, t, n, s, i, m = document.body, l = "ltr") {
  if (((e.Xe = document.activeElement), e instanceof HtmlMessage))
    return dt(e, o, a, n, s, i);
  const c = (function (e, o, a, t, n, s, i = document.body, m = "ltr") {
    let l = null;
    const c = document.createElement("div");
    (c.dir = m),
      (c.className = "ab-in-app-message ab-start-hidden ab-background"),
      s && (c.style.zIndex = (s + 1).toString()),
      e.Ye() &&
        ((c.className += " ab-modal-interactions"),
        c.setAttribute("tabindex", "-1")),
      e.Oe() ||
        ((c.style.color = le(e.textColor)),
        (c.style.backgroundColor = le(e.backgroundColor)),
        ae(e.backgroundColor) && (c.className += " ab-no-shadow"));
    const d = () => {
        -1 !== c.className.indexOf("ab-start-hidden") &&
          ((c.className = c.className.replace("ab-start-hidden", "")),
          document.querySelectorAll(".ab-iam-img-loading").length > 0
            ? t(
                `Cannot show in-app message ${e.message} because another message is being shown.`,
                InAppMessage.lo.Ze,
              )
            : a(c));
      },
      r = (o = !0) => {
        let a = document.querySelectorAll(".ab-iam-root");
        (a && 0 !== a.length) || (a = i.querySelectorAll(".ab-iam-root")),
          a &&
            a.length > 0 &&
            (a[0].classList.remove("ab-iam-img-loading"),
            l && (clearTimeout(l), (l = null)),
            o
              ? d()
              : N.error(
                  `Cannot show in-app message ${e.message} because the image failed to load.`,
                ));
      };
    e.imageStyle === InAppMessage.ImageStyle.GRAPHIC &&
      (c.className += " graphic"),
      e.orientation === InAppMessage.Orientation.LANDSCAPE &&
        (c.className += " landscape"),
      null != e.buttons &&
        0 === e.buttons.length &&
        (e.clickAction !== InAppMessage.ClickAction.NONE &&
          (c.className += " ab-clickable"),
        (c.onclick = (a) => (
          e.ll(c, () => {
            logInAppMessageClick(e),
              e.clickAction === InAppMessage.ClickAction.URI
                ? F(
                    e.uri || "",
                    n || e.openTarget === InAppMessage.OpenTarget.BLANK,
                    a,
                  )
                : e.clickAction === InAppMessage.ClickAction.NEWS_FEED && o();
          }),
          a.stopPropagation(),
          !1
        )));
    const u = T(
      "Close Message",
      e.Oe() ? void 0 : le(e.closeButtonColor),
      () => {
        e.ll(c);
      },
      m,
    );
    c.appendChild(u), s && (u.style.zIndex = (s + 2).toString());
    const b = document.createElement("div");
    (b.className = "ab-message-text"), (b.dir = m);
    const p = (e.messageAlignment || e.do).toLowerCase();
    b.className += " " + p + "-aligned";
    let g = !1;
    const f = document.createElement("div");
    if (((f.className = "ab-image-area"), e.imageUrl)) {
      if (e.cropType === InAppMessage.CropType.CENTER_CROP) {
        const o = document.createElement("span");
        (o.className = "ab-center-cropped-img"),
          (o.style.backgroundImage = "url(" + e.imageUrl + ")"),
          o.setAttribute("role", "img"),
          o.setAttribute("aria-label", "Modal Image"),
          e.uo(o),
          f.appendChild(o);
      } else {
        const o = document.createElement("img");
        if (
          (o.setAttribute("src", e.imageUrl),
          e.uo(o),
          0 === document.querySelectorAll(".ab-iam-img-loading").length)
        ) {
          g = !0;
          const e = document.querySelectorAll(".ab-iam-root");
          e && e.length > 0 && e[0].classList.add("ab-iam-img-loading"),
            (l = window.setTimeout(() => {
              r(!1);
            }, 6e4)),
            (o.onload = () => {
              r();
            }),
            (o.onerror = () => {
              r(!1);
            });
        }
        f.appendChild(o);
      }
      c.appendChild(f), (b.className += " ab-with-image");
    } else if (e.icon) {
      f.className += " ab-icon-area";
      const o = document.createElement("span");
      (o.className = "ab-icon"),
        e.Oe() ||
          ((o.style.backgroundColor = le(e.iconBackgroundColor)),
          (o.style.color = le(e.iconColor)));
      const a = document.createElement("i");
      (a.className = "fa"),
        a.appendChild(document.createTextNode(e.icon)),
        a.setAttribute("aria-hidden", "true"),
        o.appendChild(a),
        f.appendChild(o),
        c.appendChild(f),
        (b.className += " ab-with-icon");
    }
    if ((J(b, "touchstart"), e.header && e.header.length > 0)) {
      const o = document.createElement("h1");
      (o.className = "ab-message-header"), (e.bo = G.Rt()), (o.id = e.bo);
      const a = (
        e.headerAlignment || InAppMessage.TextAlignment.CENTER
      ).toLowerCase();
      (o.className += " " + a + "-aligned"),
        e.Oe() || (o.style.color = le(e.headerTextColor)),
        o.appendChild(document.createTextNode(e.header)),
        b.appendChild(o);
    }
    return b.appendChild(e.po()), c.appendChild(b), g || d(), (e.We = c), c;
  })(e, o, a, t, n, s, m, l);
  if (e instanceof FullScreenMessage || e instanceof ModalMessage) {
    const a = e instanceof FullScreenMessage ? "ab-fullscreen" : "ab-modal";
    (c.className += ` ${a} ab-centered`),
      ce.fo(e, o, c, n),
      ce.ho(c),
      ce.jo(e.bo, c);
  } else if (e instanceof SlideUpMessage) {
    c.className += " ab-slideup";
    const o = c.getElementsByClassName("ab-close-button")[0];
    if (null != o) {
      const a = oe(
        "0 0 11.38 19.44",
        "M11.38 9.72l-9.33 9.72L0 17.3l7.27-7.58L0 2.14 2.05 0l9.33 9.72z",
        e.Oe() ? void 0 : le(e.closeButtonColor),
      );
      a.setAttribute("class", "ab-chevron"), o.appendChild(a);
    }
    let a, t;
    H(c, R.kt, (e) => {
      (c.className += " ab-swiped-left"),
        null != o && null != o.onclick && o.onclick(e);
    }),
      H(c, R.Ht, (e) => {
        (c.className += " ab-swiped-right"),
          null != o && null != o.onclick && o.onclick(e);
      }),
      e.slideFrom === InAppMessage.SlideFrom.TOP
        ? ((a = R.wo), (t = " ab-swiped-up"))
        : ((a = R.vo), (t = " ab-swiped-down")),
      H(c, a, (e) => {
        (c.className += t), null != o && null != o.onclick && o.onclick(e);
      });
  }
  return c;
}
