import { logger as N } from "../../shared-lib/index.js";
import s from "../common/base-provider.js";
import r from "../managers/braze-instance.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import u from "../managers/subscription-manager.js";
import { randomInclusive as a } from "../util/math.js";
import l from "../util/net.js";
import {
  newFeatureFlagFromJson as ht,
  newFeatureFlagFromSerializedValue as at,
} from "./feature-flag-factory.js";
import h from "../util/request-header-utils.js";
export default class tr extends s {
  constructor(t, s, i, e) {
    super(),
      (this.B = t),
      (this.S = s),
      (this.C = i),
      (this.T = e),
      (this.Gi = []),
      (this.Hi = 0),
      (this.B = t),
      (this.S = s),
      (this.C = i),
      (this.T = e),
      (this.Ki = null),
      (this.Oi = new u()),
      (this.R = 10),
      (this.I = null),
      (this.q = null),
      r.N(this.Oi);
  }
  U(t) {
    var s;
    if (
      (null === (s = this.B) || void 0 === s ? void 0 : s.Qi()) &&
      null != t &&
      t.feature_flags
    ) {
      this.Gi = [];
      for (const s of t.feature_flags) {
        const t = ht(s);
        t && this.Gi.push(t);
      }
      (this.Hi = new Date().getTime()), this.Vi(), this.Oi.X(this.Gi);
    }
  }
  Wi() {
    let s = {};
    this.C && (s = this.C.ps(t.bs.Yi));
    const i = {};
    for (const t in s) {
      const e = at(s[t]);
      e && (i[e.id] = e);
    }
    return i;
  }
  Zi() {
    var s;
    return (
      (null === (s = this.C) || void 0 === s ? void 0 : s.ps(t.bs.we)) || {}
    );
  }
  je(s) {
    this.C && this.C.js(t.bs.we, s);
  }
  Fs(t) {
    return this.Oi.Ns(t);
  }
  refreshFeatureFlags(t, s, i = !1, e = !0) {
    const r = () => {
      "function" == typeof s && s(), this.Oi.X(this.Gi);
    };
    if (!this.Ce(i))
      return (
        !this.Ki &&
          this.B &&
          (this.Ki = this.B.Re(() => {
            this.refreshFeatureFlags(t, s);
          })),
        void r()
      );
    const o = this.S;
    if (!o) return void r();
    e && this.G();
    const n = o.H({}, !0),
      u = o.J(n, h.O.De);
    let f = !1;
    o.V(
      n,
      (o = -1) => {
        if (!this.S) return void r();
        const c = new Date().valueOf();
        h.W(this.C, h.O.De, c),
          -1 !== o && u.push(["X-Braze-Req-Tokens-Remaining", o.toString()]),
          l.Y({
            url: `${this.S.Z()}/feature_flags/sync`,
            headers: u,
            data: n,
            ss: (s) => {
              if (!this.S.ts(n, s, u)) return (f = !0), void r();
              this.S.es(),
                this.U(s),
                (f = !1),
                h.ns(this.C, h.O.De, 1),
                "function" == typeof t && t();
            },
            error: (t) => {
              this.S.rs(t, "retrieving feature flags"), (f = !0), r();
            },
            os: () => {
              if (e && f && !this.q) {
                h.hs(this.C, h.O.De);
                let e = this.I;
                (null == e || e < 1e3 * this.R) && (e = 1e3 * this.R),
                  this.ls(Math.min(3e5, a(1e3 * this.R, 3 * e)), t, s, i);
              }
            },
          });
      },
      h.O.De,
      s,
    );
  }
  G() {
    null != this.q && (clearTimeout(this.q), (this.q = null));
  }
  ls(t = 1e3 * this.R, s, i, e = !1) {
    this.G(),
      (this.q = window.setTimeout(() => {
        this.refreshFeatureFlags(s, i, e);
      }, t)),
      (this.I = t);
  }
  Ce(t) {
    if (!this.B) return !1;
    if (!t) {
      const t = this.B.Se();
      if (null == t) return !1;
      let s = !1;
      if (!isNaN(t)) {
        if (-1 === t) return N.info("Feature flag refreshes not allowed"), !1;
        s = new Date().getTime() >= (this.Hi || 0) + 1e3 * t;
      }
      if (!s)
        return (
          N.info(`Feature flag refreshes were rate limited to ${t} seconds`), !1
        );
    }
    return this.B.Qi();
  }
  qe() {
    var s;
    return (
      (null === (s = this.C) || void 0 === s ? void 0 : s.ps(t.bs.xe)) || null
    );
  }
  ze() {
    var s, i;
    null === (s = this.C) ||
      void 0 === s ||
      s.js(t.bs.xe, null === (i = this.T) || void 0 === i ? void 0 : i.Ss());
  }
  $e() {
    var t;
    const s = null === (t = this.T) || void 0 === t ? void 0 : t.Ss(),
      i = this.qe();
    return null == i || s === i;
  }
  Vi() {
    if (!this.C) return;
    const s = {};
    for (const t of this.Gi) {
      const i = t.Bs();
      s[t.id] = i;
    }
    this.C.js(t.bs.Yi, s), this.C.js(t.bs.ke, this.Hi), this.ze();
  }
}
