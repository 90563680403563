import r from "../managers/braze-instance.js";
import Banner from "./banner.js";
import { logger as N } from "../../shared-lib/index.js";
import i from "./banner-provider-factory.js";
import { keys as y } from "../util/code-utils.js";
export function logBannerClick(n, o) {
  if (!r.rr()) return;
  if (!(n instanceof Banner))
    return (
      N.error("Banner argument to logBannerClick must be an Banner object."), !1
    );
  const e = i.m(),
    t = e.vs();
  return 0 === y(t).length
    ? (N.info("Not logging banner click. No banners exist."), !1)
    : t[n.placementId]
    ? e.logClick(n, o)
    : (N.info(
        `Not logging banner click for ID ${n.placementId}. The placement ID did not correspond to any banner.`,
      ),
      !1);
}
