import { logger as N } from "../../shared-lib/index.js";
import { getErrorMessage as si } from "./error-utils.js";
export function _isInView(t, n = !1, e = !1, s = !1) {
  if (null == t) return !1;
  (n = n || !1), (e = e || !1);
  const i = t.getBoundingClientRect();
  return (
    null != i &&
    ((i.top >= 0 &&
      i.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
      !n) &&
    (i.left >= 0 || !s) &&
    ((i.bottom >= 0 &&
      i.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)) ||
      !e) &&
    (i.right <= (window.innerWidth || document.documentElement.clientWidth) ||
      !s)
  );
}
export const DOMUtils = { Ic: null, Mo: _isInView };
export const DIRECTIONS = { wo: "up", vo: "down", kt: "left", Ht: "right" };
export function supportsPassive() {
  if (null == DOMUtils.Ic) {
    DOMUtils.Ic = !1;
    try {
      const t = Object.defineProperty({}, "passive", {
        get: () => {
          DOMUtils.Ic = !0;
        },
      });
      window.addEventListener("testPassive", () => {}, t),
        window.removeEventListener("testPassive", () => {}, t);
    } catch (t) {
      N.error(si(t));
    }
  }
  return DOMUtils.Ic;
}
export function addPassiveEventListener(t, n, e = () => {}) {
  t.addEventListener(n, e, !!supportsPassive() && { passive: !0 });
}
export function topIsInView(t) {
  return DOMUtils.Mo(t, !0, !1, !1);
}
export function bottomIsInView(t) {
  return DOMUtils.Mo(t, !1, !0, !1);
}
export function clickElement(t) {
  if (t.onclick) {
    const n = document.createEvent("MouseEvents");
    n.initEvent("click", !0, !0), t.onclick.apply(t, [n]);
  }
}
export function detectSwipe(t, n, e) {
  let s = null,
    i = null;
  addPassiveEventListener(t, "touchstart", (t) => {
    (s = t.touches[0].clientX), (i = t.touches[0].clientY);
  }),
    addPassiveEventListener(t, "touchmove", (o) => {
      if (null == s || null == i) return;
      const l = s - o.touches[0].clientX,
        u = i - o.touches[0].clientY;
      Math.abs(l) > Math.abs(u) && Math.abs(l) >= 25
        ? (((l > 0 && n === DIRECTIONS.kt) || (l < 0 && n === DIRECTIONS.Ht)) &&
            e(o),
          (s = null),
          (i = null))
        : Math.abs(u) >= 25 &&
          (((u > 0 &&
            n === DIRECTIONS.wo &&
            t.scrollTop === t.scrollHeight - t.offsetHeight) ||
            (u < 0 && n === DIRECTIONS.vo && 0 === t.scrollTop)) &&
            e(o),
          (s = null),
          (i = null));
    });
}
export function buildSvg(t, n, e) {
  const s = "http://www.w3.org/2000/svg",
    i = document.createElementNS(s, "svg");
  i.setAttribute("viewBox", t), i.setAttribute("xmlns", s);
  const o = document.createElementNS(s, "path");
  return (
    o.setAttribute("d", n),
    null != e && o.setAttribute("fill", e),
    i.appendChild(o),
    i
  );
}
