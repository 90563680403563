import M from "./card-manager.js";
import r from "../managers/braze-instance.js";
const _ = {
  l: !1,
  aa: null,
  ea: () => (_.p(), _.aa || (_.aa = new M(r.j())), _.aa),
  p: () => {
    _.l || (r.h(_), (_.l = !0));
  },
  destroy: () => {
    (_.aa = null), (_.l = !1);
  },
};
export default _;
