import InAppMessage from "./in-app-message.js";
import { DOMUtils as ue } from "../../util/dom-utils.js";
export default class SlideUpMessage extends InAppMessage {
  constructor(
    e,
    t,
    s,
    o,
    i,
    r,
    n,
    d,
    a,
    u,
    p,
    m,
    c,
    l,
    v,
    x,
    f,
    h,
    g,
    I,
    M,
    b,
  ) {
    (x = x || InAppMessage.th.Mh),
      (v = v || InAppMessage.th.Rh),
      super(
        e,
        (t = t || InAppMessage.TextAlignment.START),
        s,
        o,
        i,
        r,
        n,
        d,
        a,
        u,
        p,
        m,
        void 0,
        c,
        l,
        v,
        x,
        f,
        h,
        g,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        I,
        M,
        b,
      ),
      (this.do = InAppMessage.TextAlignment.START);
  }
  Ye() {
    return !1;
  }
  po() {
    const e = document.createElement("span");
    return e.appendChild(document.createTextNode(this.message || "")), e;
  }
  Ah(e) {
    const t = e.getElementsByClassName("ab-in-app-message")[0];
    ue.Mo(t, !0, !0) ||
      (this.slideFrom === InAppMessage.SlideFrom.TOP
        ? (t.style.top = "0px")
        : (t.style.bottom = "0px")),
      super.Ah(e);
  }
  Bs() {
    return super.Bs(SlideUpMessage.Dt);
  }
  static hn(e) {
    return new SlideUpMessage(
      e[InAppMessage.qt.ra],
      e[InAppMessage.qt.sa],
      e[InAppMessage.qt.Sh],
      e[InAppMessage.qt.St],
      e[InAppMessage.qt.ta],
      e[InAppMessage.qt.ia],
      e[InAppMessage.qt.URI],
      e[InAppMessage.qt.oa],
      e[InAppMessage.qt.pa],
      e[InAppMessage.qt.ma],
      e[InAppMessage.qt.na],
      e[InAppMessage.qt.Jt],
      e[InAppMessage.qt.ca],
      e[InAppMessage.qt.fa],
      e[InAppMessage.qt.da],
      e[InAppMessage.qt.la],
      e[InAppMessage.qt.ga],
      e[InAppMessage.qt.ja],
      e[InAppMessage.qt.xa],
      e[InAppMessage.qt.ka],
      e[InAppMessage.qt.CSS],
      e[InAppMessage.qt.qa],
    );
  }
}
SlideUpMessage.Dt = InAppMessage.Qe.jn;
