export default class wt {
  constructor(t, s, i, h, l) {
    (this.triggerId = t),
      (this.un = s),
      (this.on = i),
      (this.pn = h),
      (this.mn = l),
      (this.triggerId = t),
      (this.un = s),
      (this.on = i),
      (this.pn = h),
      (this.mn = l);
  }
  static fromJson(t, s, i, h, l) {
    return null == t || null == t.trigger_id
      ? null
      : new wt(t.trigger_id, s, i, h, l);
  }
}
