export const TOP_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-top-impression";
export const BOTTOM_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-bottom-impression";
export function topHadImpression(o) {
  return null != o && !!o.getAttribute("data-ab-had-top-impression");
}
export function impressOnTop(o) {
  null != o && o.setAttribute("data-ab-had-top-impression", "true");
}
export function bottomHadImpression(o) {
  return null != o && !!o.getAttribute("data-ab-had-bottom-impression");
}
export function impressOnBottom(o) {
  null != o && o.setAttribute("data-ab-had-bottom-impression", "true");
}
export const detectImpression = {
  oo: topHadImpression,
  no: bottomHadImpression,
};
