import rr from "../common/base-feed.js";
import { logCardClick, logCardImpressions } from "../Card/index.js";
import { requestFeedRefresh } from "./request-feed-refresh.js";
export default class Feed extends rr {
  constructor(r, e) {
    super(r, e);
  }
  logCardImpressions(r) {
    logCardImpressions(r, !1);
  }
  logCardClick(r) {
    return logCardClick(r, !1);
  }
  dr() {
    requestFeedRefresh();
  }
  ur() {
    return !1;
  }
}
