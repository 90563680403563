import InAppMessage from "./in-app-message.js";
export default class HtmlMessage extends InAppMessage {
  constructor(i, o, e, r, d, t, s, v, n, u, a, c) {
    super(
      i,
      void 0,
      void 0,
      o,
      e,
      void 0,
      void 0,
      void 0,
      (r = r || InAppMessage.DismissType.MANUAL),
      d,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      void 0,
      t,
      s,
      void 0,
      void 0,
      void 0,
      v,
      void 0,
      void 0,
      void 0,
      n,
      u,
      c,
    ),
      (this.messageFields = a),
      (this.messageFields = a);
  }
  Bo() {
    return !1;
  }
  rt(i) {
    if (this.Ke === InAppMessage.Qe.fn) {
      if (this.Co) return !1;
      this.Co = !0;
    }
    return this._t.X(i), !0;
  }
  Bs() {
    const i = super.Bs(HtmlMessage.Dt);
    return (i[InAppMessage.qt.Po] = this.messageFields), i;
  }
  static hn(i) {
    return new HtmlMessage(
      i[InAppMessage.qt.ra],
      i[InAppMessage.qt.St],
      i[InAppMessage.qt.ta],
      i[InAppMessage.qt.pa],
      i[InAppMessage.qt.ma],
      i[InAppMessage.qt.ja],
      i[InAppMessage.qt.xa],
      i[InAppMessage.qt.wa],
      i[InAppMessage.qt.ka],
      i[InAppMessage.qt.CSS],
      i[InAppMessage.qt.Po],
      i[InAppMessage.qt.qa],
    );
  }
}
HtmlMessage.Dt = InAppMessage.Qe.fn;
