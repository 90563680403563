import aa from "../managers/braze-instance.js";
import BrazeSdkMetadata from "./braze-sdk-metadata.js";
import {
  isArray as w,
  validateValueIsFromEnum as ta,
} from "../util/code-utils.js";
import { logger as N } from "../../shared-lib/index.js";
export function addSdkMetadata(a) {
  if (!aa.rr()) return;
  const t = aa.g();
  if (t) {
    if (!w(a))
      return (
        N.error("Cannot set SDK metadata because metadata is not an array."), !1
      );
    for (const t of a)
      if (
        !ta(
          BrazeSdkMetadata,
          t,
          "sdkMetadata contained an invalid value.",
          "BrazeSdkMetadata",
        )
      )
        return !1;
    return t.addSdkMetadata(a), !0;
  }
}
