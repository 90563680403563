import { logger as N } from "../../shared-lib/index.js";
import r from "../managers/braze-instance.js";
import ve from "../models/braze-event.js";
import E from "../models/request-result.js";
const c = {
  ds: (e, o, t) => {
    var n, s;
    const i = new E(),
      l = r.P();
    if (!l)
      return (
        N.info(
          `Not logging event with type "${e}" because the current session ID could not be found.`,
        ),
        i
      );
    const d = l.qo();
    return (
      i.fe.push(
        new ve(
          t || (null === (n = r.tr()) || void 0 === n ? void 0 : n.getUserId()),
          e,
          new Date().valueOf(),
          d,
          o,
        ),
      ),
      (i.ss =
        (null === (s = r.j()) || void 0 === s ? void 0 : s.Eo(i.fe)) || !1),
      i
    );
  },
};
export default c;
