import s from "../common/base-provider.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import {
  newBannerFromJson as n,
  newBannerFromSerializedValue as o,
} from "./banner-factory.js";
import h from "../util/request-header-utils.js";
import l from "../util/net.js";
import { randomInclusive as a } from "../util/math.js";
import u from "../managers/subscription-manager.js";
import r from "../managers/braze-instance.js";
import c from "../common/event-logger.js";
import { EventTypes as d } from "../../shared-lib/event-types.js";
export default class e extends s {
  constructor(s, t, i, e) {
    super(),
      (this.B = s),
      (this.S = t),
      (this.C = i),
      (this.T = e),
      (this.banners = {}),
      (this.B = s),
      (this.S = t),
      (this.C = i),
      (this.T = e),
      (this.R = 10),
      (this.I = null),
      (this.q = null),
      (this.F = new u()),
      r.N(this.F),
      (this.k = null),
      (this.D = null);
  }
  U(s) {
    if (this.L() && null != s && s.banners) {
      this.banners = {};
      const t = s.banners;
      for (const s in t) {
        const i = t[s];
        let e = null;
        null != i && null != i.banner && (e = n(i.banner)),
          e && (this.banners[s] = e);
      }
      this.M(), this.F.X(this.banners);
    }
  }
  $(s, t, i, e = !0) {
    var n;
    const r = () => {
      "function" == typeof i && i();
    };
    if (!this.L())
      return void (
        null === (n = this.B) ||
        void 0 === n ||
        n.A(() => {
          this.$(s, t, i);
        })
      );
    const o = this.S;
    if (!o) return void r();
    e && this.G();
    const u = o.H({}, !0),
      c = [];
    for (const t of s) c.push({ id: t });
    u.placements = c;
    const d = o.J(u, h.O.K);
    let m = !1;
    o.V(
      u,
      (n = -1) => {
        if (!this.S) return void r();
        const o = new Date().valueOf();
        h.W(this.C, h.O.K, o),
          -1 !== n && d.push(["X-Braze-Req-Tokens-Remaining", n.toString()]),
          l.Y({
            url: `${this.S.Z()}/banners/sync`,
            headers: d,
            data: u,
            ss: (s) => {
              if (!this.S.ts(u, s, d)) return (m = !0), void r();
              this.S.es(),
                this.U(s),
                (m = !1),
                h.ns(this.C, h.O.K, 1),
                "function" == typeof t && t();
            },
            error: (s) => {
              this.S.rs(s, "retrieving banners"), (m = !0), r();
            },
            os: () => {
              if (((this.D = s), e && m && !this.q)) {
                h.hs(this.C, h.O.K);
                let e = this.I;
                (null == e || e < 1e3 * this.R) && (e = 1e3 * this.R),
                  this.ls(s, t, i, Math.min(3e5, a(1e3 * this.R, 3 * e)));
              }
            },
          });
      },
      h.O.K,
      i,
    );
  }
  us() {
    return this.D;
  }
  cs(s, t) {
    const i = { id: s.id };
    t && (i.bid = t);
    return c.ds(d.fs, i).ss;
  }
  G() {
    null != this.q && (clearTimeout(this.q), (this.q = null));
  }
  ls(s, t, i, e = 1e3 * this.R) {
    this.G(),
      (this.q = window.setTimeout(() => {
        this.$(s, t, i);
      }, e)),
      (this.I = e);
  }
  vs() {
    let s = {};
    this.C && (s = this.C.ps(t.bs.gs));
    const i = {};
    for (const t in s) {
      let e = null;
      null != s[t] && (e = o(s[t])), e && (i[e.placementId] = e);
    }
    return i;
  }
  M() {
    var s;
    if (!this.C) return;
    const i = {};
    for (const t in this.banners) {
      const e =
        (null === (s = this.banners[t]) || void 0 === s ? void 0 : s.Bs()) ||
        null;
      i[t] = e;
    }
    this.C.js(t.bs.gs, i), this.ws();
  }
  ws() {
    var s, i;
    null === (s = this.C) ||
      void 0 === s ||
      s.js(t.bs.ys, null === (i = this.T) || void 0 === i ? void 0 : i.Ss());
  }
  Cs() {
    var s;
    return (
      (null === (s = this.C) || void 0 === s ? void 0 : s.ps(t.bs.ys)) || null
    );
  }
  Ts() {
    return this.k;
  }
  Rs(s) {
    this.k = s;
  }
  Is() {
    var s;
    const t = null === (s = this.T) || void 0 === s ? void 0 : s.Ss(),
      i = this.Cs();
    return null != i && t === i;
  }
  qs() {
    var s;
    const t = null === (s = this.T) || void 0 === s ? void 0 : s.Ss(),
      i = this.Cs();
    return null == i || t === i;
  }
  Fs(s) {
    return this.F.Ns(s);
  }
  ks() {
    var s;
    return (
      (null === (s = this.C) || void 0 === s ? void 0 : s.ps(t.bs.xs)) || {}
    );
  }
  zs(s) {
    this.C && this.C.js(t.bs.xs, s);
  }
  changeUser() {
    this.Ds();
  }
  L() {
    return !!this.B && (!!this.B.Us() || (0 !== this.B.Es() && this.Ds(), !1));
  }
  Ds() {
    (this.banners = {}),
      this.C && (this.C.Ls(t.bs.gs), this.C.Ls(t.bs.xs)),
      this.F.X({});
  }
}
