import r from "../managers/braze-instance.js";
import { logger as N } from "../../shared-lib/index.js";
export function getDeviceId(e) {
  if (!r.rr()) return;
  const t = r.te();
  if (!t) return;
  const i = t.ce().id;
  if ("function" != typeof e) return i;
  N.warn(
    "The callback for getDeviceId is deprecated. You can access its return value directly instead (e.g. `const id = braze.getDeviceId()`)",
  ),
    e(i);
}
