import r, { OPTIONS as z } from "../../managers/braze-instance.js";
export function loadFontAwesome() {
  if (r.sr(z.Zo)) return;
  const e = "https://use.fontawesome.com/7f85a56ba4.css";
  if (
    !(null !== document.querySelector('link[rel=stylesheet][href="' + e + '"]'))
  ) {
    const t = document.createElement("link");
    t.setAttribute("rel", "stylesheet"),
      t.setAttribute("href", e),
      document.getElementsByTagName("head")[0].appendChild(t);
  }
}
