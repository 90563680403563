import se from "../InAppMessage/in-app-message-manager-factory.js";
import r, { OPTIONS as z } from "../managers/braze-instance.js";
import gr from "./triggers-provider.js";
export const TriggersProviderFactory = {
  l: !1,
  provider: null,
  m: () => (
    TriggersProviderFactory.p(),
    TriggersProviderFactory.provider || TriggersProviderFactory.rg(),
    TriggersProviderFactory.provider
  ),
  rg: () => {
    if (!TriggersProviderFactory.provider) {
      const i = r.sr(z.Go);
      (TriggersProviderFactory.provider = new gr(
        null != i ? i : 30,
        se.ea()._r(),
        r.j(),
        r.nn(),
        se.ea(),
      )),
        r._(TriggersProviderFactory.provider);
    }
  },
  p: () => {
    TriggersProviderFactory.l ||
      (TriggersProviderFactory.rg(),
      r.h(TriggersProviderFactory),
      (TriggersProviderFactory.l = !0));
  },
  destroy: () => {
    (TriggersProviderFactory.provider = null), (TriggersProviderFactory.l = !1);
  },
};
