import ri from "./trigger-condition.js";
export default class ur {
  constructor(t) {
    (this.if = t), (this.if = t);
  }
  xc(t) {
    return null == this.if || ri.sf(t[0], this.if);
  }
  static fromJson(t) {
    return new ur(t ? t.campaign_id : null);
  }
  Bs() {
    return this.if;
  }
}
