import { createCloseButton as T } from "../../util/component-utils.js";
import { detectSwipe as H, DIRECTIONS as R } from "../../util/dom-utils.js";
import { _handleBrazeAction as F } from "../../Core/handle-braze-action.js";
import { logCardDismissal } from "../index.js";
import { Guid as G } from "../../../shared-lib/index.js";
export function markCardAsRead(t) {
  if (null != t) {
    const e = t.querySelectorAll(".ab-unread-indicator")[0];
    null != e && (e.className += " read");
  }
}
export function getCardId(t) {
  return t.getAttribute("data-ab-card-id");
}
export function _setImageAltText(t, e) {
  let o = "";
  t.title || t.description || (o = "Feed Image"), e.setAttribute("alt", o);
}
export function setCardHeight(t, e) {
  const o = e.querySelectorAll(".ab-image-area");
  let a,
    i = 0;
  o.length > 0 && (i = o[0].offsetWidth);
  for (const e of t)
    if (((a = e.bt), a && e.imageUrl && "number" == typeof e.aspectRatio)) {
      const t = i / e.aspectRatio;
      t && (a.style.height = `${t}px`);
    }
}
export function cardToHtml(t, logCardClick, e, o = "ltr") {
  const a = document.createElement("div");
  (a.dir = o),
    (a.className = "ab-card ab-effect-card " + t.At),
    t.id &&
      (a.setAttribute("data-ab-card-id", t.id), a.setAttribute("id", t.id)),
    a.setAttribute("role", "article"),
    a.setAttribute("tabindex", "0");
  let i = "",
    n = !1;
  t.url && "" !== t.url && ((i = t.url), (n = !0));
  const d = (o) => (markCardAsRead(a), n && (logCardClick(t), F(i, e, o)), !1);
  if (t.pinned) {
    const t = document.createElement("div");
    t.className = "ab-pinned-indicator";
    const e = document.createElement("i");
    (e.className = "fa fa-star"), t.appendChild(e), a.appendChild(t);
  }
  if (t.imageUrl && "" !== t.imageUrl) {
    const e = document.createElement("div");
    (e.dir = o), (e.className = "ab-image-area");
    const r = document.createElement("img");
    if (
      (r.setAttribute("src", t.imageUrl),
      (r.onload = () => {
        a.style.height = "auto";
      }),
      _setImageAltText(t, r),
      e.appendChild(r),
      (a.className += " with-image"),
      n && !t.Tt)
    ) {
      const t = document.createElement("a");
      t.setAttribute("href", i),
        (t.onclick = d),
        t.appendChild(e),
        a.appendChild(t);
    } else a.appendChild(e);
  }
  const r = document.createElement("div");
  if (((r.className = "ab-card-body"), (r.dir = o), t.dismissible)) {
    t.logCardDismissal = () => logCardDismissal(t);
    const e = T("Dismiss Card", void 0, t.dismissCard.bind(t), o);
    a.appendChild(e),
      H(r, R.kt, (t) => {
        (a.className += " ab-swiped-left"), e.onclick(t);
      }),
      H(r, R.Ht, (t) => {
        (a.className += " ab-swiped-right"), e.onclick(t);
      });
  }
  let c = "",
    s = !1;
  if ((t.title && "" !== t.title && ((c = t.title), (s = !0)), s)) {
    const t = document.createElement("h1");
    if (
      ((t.className = "ab-title"),
      (t.id = G.Rt()),
      a.setAttribute("aria-labelledby", t.id),
      n)
    ) {
      const e = document.createElement("a");
      e.setAttribute("href", i),
        (e.onclick = d),
        e.appendChild(document.createTextNode(c)),
        t.appendChild(e);
    } else t.appendChild(document.createTextNode(c));
    r.appendChild(t);
  }
  const m = document.createElement("div");
  if (
    ((m.className = s ? "ab-description" : "ab-description ab-no-title"),
    (m.id = G.Rt()),
    a.setAttribute("aria-describedby", m.id),
    t.description && m.appendChild(document.createTextNode(t.description)),
    n)
  ) {
    const e = document.createElement("div");
    e.className = "ab-url-area";
    const o = document.createElement("a");
    o.setAttribute("href", i),
      t.linkText && o.appendChild(document.createTextNode(t.linkText)),
      (o.onclick = d),
      e.appendChild(o),
      m.appendChild(e);
  }
  r.appendChild(m), a.appendChild(r);
  const u = document.createElement("div");
  return (
    (u.className = "ab-unread-indicator"),
    t.viewed && (u.className += " read"),
    a.appendChild(u),
    (t.bt = a),
    a
  );
}
