import {
  dateFromUnixTimestamp as O,
  rehydrateDateAfterJsonization as V,
} from "../../util/date-utils.js";
import { logger as N } from "../../../shared-lib/index.js";
import ri from "./trigger-condition.js";
import { validateValueIsFromEnum as ta } from "../../util/code-utils.js";
export default class pt {
  constructor(t, i = [], s, e, r = 0, h, l, o = 0, n = pt.td, a, u, d) {
    (this.id = t),
      (this.sd = i),
      (this.startTime = s),
      (this.endTime = e),
      (this.priority = r),
      (this.type = h),
      (this.data = l),
      (this.ed = o),
      (this.rd = n),
      (this.mn = a),
      (this.hd = u),
      (this.od = d),
      (this.id = t),
      (this.sd = i || []),
      void 0 === s && (s = null),
      (this.startTime = s),
      void 0 === e && (e = null),
      (this.endTime = e),
      (this.priority = r || 0),
      (this.type = h),
      (this.ed = o || 0),
      null == a && (a = 1e3 * (this.ed + 30)),
      (this.mn = a),
      (this.data = l),
      null != n && (this.rd = n),
      (this.hd = u),
      (this.od = d || null);
  }
  nd(t) {
    return (
      null == this.od || (this.rd !== pt.td && t - this.od >= 1e3 * this.rd)
    );
  }
  ad(t) {
    this.od = t;
  }
  ud(t) {
    const i = t + 1e3 * this.ed;
    return Math.max(i - new Date().valueOf(), 0);
  }
  dd(t) {
    const i = new Date().valueOf() - t,
      s = null == t || isNaN(i) || null == this.mn || i < this.mn;
    return (
      s ||
        N.info(
          `Trigger action ${this.type} is no longer eligible for display - fired ${i}ms ago and has a timeout of ${this.mn}ms.`,
        ),
      !s
    );
  }
  static fromJson(t) {
    const i = t.id,
      s = [];
    for (let i = 0; i < t.trigger_condition.length; i++)
      s.push(ri.fromJson(t.trigger_condition[i]));
    const e = O(t.start_time),
      r = O(t.end_time),
      h = t.priority,
      l = t.type,
      o = t.delay,
      n = t.re_eligibility,
      a = t.timeout,
      u = t.data,
      d = t.min_seconds_since_last_trigger;
    return ta(
      pt.ln,
      l,
      "Could not construct Trigger from server data",
      "Trigger.Types",
    )
      ? new pt(i, s, e, r, h, l, u, o, n, a, d)
      : null;
  }
  Bs() {
    const t = [];
    for (let i = 0; i < this.sd.length; i++) t.push(this.sd[i].Bs());
    return {
      i: this.id,
      c: t,
      s: this.startTime,
      e: this.endTime,
      p: this.priority,
      t: this.type,
      da: this.data,
      d: this.ed,
      r: this.rd,
      tm: this.mn,
      ss: this.hd,
      ld: this.od,
    };
  }
  static Qn(t) {
    const i = [],
      s = t.c || [];
    for (let t = 0; t < s.length; t++) i.push(ri.Qn(s[t]));
    return new pt(
      t.i,
      i,
      V(t.s),
      V(t.e),
      t.p,
      t.t,
      t.da,
      t.d,
      t.r,
      t.tm,
      t.ss,
      t.ld,
    );
  }
}
(pt.ln = { an: "inapp", md: "templated_iam" }), (pt.td = -1);
