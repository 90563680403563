import l from "../util/net.js";
import r from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import c from "../common/event-logger.js";
import { newInAppMessageFromJson as ut } from "./in-app-message-factory.js";
import { randomInclusive as a } from "../util/math.js";
import E from "../models/request-result.js";
import { logger as N, EventTypes as d } from "../../shared-lib/index.js";
import u from "../managers/subscription-manager.js";
import pt from "../triggers/models/trigger.js";
import { BRAZE_ACTION_URI_REGEX as to } from "../util/validation-utils.js";
import {
  containsPushPrimerBrazeAction as gt,
  containsUnknownBrazeAction as ft,
  getDecodedBrazeAction as eo,
  ineligibleBrazeActionURLErrorMessage as jt,
  INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES as vt,
} from "../util/braze-actions.js";
import yt from "../Push/utils/push-utils.js";
import h from "../util/request-header-utils.js";
import { STORAGE_KEYS as t } from "../managers/storage-manager.js";
import { FullScreenMessage, ModalMessage, SlideUpMessage } from "./index.js";
export default class ea {
  constructor(t, e, s, i) {
    (this.S = t),
      (this.T = e),
      (this.C = s),
      (this.As = i),
      (this.S = t),
      (this.T = e),
      (this.C = s),
      (this.As = i),
      (this.Ir = new u()),
      r.N(this.Ir),
      (this.Mr = 1e3),
      (this.Tr = 6e4),
      (this.Br = null);
  }
  _r() {
    return this.Ir;
  }
  qr(t) {
    return this.Ir.Ns(t);
  }
  Er() {
    return this.Br;
  }
  Gr(t) {
    this.Br = t;
  }
  ds(t, e, s, i) {
    const r = new E();
    let n;
    if (e === d.Nr || t instanceof ControlMessage) {
      if (!t.wt())
        return (
          N.info(
            "This in-app message has already received an impression. Ignoring analytics event.",
          ),
          r
        );
    } else if (e === d.Cr || (t instanceof HtmlMessage && e === d.Or)) {
      if (!t.rt(i))
        return (
          N.info(
            "This in-app message has already received a click. Ignoring analytics event.",
          ),
          r
        );
    }
    return (
      (n =
        t instanceof ControlMessage
          ? { trigger_ids: [t.triggerId] }
          : this.Xr(t)),
      null == n
        ? r
        : (t.messageExtras && (n.message_extras = t.messageExtras),
          null != s && (n.bid = s),
          c.ds(e, n))
    );
  }
  Hr(t, e) {
    const s = new E();
    if (!t.rt())
      return (
        N.info(
          "This in-app message button has already received a click. Ignoring analytics event.",
        ),
        s
      );
    const i = this.Xr(e);
    return null == i
      ? s
      : t.id === InAppMessageButton.Jr
      ? (N.info(
          "This in-app message button does not have a tracking id. Not logging event to Braze servers.",
        ),
        s)
      : (null != t.id && (i.bid = t.id), c.ds(d.Or, i));
  }
  Kr(t) {
    const e = t.messageFields;
    return (null != e && e.is_push_primer) || !1;
  }
  Lr(t) {
    if (!(t instanceof InAppMessage)) return;
    const e = (t) => {
      if (!t) return;
      const e = eo(t);
      return ft(e)
        ? jt(vt.Qr, "In-App Message")
        : gt(e) && !yt.Ur()
        ? jt(vt.Vr, "In-App Message")
        : void 0;
    };
    if (this.Kr(t) && !yt.Ur())
      return "In-App Message contains a push prompt, but is not eligible for a push prompt. Ignoring.";
    const s = t.buttons || [];
    let i;
    for (const t of s)
      if (
        t.clickAction === InAppMessage.ClickAction.URI &&
        t.uri &&
        to.test(t.uri) &&
        ((i = e(t.uri)), i)
      )
        return i;
    return t.clickAction === InAppMessage.ClickAction.URI &&
      t.uri &&
      to.test(t.uri)
      ? e(t.uri)
      : void 0;
  }
  Wr(t, e, s, i) {
    const r = this.S;
    if (!r) return;
    const n = r.Yr(!1, !1),
      o = r.H(n);
    (o.template = { trigger_id: t.triggerId, trigger_event_type: e }),
      null != s && (o.template.data = s.Zr());
    const u = r.J(o, h.O.sn);
    r.V(
      o,
      (r = -1) => {
        if (!this.S) return;
        const n = new Date().valueOf();
        h.W(this.C, h.O.sn, n),
          -1 !== r && u.push(["X-Braze-Req-Tokens-Remaining", r.toString()]),
          l.Y({
            url: `${this.S.Z()}/template/`,
            data: o,
            headers: u,
            ss: (e) => {
              if ((h.ns(this.C, h.O.sn, 1), !this.S.ts(o, e, u)))
                return void ("function" == typeof t.on && t.on());
              if ((this.S.es(), null == e || null == e.templated_message))
                return;
              const s = e.templated_message;
              if (s.type !== pt.ln.an) return;
              const i = ut(s.data);
              if (null == i) return;
              const r = this.Lr(i);
              if (r)
                return N.error(r), void ("function" == typeof t.on && t.on());
              "function" == typeof t.un && t.un(i);
            },
            error: (r) => {
              let n = `getting user personalization for message ${t.triggerId}`;
              if (new Date().valueOf() - t.pn < t.mn) {
                h.hs(this.C, h.O.sn);
                const r = Math.min(t.mn, this.Tr),
                  o = this.Mr;
                null == i && (i = o);
                const l = Math.min(r, a(o, 3 * i));
                (n += `. Retrying in ${l} ms`),
                  setTimeout(() => {
                    this.Wr(t, e, s, l);
                  }, l);
              }
              this.S.rs(r, n);
            },
          });
      },
      h.O.sn,
    );
  }
  Xr(t) {
    if (null == t.triggerId)
      return (
        N.info(
          "The in-app message has no analytics id. Not logging event to Braze servers.",
        ),
        null
      );
    const e = {};
    return null != t.triggerId && (e.trigger_ids = [t.triggerId]), e;
  }
  Me(e) {
    return (
      !!this.C &&
      !(
        !(e && e instanceof InAppMessage && e.constructor !== InAppMessage) ||
        e instanceof ControlMessage
      ) &&
      this.C.js(t.bs.cn, e.Bs())
    );
  }
  Ar() {
    if (!this.C) return null;
    const e = this.C.ps(t.bs.cn);
    if (!e) return null;
    let s;
    switch (e.type) {
      case InAppMessage.Qe.gn:
        s = FullScreenMessage.hn(e);
        break;
      case InAppMessage.Qe.fn:
      case InAppMessage.Qe.Le:
      case InAppMessage.Qe.Io:
        s = HtmlMessage.hn(e);
        break;
      case InAppMessage.Qe.dn:
      case InAppMessage.Qe.xo:
        s = ModalMessage.hn(e);
        break;
      case InAppMessage.Qe.jn:
        s = SlideUpMessage.hn(e);
    }
    return s && this.vn(), s;
  }
  vn() {
    this.C && this.C.Ls(t.bs.cn);
  }
}
