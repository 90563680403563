import { rehydrateDateAfterJsonization as V } from "../util/date-utils.js";
export default class ti {
  constructor(t, i, s, l, h) {
    (this.endpoint = t),
      (this.Ln = i),
      (this.publicKey = s),
      (this.Vl = l),
      (this.El = h),
      (this.endpoint = t || null),
      (this.Ln = i || null),
      (this.publicKey = s || null),
      (this.Vl = l || null),
      (this.El = h || null);
  }
  Bs() {
    return {
      e: this.endpoint,
      c: this.Ln,
      p: this.publicKey,
      u: this.Vl,
      v: this.El,
    };
  }
  static Qn(t) {
    return new ti(t.e, V(t.c), t.p, t.u, t.v);
  }
}
