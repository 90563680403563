import { buttonsFromSerializedInAppMessage as pe } from "../in-app-message-factory.js";
import InAppMessage from "./in-app-message.js";
export default class FullScreenMessage extends InAppMessage {
  constructor(
    e,
    r,
    s,
    t,
    i,
    a,
    o,
    p,
    m,
    n,
    u,
    c,
    f,
    d,
    l,
    g,
    j,
    x,
    z,
    h,
    v,
    w,
    y,
    S,
    b,
    k,
    q,
    A,
    B,
  ) {
    (p = p || InAppMessage.DismissType.MANUAL),
      (k = k || InAppMessage.Orientation.PORTRAIT),
      super(
        e,
        r,
        void 0,
        s,
        t,
        i,
        a,
        o,
        p,
        m,
        n,
        u,
        c,
        f,
        d,
        l,
        g,
        j,
        x,
        z,
        h,
        v,
        w,
        y,
        S,
        (b = b || InAppMessage.CropType.CENTER_CROP),
        k,
        q,
        A,
        B,
      ),
      (this.do = InAppMessage.TextAlignment.CENTER);
  }
  Bs() {
    return super.Bs(FullScreenMessage.Dt);
  }
  static hn(e) {
    return new FullScreenMessage(
      e[InAppMessage.qt.ra],
      e[InAppMessage.qt.sa],
      e[InAppMessage.qt.St],
      e[InAppMessage.qt.ta],
      e[InAppMessage.qt.ia],
      e[InAppMessage.qt.URI],
      e[InAppMessage.qt.oa],
      e[InAppMessage.qt.pa],
      e[InAppMessage.qt.ma],
      e[InAppMessage.qt.na],
      e[InAppMessage.qt.Jt],
      e[InAppMessage.qt.ua],
      e[InAppMessage.qt.ca],
      e[InAppMessage.qt.fa],
      e[InAppMessage.qt.da],
      e[InAppMessage.qt.la],
      e[InAppMessage.qt.ga],
      e[InAppMessage.qt.ja],
      e[InAppMessage.qt.xa],
      e[InAppMessage.qt.za],
      e[InAppMessage.qt.ha],
      e[InAppMessage.qt.va],
      e[InAppMessage.qt.wa],
      pe(e[InAppMessage.qt.ya]),
      e[InAppMessage.qt.Sa],
      e[InAppMessage.qt.ba],
      e[InAppMessage.qt.ka],
      e[InAppMessage.qt.CSS],
      e[InAppMessage.qt.qa],
    );
  }
}
FullScreenMessage.Dt = InAppMessage.Qe.gn;
